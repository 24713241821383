import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';

import {changeAwardRejectPopUpStatus} from '../../../../redux/actions/awards/awards.js';
import styles from "./bySellingMethod/awardEditModule.module.css";

import {setAwardAllUserPopUp, setAwardAllUserPopUpUser} from '../../../../redux/actions.js';

import {
    changeContractApprowePopUpStatus,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
} from '../../../../redux/actions/contracts/contracts.js';

import {saveArrBids} from '../../../../redux/allAuctionBids';
import {setDataAddress} from '../../../../redux/createAddress';

import {
    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,
} from '../../../../redux/history.js';
import {setUniversalError, changeLoader} from '../../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../../redux/actions/contracts/contracts.js';

import procedure from "../../../../redux/procedure";


import AwardEditREM from "../edit/bySellingMethod/AwardEditREM";


import AwardBREBRD from '../../../product/awardNew/newAwardBREBRD'
import AwardBRW from '../../../product/awardNew/newAwardBRW'
import AwardSUE from '../../../product/awardNew/newAwardSUE'
import AwardNLENLD from '../../../product/awardNew/newAwardNLENLD'
import AwardLAE from '../../../product/awardNew/newAwardLAE'
import AwardLAW from '../../../product/awardNew/newAwardLAW'
import AwardLAP from '../../../product/awardNew/newAwardLAP'
import AwardLPE from '../../../product/awardNew/newAwardLPE'
import AwardAPE from '../../../product/awardNew/newAwardAPE'
import AwardAPD from '../../../product/awardNew/newAwardAPD'
import AwardOther from '../../../product/awardNew/newAwardOther'
import NewAwardSPE from "../../../product/awardNew/newAwardSPE";
import {getUpdateDataAuction, getUpdateDataBid} from "../../../product/updateData";
import PopAsk from "../../../_popups/popAsk/popAsk";
import {getDefaultHeaders} from "../../../_api/bidApi";

const components = {
    [procedure.SELLING_METHOD_RENEWABLES_MULTI_AWARDS]: AwardEditREM,
}

const EditAwardList = (props) => {
    const [slim, setSlim] = useState({state: false, text: 'Розгорнути'})
    const [popApproveAllDocuments, setPopApproveAllDocuments] = useState(false)

    function Slimmer() {
        slim.state ? setSlim({state: false, text: 'Розгорнути'}) : setSlim({state: true, text: 'Згорнути'})
    }

    //@todo disable and rewrite
    //added for old code support
    useEffect(() => {
        if (props?.auction?.privateData?.bids[0]?.id) getUpdateDataBid(props, props?.auction?.privateData?.bids[0]?.id)
    }, [])

    //@todo refactor and delete
    //@deprected
    function renderListOld(props) {
        //@todo refactor
        if (procedure.isSPE(props.auction.sellingMethod) || procedure.isSPD(props.auction.sellingMethod)) {
            return <NewAwardSPE/>
        }
        if (procedure.isBRE(props.auction.sellingMethod) || procedure.isBRD(props.auction.sellingMethod)) {
            return <AwardBREBRD/>
        }
        if (procedure.isBRW(props.auction.sellingMethod)) {
            return <AwardBRW/>
        }
        if (procedure.isSUE(props.auction.sellingMethod)) {
            return <AwardSUE/>
        }
        if (procedure.isNLE(props.auction.sellingMethod) || procedure.isNLD(props.auction.sellingMethod)) {
            return <AwardNLENLD/>
        }
        if (procedure.isLAE(props.auction.sellingMethod)) {
            return <AwardLAE/>
        }
        if (procedure.isLAW(props.auction.sellingMethod)) {
            return <AwardLAW/>
        }
        if (procedure.isLAP(props.auction.sellingMethod)) {
            return <AwardLAP/>
        }
        if (procedure.isLPE(props.auction.sellingMethod)) {
            return <AwardLPE/>
        }
        if (procedure.isAPE(props.auction.sellingMethod)) {
            return <AwardAPE/>
        }
        if (procedure.isAPD(props.auction.sellingMethod)) {
            return <AwardAPD/>
        }
        return <AwardOther/>
    }

    if (!components.hasOwnProperty(props?.auction?.sellingMethodClear)) {
        return renderListOld(props)
    }

    function showApproveAllDocumentsButton(props) {
        if (!procedure.isREM(props?.auction?.sellingMethod)) return
        let showBtn = false
        for (let key in props?.auction?.awards) {
            if (props?.auction?.awards[key].status === 'verification') showBtn = true
        }
        if (!showBtn) return
        return (
            <>
                {
                    popApproveAllDocuments
                        ?
                        <PopAsk
                            title={`Підтвердити завершення перевірки документів?`}
                            actionYes={() => {
                                props.changeLoader(true)
                                const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction?.id}/set_status/active_qualification`
                                fetch(endPoint, {
                                    method: "POST",
                                    headers: getDefaultHeaders(props)
                                }).catch(error => {
                                    props.changeLoader(false)
                                    setPopApproveAllDocuments(false)
                                    console.error('Api request error:', error);
                                    props.setUniversalError(JSON.stringify({
                                        title: 'Помилка',
                                        message: 'Під час підтвердження перевірки документів виникла помилка'
                                    }))
                                }).then(response => {
                                    if (response.status === 200) {
                                        getUpdateDataAuction(props, props.auction.id)
                                        props.changeLoader(false)
                                        setPopApproveAllDocuments(false)
                                        return
                                    }
                                    props.changeLoader(false)
                                    setPopApproveAllDocuments(false)
                                    console.error('Api request failed with code:', response.status, response);
                                    props.setUniversalError(JSON.stringify({
                                        title: 'Помилка',
                                        message: 'Під час підтвердження перевірки документів виникла помилка'
                                    }))
                                })
                                setPopApproveAllDocuments(false)
                            }}
                            actionNo={() => {
                                setPopApproveAllDocuments(false)
                            }}
                        />
                        : null
                }
                <div
                    className={styles.btnAccept}
                    onClick={
                        () => {
                            setPopApproveAllDocuments(true)
                        }
                    }>
                    Перевірка документів завершена
                </div>
            </>
        )
    }


    let awardComponentListRendered = []
    if (props?.auction?.awards?.length > 0) {
        awardComponentListRendered = props.auction.awards.map(
            (award, count) => {
                const Component = components[props?.auction?.sellingMethodClear]
                return <Component award={award} key={award.id} count={count}/>
            }
        )
    }

    if (awardComponentListRendered?.length > 0) {
        return (
            <div className={styles.awardBlock}>
                <div className={styles.awardBlock_title}>Кваліфікація учасників</div>
                <div className={styles.slimmer} onClick={() => Slimmer()}>{slim.text}</div>
                {slim.state ?
                    <div className={styles.awardBlockContent}>
                        {showApproveAllDocumentsButton(props)}
                        {awardComponentListRendered}
                    </div> : null
                }
            </div>
        )
    }
    return null
}


const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionAwards: state.start.auctionAwards,
        allUserPopUp: state.start.allUserPopUp,
        allUserPopUpUser: state.start.allUserPopUpUser,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        timberContractPut: state.start.timberContractPut,
        pageNumber: state.start.pageNumber,
        auctionsGetId: state.start.auctionsGetId,
        auctionsAllBids: state.start.auctionsAllBids,
        auction: state.start.auctionFull,
        bidEdit: state.start.bidEdit,
        userProfiles: state.start.userProfiles
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,
    setAwardAllUserPopUp,
    setAwardAllUserPopUpUser,

    changeContractApprowePopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,

    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,
    setContractDatePaid,

    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
    setDataAddress,
    saveArrBids
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAwardList)
