import React, {useState, useEffect} from 'react';
import styles from '../procedure.module.css'
import stylesInput from './inputAmount.module.css'
import stylesInputCheck from './checkBox.module.css'


import {useSelector} from 'react-redux';
import {TranslationsUk} from '../../../../../../redux/translations.uk.js';

import { isMongo, checkProp } from '../../../../../../redux/check';

import TextArea from '../../../../../elements/inputs/textArea'
import Input from '../../../../../elements/inputs/input';
import Select from '../../../../../elements/inputs/inputSelect'
import Button from '../../../../../elements/buttons/button';
import InputAmount from '../../../../../elements/inputs/inputAmount';

import BankAccount from '../../bankAccount/bankAccount';

import { disabledElement } from '../../../../disabled';

//import { setTenderAttemptList, createBodyCSE } from './utils'; 
import { createBody } from '../lib/createBody';
import { setTenderAttemptList } from '../lib/utils';

import Arrests from '../../arrests/arrests'

import {editAuc, createAuc, editAucPatch, editAucAwardPatch, editAucArest} from '../../../createEditAndCloneAuction/edit/editTabMonga'

import LocationOrg from '../../../../elems/localization/locationOrg';
import {changeLoader} from '../../../../../../redux/actions.js';
import {setDataAddress} from '../../../../../../redux/createAddress';
import {setUniversalError} from '../../../../../../redux/actions.js';


const AuctionLAW = ( {data, ...props} ) => {
    const token = useSelector(state => state.start.token)
    const auction = useSelector(state => state.start.auctionsMy)
    const [auctionBank] = useState(null)

    const [lotId, setLotId] = useState(checkProp(data, 'lotId') ? data.lotId : null)
    const [title, setTitle] = useState(checkProp(data, 'title') ? isMongo(data.title) : null)
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [tenderAttempts, setTenderAttempts] = useState(checkProp(data, 'tenderAttempts') && Number.isInteger(data.tenderAttempts) ? data.tenderAttempts : null)
    // const [accessDetails, setAccessDetails] = useState(checkProp(data, 'accessDetails') ? isMongo(data.accessDetails) : null)
    //const [x_documentRequirements, setX_documentRequirements] = useState(checkProp(data, 'x_documentRequirements') ? isMongo(data.x_documentRequirements) : null)
    const [x_additionalInformation, setX_additionalInformation] = useState(checkProp(data, 'x_additionalInformation') ? isMongo(data.x_additionalInformation) : null)
    //const [minNumberOfQualifiedBids, setMinNumberOfQualifiedBids] = useState(checkProp(data, 'minNumberOfQualifiedBids') && Number.isInteger(data.minNumberOfQualifiedBids) ? data.minNumberOfQualifiedBids : 2)
    const [saleType, setSaleType] = useState(checkProp(data, 'saleType') && data.saleType !== null && data.saleType !== 'null' ? data.saleType : 'landSale')
    //const [startDate, setStartDate] = useState(checkProp(data, 'auctionPeriod') && checkProp(data.auctionPeriod, 'startDate') ? data.auctionPeriod.startDate : null)
    //const [valueAddedTaxCharged, setValueAddedTaxCharged] = useState(checkProp(data, 'valueAddedTaxCharged') && data.valueAddedTaxCharged !== null ? data.valueAddedTaxCharged : false)
    
    const [valueAmount, setValueAmount] = useState(checkProp(data, 'value') && data?.value !== null && data?.value?.amount !== null ? data?.value?.amount : '')
    const [valueCurrency, setValueCurrency] = useState(checkProp(data, 'value') && data?.value !== null && data?.value?.currency !== null ? data?.value?.currency : 'UAH')
    const [valueAddedTaxIncluded, setValueAddedTaxIncluded] = useState(checkProp(data, 'value') && data?.value !== null && data?.value?.valueAddedTaxIncluded !== null ? data?.value?.valueAddedTaxIncluded : true)
    //const [value, setValue] = useState(checkProp(data, 'value') ? data.value : null)
    // const [guarantee, setGuarantee] = useState(checkProp(data, 'guarantee') ? data.guarantee : null)
    // const [minimalStep, setMinimalStep] = useState(checkProp(data, 'minimalStep') ? data.minimalStep : null)
    // const [registrationFee, setRegistrationFee] = useState(checkProp(data, 'registrationFee') ? data.registrationFee : null)
    // const [expertMonetaryValuation, setExpertMonetaryValuation] = useState(checkProp(data, 'expertMonetaryValuation') ? data.expertMonetaryValuation : null)
    // const [normativeMonetaryValuation, setNormativeMonetaryValuation] = useState(checkProp(data, 'normativeMonetaryValuation') ? data.normativeMonetaryValuation : null)
    // const [budgetSpent, setBudgetSpent] = useState(checkProp(data, 'budgetSpent') ? data.budgetSpent : null)
    const [previousAuctionId, setPreviousAuctionId] = useState(checkProp(data, 'previousAuctionId') ? data.previousAuctionId : null)
    //const [discountPercent, setDiscountPercent] = useState(checkProp(data,'discount') && checkProp(data.discount, 'discountPercent') ? data.discount.discountPercent : null)
    // const [previousAuctionValue, setPreviousAuctionValue] = useState(checkProp(data,'discount') && checkProp(data.discount, 'previousAuctionValue') ? data.discount.previousAuctionValue : null)
    const [bankAccounts, setBankAccounts] = useState(checkProp(data,'bankAccounts') ? data.bankAccounts : [])
    //const [discount, setDiscount] = useState(checkProp(data,'discount') && checkProp(data.discount,'discount') ? data.discount.discount : false)
    // const [restrictions, setRestrictions] = useState(checkProp(data, 'restrictions') ? isMongo(data.restrictions) : null)
    // const [conditions, setConditions] = useState(checkProp(data, 'conditions') ? isMongo(data.conditions) : null)
    const [arrests, setArrests] = useState(checkProp(data,'arrests') ? data.arrests : null)

    const [titleAward, setTitleAward] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'title')) ? isMongo(data.awards[0].title) : null)
    const [descriptionAwards, setDescriptionAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'description')) ? isMongo(data.awards[0].description) : null)
    //const [statusAwards, setStatusAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'status')) ? data.awards[0].status : null)
    
    const [valueAwards, setValueAwards] = useState(
        (checkProp(data, 'awards') && checkProp(data.awards[0], 'value'))
        ? data.awards[0].value
        : null)
    
    // const [, setNameAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'name')) ? isMongo(data.awards[0].buyers[0].name) : null)
    const [identifierLegalNameAwards, setIdentifierLegalNameAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'identifier') && checkProp(data.awards[0].buyers[0].identifier, 'legalName')) ? isMongo(data.awards[0].buyers[0].identifier.legalName) : null)
    const [identifierSchemeAwards, setIdentifierSchemeAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'identifier') && checkProp(data.awards[0].buyers[0].identifier, 'scheme')) ? data.awards[0].buyers[0].identifier.scheme : null)
    const [identifierIdAwards, setIdentifierIdAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'identifier') && checkProp(data.awards[0].buyers[0].identifier, 'id')) ? data.awards[0].buyers[0].identifier.id : null)
    
    const [addressAwards, setAdressAwards] = useState(
        (checkProp(data, 'awards')
        && checkProp(data.awards[0], 'buyers')
        && checkProp(data.awards[0].buyers[0], 'address')
    ) ? data.awards[0].buyers[0].address : null)
    const [contactPointNameAwards, setContactPointNameAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'contactPoint') && checkProp(data.awards[0].buyers[0].contactPoint, 'name')) ? isMongo(data.awards[0].buyers[0].contactPoint.name) : null)
    const [contactPointEmailAwards, setContactPointEmailAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'contactPoint') && checkProp(data.awards[0].buyers[0].contactPoint, 'email')) ? data.awards[0].buyers[0].contactPoint.email : null)
    const [contactPointTelephoneAwards, setContactPointTelephoneAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'contactPoint') && checkProp(data.awards[0].buyers[0].contactPoint, 'telephone')) ? data.awards[0].buyers[0].contactPoint.telephone : null)
    const [contactPointFaxNumberAwards, setContactPointFaxNumberAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'contactPoint') && checkProp(data.awards[0].buyers[0].contactPoint, 'faxNumber')) ? data.awards[0].buyers[0].contactPoint.faxNumber : null)
    const [contactPointUrlAwards, setContactPointUrlAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'contactPoint') && checkProp(data.awards[0].buyers[0].contactPoint, 'url')) ? data.awards[0].buyers[0].contactPoint.url : null)
    const [contactRepresentativeInfoAwards] = useState((checkProp(data, 'awards') && checkProp(data.awards[0], 'buyers') && checkProp(data.awards[0].buyers[0], 'representativeInfo')) ? data.awards[0].buyers[0].representativeInfo : null)
    
    useEffect(() => {
        if(checkProp(data, 'awards') && checkProp(data.awards[0], 'value')){
            setValueAwards(data.awards[0].value)
        }
    }, [])

    function setBaseUrl(){
        if(window.location.pathname.indexOf('/editNew/') !== -1){
            return window.location.pathname.split('/editNew/');
        }
        return null
    }

    function setPageId(){
        let tmp = setBaseUrl()
        if(tmp !== null){
            return tmp[1].split('/')
        }
        return null
    }

    let tmpId = setPageId()
    useEffect(() => {
        getAucions(props, tmpId[0])
        if(valueAmount === 0.01) setValueAmount('')
        if(valueCurrency === 'null') setValueCurrency('UAH')
        //if(valueAddedTaxIncluded === false && valueAmount === 0.01 && valueCurrency === 'null') setValueAddedTaxIncluded(true)
    }, [])

    async function getAucions(props, id){
        changeLoader(true)
        let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/${id}`;
        const response = await fetch(endPoint, {
            method: "GET",
            headers: {
                'Authorization': token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response === undefined || response === 'undefined'){
        }else{
            const json = await response.json()
            if(json.hasOwnProperty('message') === true){
                const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${id}`;
                const response = await fetch(endPoint, {
                    method: "GET",
                    headers: {
                        'Authorization': token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(response === undefined || response === 'undefined'){
                }else{
                    const json = await response.json()
                    if(json.hasOwnProperty('message') === true){
                        let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
                        setUniversalError(tmp)
                    }else{
                        setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
                    }
                }
            }else{
                setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
            }
        }
        changeLoader(false)
    }

    let requestBody = {
        title: title !== null && title !== ''
            ? { uk_UA: title.trim() }
            : null,
        description: description !== null && description !== ''
            ? { uk_UA: description.trim() }
            : null,
        // accessDetails:  accessDetails !== ''
        //     ? { uk_UA: accessDetails }
        //     : null,
        tenderAttempts: +tenderAttempts,
        lotId: lotId !== '' ? lotId : null,
        sellingMethod: data.sellingMethod,
        // x_documentRequirements: {
        //     uk_UA: x_documentRequirements !== '' ? x_documentRequirements : null
        // },
        x_additionalInformation: {
            uk_UA: x_additionalInformation !== '' ? x_additionalInformation : null
        },
        //minNumberOfQualifiedBids: +minNumberOfQualifiedBids,
        saleType: saleType,
        // auctionPeriod: {
        //     startDate: startDate
        // },
        //valueAddedTaxCharged: valueAddedTaxCharged,
        value: {
            currency: valueCurrency,
            amount: valueAmount === '' ? null : +valueAmount,
            valueAddedTaxIncluded: valueAddedTaxIncluded
        },
        // value: {
        //     currency: checkProp(value,'currency') ? value.currency : null,
        //     amount: checkProp(value,'amount') && value?.amount !== '' ? +value.amount : null,
        //     valueAddedTaxIncluded: checkProp(value,'addTax') ? value.addTax : null
        // },
        // minimalStep: {
        //     currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
        //     amount: checkProp(minimalStep,'amount') ? +minimalStep.amount : null,
        // },
        // guarantee: {
        //     currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
        //     amount: checkProp(guarantee,'amount') ? +guarantee.amount : null
        // },
        // registrationFee: {
        //     currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
        //     amount: checkProp(registrationFee,'amount') ? +registrationFee.amount : null
        // },
        // expertMonetaryValuation: {
        //     currency: checkProp(expertMonetaryValuation,'currency') ? expertMonetaryValuation.currency : null,
        //     amount: checkProp(expertMonetaryValuation,'amount') ? +expertMonetaryValuation.amount : null
        // },
        // normativeMonetaryValuation: {
        //     currency: checkProp(normativeMonetaryValuation,'currency') ? normativeMonetaryValuation.currency : null,
        //     amount: checkProp(normativeMonetaryValuation,'amount') ? +normativeMonetaryValuation.amount : null
        // },
        // budgetSpent: {
        //     currency: checkProp(budgetSpent,'currency') ? budgetSpent.currency : null,
        //     amount: checkProp(budgetSpent,'amount') ? +budgetSpent.amount : null,
        //     valueAddedTaxIncluded: checkProp(budgetSpent,'addTax') ? budgetSpent.addTax : null
        // },
        previousAuctionId: previousAuctionId,
        
        // discount: {
        //     discount: discount,
        //     discountPercent: discountPercent,
        //     previousAuctionValue:{
        //         currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
        //         amount: checkProp(previousAuctionValue,'amount') ? +previousAuctionValue.amount : null,
        //         valueAddedTaxIncluded: checkProp(previousAuctionValue,'addTax') ? previousAuctionValue.addTax : null
        //     }
        // },
        bankAccounts: bankAccounts,
        // restrictions: restrictions,
        // conditions: conditions,
        arrests: arrests !== null && arrests.length !== 0 ? arrests : null
    }

    let requestBodyAward = {
        title: titleAward !== null && titleAward !== ''
            ? { uk_UA: titleAward.trim() }
            : null,
        description: descriptionAwards !== null && descriptionAwards !== ''
            ? { uk_UA: descriptionAwards.trim() }
            : null,
        /*status: statusAwards,*/
        value: {
            currency: checkProp(valueAwards,'currency') ? valueAwards.currency : null,
            amount: checkProp(valueAwards,'amount') && valueAwards?.amount !== '' ? +valueAwards.amount : null,
        },
        buyers: [{
            /*name: {
                uk_UA:identifierLegalNameAwards,
            },*/
            identifier: setIdentifier(),
            address: addressAwards,
            representativeInfo: contactRepresentativeInfoAwards !== null ? contactRepresentativeInfoAwards.trim() : null,
            contactPoint: {
                name: contactPointNameAwards !== null ? {uk_UA: contactPointNameAwards.trim()} : null,
                email: contactPointEmailAwards !== null ? contactPointEmailAwards.trim() : null,
                telephone: contactPointTelephoneAwards !== null ? contactPointTelephoneAwards.trim() : null,
                faxNumber: contactPointFaxNumberAwards !== null ? contactPointFaxNumberAwards.trim() : null,
                url: contactPointUrlAwards !== null ? contactPointUrlAwards.trim() : null
            }
        }]
    }
    function setIdentifier(){
        if(
            (identifierSchemeAwards === null || identifierSchemeAwards.trim() === '')
            && (identifierIdAwards === null || identifierIdAwards.trim() === '')
            && (identifierLegalNameAwards === null || identifierLegalNameAwards.trim() === '')
        ){
            return null
        }
        return {
            scheme: identifierSchemeAwards !== null ? identifierSchemeAwards.trim() : null,
            id: identifierIdAwards !== null ? identifierIdAwards.trim() : null,
            legalName: identifierLegalNameAwards !== null ? { uk_UA: identifierLegalNameAwards.trim()} : null
        }
    }

    function discountBlock(){
        if (tenderAttempts > 1){
            return(
                <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Дані попереднього аукціону</div>
                    <div className={styles.twoInLine}>
                        <Input //"Ідентифікатор попереднього аукціону"
                            label="Ідентифікатор попереднього аукціону"
                            value={previousAuctionId}
                            onChange={(e)=>{ setPreviousAuctionId(e) }}
                            disabled={disabledInputAward(auction, '01')}
                            required
                        />
                    </div>
                    {/* discount 
                    ? 
                        <>
                            <Checkbox 
                                value={discount}
                                onChange={setDiscount}
                            >
                            Застосувати знижку
                            </Checkbox>
                            <div 
                            // className={styles.twoInLine}
                                style={{width: '60%', height: '180px', display: 'flex', flexFlow: 'column', justifyContent: 'space-between'}}
                            >
                                <InputAmount //"Стартова вартість попереднього аукціону"
                                    title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.legalName}
                                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.amount.legalName}
                                    data={{
                                        amount: checkProp(previousAuctionValue,'amount') ? previousAuctionValue.amount : null,
                                        currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
                                        addTax: checkProp(previousAuctionValue,'valueAddedTaxIncluded') ? previousAuctionValue.valueAddedTaxIncluded : true
                                    }}
                                    onChange={(e) => { setPreviousAuctionValue(e) }}
                                    disabled={disabledElement(auction, '01')}
                                    required
                                    tax
                                />
                                <Input //"Розмір знижки від попереднього аукціону, %"
                                    label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.discount.discountPercent.legalName} 
                                    value={discountPercent}
                                    type='number'
                                    onChange={(e)=>{ setDiscountPercent(e) }}
                                    disabled={disabledElement(auction, '01')}
                                    required
                                />
                            </div>
                        </>
                    : 
                        <Checkbox 
                            value={discount}
                            onChange={setDiscount}
                        >
                            Застосувати знижку
                        </Checkbox>
                                */}
                </div>
            )
        } else {
            //requestBody.discount = null
        }
        return null
    }

    async function combineUpdate(){
        let tmp
        let bodyObj = {
            base: requestBody,
        }
        if(auction.id !== null && auction.id !== ''){
            tmp = createBody(bodyObj, JSON.parse(auctionBank))
            if(auction.status === null){
                await editAucAwardPatch(props.updateAuc, requestBodyAward)
                await editAuc(props.updateAuc, tmp)
            }else if(auction.status === 'active_awarded'){
                await editAucArest(props.updateAuc, tmp)
                await editAucAwardPatch(props.updateAuc, requestBodyAward)
                await editAuc(props.updateAuc, tmp)
            }else{
                await editAucAwardPatch(props.updateAuc, requestBodyAward)
                await editAucPatch(props.updateAuc, tmp)
            }
        }else{
            tmp = createBody(bodyObj)
            createAuc(props.updateAuc, tmp)
        }
    }

    function disabledInputAward(auction, cod){
        if(
            auction.status === 'complete' ||
            auction.status === 'cancelled' ||
            auction.status === 'unsuccessful'
        ){
            return true
        }else if(auction.status === 'active_awarded' && cod === '22'){
            return true
        }
        return false
    }

    return (
        <>
            <Input //"Номер лота"
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.lotId.legalName} 
                value={lotId}
                onChange={(e)=>{ setLotId(e) }}
                disabled={disabledInputAward(auction, '01')}
                //required
            />
            <Input //Назва аукціону"
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.title.legalName} 
                value={title}
                onChange={(e)=>{ setTitle(e) }}
                disabled={disabledInputAward(auction, '01')}
                required
            />
            <TextArea //"Опис аукціону"
                value={description}
                disabled={disabledInputAward(auction, '01')}
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.description.legalName} 
                required
                onChange={(e)=>{ setDescription(e) }}
            />
            <Select //'Лот виставляється',
                data={{
                    label: "Лот виставляється", 
                    target: tenderAttempts,
                    
                    required: true,
                }}
                disabled={disabledInputAward(auction, '01')}
                onChange={(e) => { setTenderAttempts(e) }}
                options={setTenderAttemptList()}
            />
            {/* <Input //"Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
                label="Порядок та можливий час ознайомлення з лотом" 
                value={accessDetails}
                onChange={(e)=>{ setAccessDetails(e) }}
                disabled={disabledElement(auction, '01')}
            /> */}
            {/*<Input //"Перелік та вимоги до оформлення документів"
                label="Перелік та вимоги до оформлення документів"
                value={x_documentRequirements}
                onChange={(e)=>{ setX_documentRequirements(e) }}
                disabled={disabledElement(auction, '01')}
            />*/}
            <Input
                label="Додаткові відомості"
                value={x_additionalInformation}
                onChange={(e)=>{ setX_additionalInformation(e) }}
                disabled={disabledInputAward(auction, '01')}
            />
            <Select //"Тип продажу" 
                data={{
                    label: "Тип продажу", 
                    target: saleType,
                    
                    // required: true,
                }}
                onChange={(e) => { setSaleType(e) }}
                options={
                    [
                        {value: 'landSale', name: "Продаж земельних ділянок"},
                        {value: 'superficiesSale', name: "Продаж прав суперфіцію"},
                        {value: 'emphyteusisSale', name: "Продаж прав емфітевзису"}
                    ]
                }
                disabled={disabledInputAward(auction, '01')}
            />
            {/*<Input //"Обтяження прав на земельну ділянку, обмеження у використанні земель"
                label="Обтяження прав на земельну ділянку, обмеження у використанні земель"
                value={restrictions}
                onChange={(e)=>{ setRestrictions(e) }}
                disabled={disabledElement(auction, '01')}
            />
            <Input //"Містобудівні умови земельної ділянки"
                label="Містобудівні умови земельної ділянки"
                value={conditions}
                onChange={(e)=>{ setConditions(e) }}
                disabled={disabledElement(auction, '01')}
            />*/}
            <Arrests arrests={arrests} setArrests={setArrests}/>


            {discountBlock()}
            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Вартісні характеристики аукціону</div>
                <div className={stylesInput.inPutWrap}>
                    <div className={stylesInput.title}>Стартова ціна лота *</div>
                    <div className={stylesInput.content}>
                        <div className={stylesInput.inPutSelectWrap}>
                            <select className={stylesInput.inPutSelect}
                                value={valueCurrency}
                                onChange={setValueCurrency}
                                disabled={disabledInputAward(auction, '01')}
                            >
                                <option value="UAH" key='UAH' default>Гривня</option>
                            </select>
                            <div className={stylesInput.labelCur}>Валюта</div>
                        </div>
                        <input
                            className={disabledElement(auction, '01') ? stylesInput.disabled : stylesInput.inPutAmount}
                            type='number'
                            onInput={e => {
                                let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                let reg2=/^\d*(\.\d{0,2})?$/;
                                if(reg.test(e.target.value) !== true && reg2.test(e.target.value)) setValueAmount(e.target.value)
                            }}
                            disabled={disabledInputAward(auction, '01')}
                            value={valueAmount}
                        />
                        <>
                            <div className={stylesInputCheck.inPutInfo}>
                                <label className={disabledElement(auction, '01')
                                    ? `${stylesInputCheck.check} ${stylesInputCheck.disabled} ${stylesInputCheck.option}`
                                    : `${stylesInputCheck.check} ${stylesInputCheck.option}`}>
                                    <input 
                                        checked={valueAddedTaxIncluded}
                                        className={stylesInputCheck.checkInput}
                                        onChange={() => setValueAddedTaxIncluded(!valueAddedTaxIncluded)}
                                        type='checkbox'
                                        disabled={disabledInputAward(auction, '01')}
                                    >
                                    </input>
                                    <span className={stylesInputCheck.checkBox}></span>
                                </label>
                                <div className={stylesInputCheck.descript}>Податок</div>
                            </div>
                        </>
                    </div>
                </div>
                {/*<InputAmount //"Стартова ціна лота"
                    title="Стартова ціна"
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.value.amount.legalName}
                    data={{
                        amount: checkProp(value,'amount') ? value.amount : '',
                        currency: checkProp(value,'currency') ? value.currency : null,
                        addTax: checkProp(value,'valueAddedTaxIncluded') ? value.valueAddedTaxIncluded : false
                    }}
                    onChange={(e) => {
                                setValue(e)
                                // if(Number(e.amount) <= 20000){
                                //     setRegistrationFee({registrationFee, amount: 17})
                                // }else if(Number(e.amount) <= 50000){
                                //     setRegistrationFee({registrationFee, amount: 119})
                                // }else if(Number(e.amount) <= 200000){
                                //     setRegistrationFee({registrationFee, amount: 340})
                                // }else if(Number(e.amount) <= 1000000){
                                //     setRegistrationFee({registrationFee, amount: 510})
                                // }else if(Number(e.amount) > 1000000){
                                //     setRegistrationFee({registrationFee, amount: 1700})
                                // }
                    }}
                    disabled={disabledElement(auction, '01')}
                    tax
                    required
                />*/}
                {/*<InputAmount //"Гарантійний внесок"
                    title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.guarantee.legalName}
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.guarantee.amount.legalName}
                    data={{
                        amount: checkProp(guarantee,'amount') ? guarantee.amount : null,
                        currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
                    }}
                    onChange={(e) => { setGuarantee(e) }}
                    disabled={disabledElement(auction, '01')}
                    required
                />
                <InputAmount //"Розмір кроку аукціону"
                    title="Мінімальний крок"
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.minimalStep.amount.legalName}
                    data={{
                        amount: checkProp(minimalStep,'amount') ? minimalStep.amount : null,
                        currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
                    }}
                    onChange={(e) => { setMinimalStep(e) }}
                    disabled={disabledElement(auction, '01')}
                    required
                />
                <InputAmount //"Реєстраційний внесок"
                    title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.registrationFee.legalName}
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.registrationFee.amount.legalName}
                    data={{
                        amount: checkProp(registrationFee,'amount') ? registrationFee.amount : null,
                        currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
                    }}
                    onChange={(e) => { setRegistrationFee(e) }}
                    disabled={disabledElement(auction, '01')}
                    required
                />
                <InputAmount //"Експертна грошова оцінка"
                    title="Експертна грошова оцінка"
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.registrationFee.amount.legalName}
                    data={{
                        amount: checkProp(expertMonetaryValuation,'amount') ? expertMonetaryValuation.amount : null,
                        currency: checkProp(expertMonetaryValuation,'currency') ? expertMonetaryValuation.currency : null,
                    }}
                    onChange={(e) => { setExpertMonetaryValuation(e) }}
                    disabled={disabledElement(auction, '01')}
                />
                <InputAmount //"Нормативна грошова оцінка"
                    title="Нормативна грошова оцінка"
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.registrationFee.amount.legalName}
                    data={{
                        amount: checkProp(normativeMonetaryValuation,'amount') ? normativeMonetaryValuation.amount : null,
                        currency: checkProp(normativeMonetaryValuation,'currency') ? normativeMonetaryValuation.currency : null,
                    }}
                    onChange={(e) => { setNormativeMonetaryValuation(e) }}
                    disabled={disabledElement(auction, '01')}
                />
                <InputAmount //"Сума витрат (видатків), здійснених на підготовку лота"
                    title="Сума витрат (видатків), здійснених на підготовку лота"
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.value.amount.legalName}
                    data={{
                        amount: checkProp(budgetSpent,'amount') ? budgetSpent.amount : null,
                        currency: checkProp(budgetSpent,'currency') ? budgetSpent.currency : null,
                        addTax: checkProp(budgetSpent,'valueAddedTaxIncluded') ? budgetSpent.valueAddedTaxIncluded : false
                    }}
                    onChange={(e) => {setBudgetSpent(e)}}
                    disabled={disabledElement(auction, '01')}
                    tax
                />*/}
                {/* <div style={{height: '20px'}}></div>
                <Checkbox 
                        value={valueAddedTaxCharged === null ? false : valueAddedTaxCharged}
                        onChange={setValueAddedTaxCharged}
                        disabled={disabledElement(auction, '01')}
                    >
                        На фінальну суму нараховується ПДВ
                </Checkbox> */}
                <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Рішення щодо кваліфікації учасників</div>
                    <Input //Назва аукціону"
                        label="Назва рішення про продаж майна"
                        value={titleAward}
                        onChange={(e)=>{ setTitleAward(e) }}
                        disabled={disabledInputAward(auction, '01')}
                    />
                    <Input //Назва аукціону"
                        label="Опис рішення про продаж майна"
                        value={descriptionAwards}
                        onChange={(e)=>{ setDescriptionAwards(e) }}
                        disabled={disabledInputAward(auction, '01')}
                    />
                    {/*<Select 
                        data={{
                            label: 'Статус рішення щодо продажу майна зебезпеченому кредитору',
                            target: statusAwards,
                            
                            required: true,
                        }}
                        onChange={(e) => { setStatusAwards(e) }}
                        options={[
                            {name: 'Обрати', value: null},
                            {name: 'Очікується протокол про продаж', value: 'pending'},
                            {name: 'Очікується акт/договір про продаж майна (не обов\'язково)', value: 'active '},
                        ]}
                        disabled={disabledInput(auction, '01', true)}
                    />*/}
                    <div className={styles.costBlock}>
                        <InputAmount //"Гарантійний внесок"
                            title="Цінова пропозиція"
                            lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.guarantee.amount.legalName}
                            data={{
                                amount: checkProp(valueAwards,'amount') ? valueAwards.amount : '',
                                currency: checkProp(valueAwards,'currency') ? valueAwards.currency : null,
                                auctionType: 'brd'
                            }}
                            onChange={(e) => { setValueAwards(e) }}
                            disabled={disabledInputAward(auction, '22')}
                        />
                    </div>
                    <div className={styles.costBlock}>
                        <div className={styles.costBlockTitle}>Інформація про покупця</div>
                        <Input //Повна юридична назва організації або ПІБ
                            label='Повна юридична назва організації або ПІБ'
                            value={identifierLegalNameAwards}
                            // onChange={(e)=>{ setNameAwards(e) }}
                            disabled='true'
                        />
                        <div className={styles.orgBlock}>
                            <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                            <Input //Ідентифікатори організації
                                label='Повна юридична назва організації'
                                value={identifierLegalNameAwards}
                                onChange={(e)=>{ 
                                    setIdentifierLegalNameAwards(e)
                                }}
                                disabled={disabledInputAward(auction, '01')}
                                required
                            />
                            <Select 
                                data={{
                                    label: 'Ідентифікатори організації',
                                    target: identifierSchemeAwards,
                                    
                                    required: true,
                                }}
                                onChange={(e) => { setIdentifierSchemeAwards(e) }}
                                options={[
                                    {name: 'Обрати', value: null},
                                    {name: 'ЄДРПОУ', value: 'UA-EDR'},
                                    {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                                    {name: 'Паспорт', value: 'UA-PASSPORT'},
                                    {name: 'ID-карта', value: 'UA-ID-CARD'},
                                    {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                                ]}
                                disabled={disabledInputAward(auction, '01')}
                            />
                            <Input //Код ЄДРПОУ або ІПН або паспорт
                                label='Код ЄДРПОУ або ІПН або паспорт'
                                value={identifierIdAwards}
                                onChange={(e)=>{ setIdentifierIdAwards(e) }}
                                disabled={disabledInputAward(auction, '01')}
                                required
                            />
                        </div>
                        <LocationOrg
                            address={addressAwards}
                            setAdress={setAdressAwards}
                            dataText='Місцезнаходження учасника'
                            disabled={disabledInputAward(auction, '01')}
                        />
                        <div className={styles.orgBlock}>
                            <div className={styles.costBlockTitle}>Контактна особа</div>
                            <Input //ПІБ
                                label='ПІБ'
                                value={contactPointNameAwards}
                                onChange={(e)=>{ setContactPointNameAwards(e) }}
                                disabled={disabledInputAward(auction, '01')}
                                required
                            />
                            <Input //Адреса електронної пошти
                                label='Адреса електронної пошти'
                                value={contactPointEmailAwards}
                                onChange={(e)=>{ setContactPointEmailAwards(e) }}
                                disabled={disabledInputAward(auction, '01')}
                                required
                            />
                            <Input //Номер телефону
                                label='Номер телефону'
                                value={contactPointTelephoneAwards}
                                onChange={(e)=>{ setContactPointTelephoneAwards(e) }}
                                disabled={disabledInputAward(auction, '01')}
                                required
                            />
                            <Input //Номер факсу
                                label='Номер факсу'
                                value={contactPointFaxNumberAwards}
                                onChange={(e)=>{ setContactPointFaxNumberAwards(e) }}
                                disabled={disabledInputAward(auction, '01')}
                            />
                            <Input //Веб адреса
                                label='Веб адреса'
                                value={contactPointUrlAwards}
                                onChange={(e)=>{ setContactPointUrlAwards(e) }}
                                disabled={disabledInputAward(auction, '01')}
                            />
                        </div>
                        {/*<Input //№ свідоцтва про право на провадження діяльності арбітражного керуючого
                            label='Інформація щодо підтвердження повноважень'
                            value={contactRepresentativeInfoAwards}
                            onChange={(e)=>{ setContactRepresentativeInfoAwards(e) }}
                            disabled={disabledInputAward(auction, '01', true)}
                        />*/}
                    </div>
                </div>
            </div>
            {/* <div className={styles.startDate}>
                <div className={styles.costBlockTitle}>
                    {`${TranslationsUk['basicSell-english'].basicSellEnglishProcedure.auctionPeriod.startDate.legalName} аукціону`}
                </div>
                <InputDate
                    data={{
                        target: startDate,
                        time: true
                    }}
                    onChange={ e => setStartDate(e) }
                    disabled={disabledElement(auction, '11')}
                />
            </div> */}
            <BankAccount bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} />

            <div className={styles.bottomButtonBlock}>
                <Button
                    height={'50px'}
                    font={'16px'}
                    color='blue'
                    data={'Зберегти зміни'}
                    onClick={
                        () =>{
                            combineUpdate()
                        }
                    }
                />
            </div>
        </>
    )
}

export default AuctionLAW