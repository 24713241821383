import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {changeCreateAuctionLotIdentifier} from '../../../redux/actions.js';
import InputText from '../../elements/inputs/input';

import TextField from '@material-ui/core/TextField';
import {TranslationsUk} from '../../../redux/translations.uk.js';

import {disabledElement} from '../disabled'

const WindowAuctionSelfNumber = ( props ) => {
    let tmp;
    let tmpText
    if(props.auctionsMy.hasOwnProperty('lotIdentifier')){
        tmpText = props.auctionsMy.lotIdentifier
    }else{
        tmpText = props.auctionsMy.lotId
    }
    if(props.typeElem === 1){
        tmp = <InputText
                disabled={disabledElement(props.auctionsMy, '00')}
                label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.lotId.legalName}
                value={tmpText}
                onChange={
                    (e) => {
                        props.changeCreateAuctionLotIdentifier(e)
                    }
                }
                required
            />
            {/* <div>
                <TextField
                    disabled={disabledElement(props.auctionsMy, '00')}
                    id="filled-required"
                    className={styles.inputText}
                    label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.lotId.legalName}
                    defaultValue={tmpText}
                    variant="outlined"
                    onChange={
                        (e) => {
                            props.changeCreateAuctionLotIdentifier(e.target.value)
                        }
                    }
                    helperText="Обов'язкове поле"
                />
            </div> */}
    }else{
        tmp = <div>
                <TextField
                    disabled={disabledElement(props.auctionsMy, '00')}
                    className={styles.inputText}
                    label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.lotId.legalName}
                    defaultValue={tmpText}
                    variant="outlined"
                    onChange={
                        (e) => {
                            props.changeCreateAuctionLotIdentifier(e.target.value)
                        }
                    }
                    helperText="Обов'язкове поле"
                />
            </div> 
     
    }
    return tmp
}

function elementDisabled(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    changeCreateAuctionLotIdentifier,
    elementDisabled,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionSelfNumber)
