import React, {useState} from 'react'
import styles from './bidAddDocNewPopup.module.css'

import {connect} from 'react-redux';

import {
    auctionsBidsGetId,
} from '../../../redux/actions/auctionBid/auctionBid.js';

import {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,
} from '../../../redux/actions/awards/awards.js';

import {
    changeContractApprowePopUpStatus,
    changeContractErrorPopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
} from '../../../redux/actions/contracts/contracts.js';

import {setUniversalError, changeLoader} from '../../../redux/actions.js';

import {contractsAuctionIdGet, saveHistoryDocsContracts} from '../../../redux/actions/contracts/contracts.js';

import {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,
} from '../../../redux/togetherApi.js';
import {ecpApiPass, ecpApiList, changeCA} from '../../../redux/ecpApi.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../redux/actions/documents/documents.js';
//import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import ESignCheckBlock from '../../elements/ecp/ecpCheckBlock';

import {editAwardDoc} from '../../../redux/editAwardDoc.js';



let files

const BidAddDocPopUp = (props) => {
    const [dropArea, setDropArea] = useState({text: 'Додайте файли документів', style: styles.dropZone })

    

    function dragStartHandler(e) {
        e.preventDefault()
        setDropArea({text: 'Відпустіть мишу для завантаження файлу', style: styles.dropZoneActive })
    }

    function dragLeaveHandler(e) {
        e.preventDefault()
        setDropArea({text: 'Додайте файли документів', style: styles.dropZone })
    }
    
    function onDporHandler(e) {
        e.preventDefault()
        files = [...e.dataTransfer.files]
         let tmp = <>
                        <div>{`Додано файл:
                            ${files[0].name.length > 45 ? files[0].name.substr(0, 50) + '...' : files[0].name}`}
                        </div>
                        <div>
                            {`розмір: ${(files[0].size / 1000000).toFixed(2)} Mb, тип: ${files[0].type}`}
                        </div>
                    </>
                    setDropArea({
                        text: tmp, style: styles.dropZoneActive
                    })
    }

    let tmpTypeDoc = 'null'
    let tmpTitle = ''
    let tmpDescription = ''
    if(props.popUpContractUniversal !== null){
        tmpTypeDoc = props.popUpContractUniversal.type
        tmpTitle = props.popUpContractUniversal.title
        tmpDescription = props.popUpContractUniversal.description
    }

    let tmpTypeOf
    let tmpUrlNew
    let tmpUrl = null
    let tmpUrlKep = null
    let tmpRelatedDocument = null
    if(props.popUpContractUniversal.type === 'auctionProtocol'){
        if(props.userProfiles.canCreateBid === true){
            tmpTypeOf = 'bid'
            if(props.popUpContractUniversal.id === null){
                tmpUrlNew = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/bid/${props.popUpContractApprowe.currentUser.bid.id}/documents`
            }else{
                tmpUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/bid/${props.popUpContractApprowe.currentUser.bid.id}/documents/${props.popUpContractUniversal.id}`
                tmpUrlNew = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/bid/${props.popUpContractApprowe.currentUser.bid.id}/documents`
                if(props.auctionAwards !== null){
                    if(props.auctionAwards.length > 0){
                        for(let i = 0; i < props.auctionAwards.length; i++){
                            if(props.auctionAwards[i].id === props.popUpContractApprowe.currentUser.id){
                                if(props.auctionAwards[i].bid !== null){
                                    if(props.auctionAwards[i].bid.documents !== null){
                                        if(props.auctionAwards[i].bid.documents.length > 0){
                                            for(let t = 0; t < props.auctionAwards[i].bid.documents.length; t++){
                                                if(props.auctionAwards[i].bid.documents[t].id === props.popUpContractUniversal.id){
                                                    tmpRelatedDocument = props.auctionAwards[i].bid.documents[t].documentId
                                                }
                                            }
                                            if(tmpRelatedDocument !== null){
                                                for(let d = 0; d < props.auctionAwards[i].bid.documents.length; d++){
                                                    if(props.auctionAwards[i].bid.documents[d].relatedDocument === tmpRelatedDocument){
                                                        tmpUrlKep = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/bid/${props.popUpContractApprowe.currentUser.bid.id}/documents/${props.auctionAwards[i].bid.documents[d].id}`
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                //tmpUrlKep = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/bid/${props.popUpContractApprowe.currentUser.bid.id}/documents/${props.popUpContractUniversal.id}`
            }
        }else{
            tmpTypeOf = 'award'
            if(props.popUpContractUniversal.id === null){
                tmpUrlNew = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.contractId}/documents`
            }else{
                tmpUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/documents/${props.popUpContractUniversal.id}`
                tmpUrlNew = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/documents`
                if(props.auctionAwards !== null){
                    if(props.auctionAwards.length > 0){
                        for(let i = 0; i < props.auctionAwards.length; i++){
                            if(props.auctionAwards[i].id === props.popUpContractApprowe.currentUser.id){
                                if(props.auctionAwards[i].documents !== null){
                                    if(props.auctionAwards[i].documents.length > 0){
                                        for(let t = 0; t < props.auctionAwards[i].documents.length; t++){
                                            if(props.auctionAwards[i].documents[t].id === props.popUpContractUniversal.id){
                                                tmpRelatedDocument = props.auctionAwards[i].documents[t].documentId
                                            }
                                        }
                                        if(tmpRelatedDocument !== null){
                                            for(let d = 0; d < props.auctionAwards[i].documents.length; d++){
                                                if(props.auctionAwards[i].documents[d].relatedDocument === tmpRelatedDocument){
                                                    tmpUrlKep = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/documents/${props.auctionAwards[i].documents[d].id}`
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }else{
        tmpTypeOf = 'award'
        if(props.popUpContractUniversal.id === null){
            tmpUrlNew = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.contractId}/documents`
        }else{
            tmpUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/documents/${props.popUpContractUniversal.id}`
            tmpUrlNew = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/documents`
            if(props.auctionAwards !== null){
                if(props.auctionAwards.length > 0){
                    for(let i = 0; i < props.auctionAwards.length; i++){
                        if(props.auctionAwards[i].id === props.popUpContractApprowe.currentUser.id){
                            if(props.auctionAwards[i].documents !== null){
                                if(props.auctionAwards[i].documents.length > 0){
                                    for(let t = 0; t < props.auctionAwards[i].documents.length; t++){
                                        if(props.auctionAwards[i].documents[t].id === props.popUpContractUniversal.id){
                                            tmpRelatedDocument = props.auctionAwards[i].documents[t].documentId
                                        }
                                    }
                                    if(tmpRelatedDocument !== null){
                                        for(let d = 0; d < props.auctionAwards[i].documents.length; d++){
                                            if(props.auctionAwards[i].documents[d].relatedDocument === tmpRelatedDocument){
                                                tmpUrlKep = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/documents/${props.auctionAwards[i].documents[d].id}`
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    let tmpDocId = null
    if(props.popUpContractUniversal.id !== null) tmpDocId = props.popUpContractUniversal.id

    let dataFilesAtribute = {
        actionNo: null,
        // closeAC: props.changeContractApprowePopUpStatus,/
    }
    if(files){
        dataFilesAtribute.fileName = tmpTitle
        dataFilesAtribute.fileType = tmpTypeDoc
        dataFilesAtribute.fileDiscription = tmpDescription
        dataFilesAtribute.fileFile = files
        dataFilesAtribute.uploadUrlNew = tmpUrlNew
        dataFilesAtribute.uploadUrl = tmpUrl
        dataFilesAtribute.uploadUrlKep = tmpUrlKep
        dataFilesAtribute.documentType = tmpTypeOf
        dataFilesAtribute.id = tmpDocId
        dataFilesAtribute.relatedDocument = tmpRelatedDocument
        dataFilesAtribute.changeLoader = props.changeLoader
        //dataFilesAtribute.combineUpadateKEP = combineUpadateKEP
        dataFilesAtribute.auctionsGetAwards = props.auctionsGetAwards
        dataFilesAtribute.contractsAuctionIdGet = props.contractsAuctionIdGet
        dataFilesAtribute.auctionsBidsGetId = props.auctionsBidsGetId
        dataFilesAtribute.aucId = props.popUpContractApprowe.aucId
    }
    return (
            <div className={styles.wrapMain}>
            <div className={styles.wrap}>
                <div
                    className={styles.close}
                    onClick={
                        () => {
                            props.setContractDocId(null)
                            props.setContractDocTitle('')
                            props.setContractDocType('null')
                            props.setContractDocDescription('')
                            props.changeDocumentFileName(null)
                            props.changeCA('null')
                            props.togetherApiDocFileName(null)
                            props.ecpApiPass('')
                            props.changeContractApprowePopUpStatus(null)
                        }
                    }
                >
                    ✕
                </div>
                {namePopUp(props)}
                <div className={styles.bidLotCost_input}>
                    {/*<Select
                        text='Тип документу'
                        list={}
                    /> */}
                    {viewSelect(props)}

                    <input 
                        className={styles.inputText}
                        type='text'
                        placeholder='Публічна назва документу'
                        value={props.popUpContractUniversal.title}
                        onInput={(e) => {
                            // props.changeBidAward(e.target.value)
                            props.setContractDocTitle(e.target.value)
                        }}
                    />
                    <textarea
                        className={styles.inputTextArea}
                        placeholder='Опис документу'
                        value={props.popUpContractUniversal.description}
                        onInput={(e) => {
                            props.setContractDocDescription(e.target.value)
                        }}
                    />


                    <div className={dropArea.style}
                        onDragStart={ e => dragStartHandler(e)}
                        onDragLeave={ e => dragLeaveHandler(e)}
                        onDragOver={ e => dragStartHandler(e)}
                        onDrop={ e => onDporHandler(e)}
                    >
                        <p>{dropArea.text}</p>
                        <label className={styles.btnPin}>Прикріпити
                            <input type="file"
                                name="file"
                                id="inputFile"
                            onInput={(e) => {
                                    e.preventDefault()
                                    files = document.querySelector('#inputFile').files
                                let tmp = <>
                                    <div>{`Додано файл:
                                        ${files[0].name.length > 45 ? files[0].name.substr(0, 50) + '...' : files[0].name}`}
                                    </div>
                                        <div>
                                            {`розмір: ${(files[0].size / 1000000).toFixed(2)} Mb, тип: ${files[0].type}`}
                                        </div>
                                            </>
                                    setDropArea({
                                        text: tmp, style: styles.dropZoneActive
                                    })
                                        // props.changeBidAward(e.target.value)
                                    }}></input>
                        </label>
                    </div>
                </div>
                <ESignCheckBlock 
                    dataFilesAtribute={dataFilesAtribute}
                />
                {/*
                <div className={styles.bottomPart}>
                    <div className={styles.btnCancel}
                        onClick={
                            () => {
                                props.setContractDocId(null)
                                props.setContractDocTitle('')
                                props.setContractDocType('null')
                                props.setContractDocDescription('')
                                props.changeDocumentFileName(null)
                                props.changeCA('null')
                                props.togetherApiDocFileName(null)
                                props.ecpApiPass('')
                                props.changeContractApprowePopUpStatus(null)
                            }
                        }
                    >
                        Скасувати
                    </div>
                    {viewButtonsSave(props)}
                </div>
                */}
                {buttonsBlock(props)}
            </div>
        </div>
    )
}

function buttonsBlock(props){
    if(props.togetherApiPage.ecpOnOff === false){
        return (
            <div className={styles.bottomPart}>
                <div className={styles.btnCancel}
                    onClick={
                        ()=>{
                            props.setContractDocId(null)
                            props.setContractDocTitle('')
                            props.setContractDocType('null')
                            props.setContractDocDescription('')
                            props.changeDocumentFileName(null)
                            props.changeCA('null')
                            props.togetherApiDocFileName(null)
                            props.ecpApiPass('')
                            props.changeContractApprowePopUpStatus(null)
                        }
                    }
                >
                    Скасувати
                </div>
                {viewButtonsSave(props)}
            </div>
        )
    }
    return null
}

function viewButtonsSave(props){
    if(
        props.popUpContractUniversal.title !== '' &&
        props.popUpContractUniversal.title !== null &&
        props.popUpContractUniversal.type !== 'null' &&
        props.popUpContractUniversal.type !== null
    ){
        return (
            <div className={styles.btnDone}
                onClick={
                    () => {
                        if(props.popUpContractUniversal.id === null){
                            uploadNewDoc(props)
                        }else{
                            if(files === undefined || files === 'undefined'){
                                patchNewDocWithoutFiles(props)
                            }else{
                                patchNewDocFiles(props)
                            }
                        }
                    }
                }
            >
                Зберегти
            </div>
        )
    }
    return null
}

function viewSelect(props){
    if(props.popUpContractUniversal.id !== null){
        return(
            <div className={styles.selectWrap}>
                <select
                    disabled='disabled'
                    className={styles.selectTypeFile}
                    value={props.popUpContractUniversal.type}
                    onChange={
                        (e)=>{
                            props.setContractDocType(e.target.value)
                        }
                    }
                >
                    <option selected default value='null'>Обрати</option>
                    {listDoc(props)}
                </select>
            </div>
        )    
    }
    return(
        <div className={styles.selectWrap}>
            <select
                className={styles.selectTypeFile}
                value={props.popUpContractUniversal.type}
                onChange={
                    (e)=>{
                        props.setContractDocType(e.target.value)
                    }
                }
            >
                <option selected default value='null'>Обрати</option>
                {listDoc(props)}
            </select>
        </div>
    )
}

function listDoc(props) {
    if(props.popUpContractApprowe.fullAuction.privateData.isOwner === true && props.popUpContractApprowe.currentUser.status !== 'active'){
        return (
            <>
                <option key={1} value='auctionProtocol'>Протокол аукціону</option>
                <option key={3} value='rejectionProtocol'>Документ, що підтверджує дискваліфікацію</option>
                <option key={4} value='act'>Документ, що підтверджує відмову</option>
            </>
        )
    }if(props.popUpContractApprowe.fullAuction.privateData.isOwner === true && props.popUpContractApprowe.currentUser.status === 'active'){
        return (
            <>
                <option key={3} value='rejectionProtocol'>Документ, що підтверджує дискваліфікацію</option>
                <option key={4} value='act'>Документ, що підтверджує відмову</option>
            </>
        )
    }else if(props.popUpContractApprowe.currentUser.bid.createdByCurrentUser === true && props.popUpContractApprowe.currentUser.status === 'pending'){
        return (
            <>
                <option key={1} value='auctionProtocol'>Протокол аукціону</option>
            </>
        )
    }else if(props.popUpContractApprowe.currentUser.bid.createdByCurrentUser === true && props.popUpContractApprowe.currentUser.status === 'active'){
        if(props.popUpContractApprowe.currentUser.bid.isCurrentTenant !== null){
            if(props.popUpContractApprowe.currentUser.bid.isCurrentTenant === false){
                return (
                    <>
                        <option key={1} value='auctionProtocol'>Протокол аукціону</option>
                        <option key={2} value='transferAct'>Акт приймання-передачі (обов'язково)</option>
                    </>
                )
            }else{
                return (
                    <>
                        <option key={1} value='auctionProtocol'>Протокол аукціону</option>
                        <option key={2} value='transferAct'>Акт приймання-передачі</option>
                    </>
                )
            }
        }else{
            return (
                <>
                    <option key={1} value='auctionProtocol'>Протокол аукціону</option>
                </>
            )
        }
    }else if(props.popUpContractApprowe.currentUser.bid.createdByCurrentUser === true && props.popUpContractApprowe.currentUser.status === 'pending_waiting'){
        return (
            <>
                <option key={2} value='act'>Документ, що підтверджує відмову</option>
            </>
        )
    }
    return null
}

function namePopUp(props){
    if(props.bidEdit !== null){
        if(props.bidEdit.hasOwnProperty('id') === true){
            return (
                <>
                    <h3 className={styles.popupTitle}>Редагувати документ</h3>
                    <p className={styles.description}>Ви редагуєте документ кваліфікації</p>
                </>
            )
        }
    }
    return (
        <>
            <h3 className={styles.popupTitle}>Додати документ</h3>
            <p className={styles.description}>Ви додаєте документ до кваліфікації</p>
        </>
    )
}

async function patchNewDocWithoutFiles(props){
    await props.changeLoader(true)
    let body = {}
    if(props.popUpContractUniversal.title !== null && props.popUpContractUniversal.title !== '') body.title = props.popUpContractUniversal.title
    if(props.popUpContractUniversal.description !== null && props.popUpContractUniversal.description !== '') body.description = props.popUpContractUniversal.description
    if(props.popUpContractUniversal.type !== null && props.popUpContractUniversal.type !== '') body.documentType = props.popUpContractUniversal.type
    let endPoint = ``
    if(props.popUpContractUniversal.documentOf === 'award'){
        body.documentOf = 'award'
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/documents/${props.popUpContractUniversal.id}`
    }
    if(props.popUpContractUniversal.documentOf === 'bid'){
        body.documentOf = 'bid'
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpContractApprowe.aucId}/bid/${props.popUpContractApprowe.currentUser.bid.id}/documents/${props.popUpContractUniversal.id}`
    }
    body.language = 'uk'
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/document/${props.popUpContractUniversal.id}`
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/document/${props.popUpContractUniversal.id}`
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/documents/${props.popUpContractUniversal.id}`
    await fetch(endPoint, {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error);
    let data1 = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag
    }
    let headers1 = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    await props.contractsAuctionIdGet(data1, headers1, props.auctionId.id)
    await getContractsAuctionHistory(props)
    await props.changeLoader(false)
    await props.setContractDocId(null)
    await props.setContractDocTitle('')
    await props.setContractDocType('null')
    await props.setContractDocDescription('')
    await props.changeContractApprowePopUpStatus(null)
}

async function patchNewDocFiles(props){
    await props.changeLoader(true)
    const addFileInp = files;
    //const addFileInp = document.getElementById('input__fileDel');
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    const formData = new FormData();
    //formData.append('Content-Type', 'multipart/form-data')
    formData.append('documentType', props.popUpContractUniversal.type)
    formData.append('file', addFileInp[0])
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при збереженні файлу', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{
            let body = {}
            if(props.popUpContractUniversal.title !== null && props.popUpContractUniversal.title !== '') body.title = props.popUpContractUniversal.title
            if(props.popUpContractUniversal.description !== null && props.popUpContractUniversal.description !== '') body.description = props.popUpContractUniversal.description
            if(props.popUpContractUniversal.type !== null && props.popUpContractUniversal.type !== '') body.documentType = props.popUpContractUniversal.type
            body.language = 'uk'
            //body.documentOf = 'award'
            body.token = json.jwt
            //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/document/${props.popUpContractUniversal.id}`
            //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.contractId}/document/${props.popUpContractUniversal.id}`
            let endPoint = ''
            if(props.popUpContractUniversal.documentOf === 'award'){
                body.documentOf = 'award'
                endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/documents/${props.popUpContractUniversal.id}`
            }
            if(props.popUpContractUniversal.documentOf === 'bid'){
                body.documentOf = 'bid'
                endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpContractApprowe.aucId}/bid/${props.popUpContractApprowe.currentUser.bid.id}/documents/${props.popUpContractUniversal.id}`
            }
            await fetch(endPoint, {
                method: "PATCH",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error);


            await setTimeout(() => combineUpadate(props, props.auctionId.id), 2000)
        }
    }
}

async function combineUpadate(props, auctionId){
    let data1 = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag
    }
    let headers1 = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    await props.auctionsGetAwards(data1, headers1, auctionId)
    await props.contractsAuctionIdGet(data1, headers1, auctionId)
    //await getContractsAuctionHistory(props)
    await props.changeLoader(false)
    await props.setContractDocId(null)
    await props.setContractDocTitle('')
    await props.setContractDocType('null')
    await props.setContractDocDescription('')
    await props.changeContractApprowePopUpStatus(null)
    await props.changeLoader(false)
    files = undefined
}

async function getContractsAuctionHistory(props){
    //const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/award/${props.popUpContractApprowe.currentUser.id}/documents`;
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/awards/${props.popUpContractApprowe.currentUser.id}/documents`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        
    }else{
        const json = await response.json()
        props.saveHistoryDocsContracts(json)
    }
}

/////////////////////////////////////////////////////
/*async function uploadNewDoc(props){
    await props.changeLoader(true)
    const addFileInp = files;
    let endPoint = ``;
    if(
        props.popUpContractApprowe.sellingMethod === "dgf-english" ||
        props.popUpContractApprowe.sellingMethod === "dgf-english-fast" ||
        props.popUpContractApprowe.sellingMethod === "dgf-english-fast-manual" ||
        props.popUpContractApprowe.sellingMethod === "dgf-english-fast-auction-manual-qualification" ||
        props.popUpContractApprowe.sellingMethod === "dgf-english-initial-auction" ||
        props.popUpContractApprowe.sellingMethod === "dgf-english-initial-auction-manual" ||
        props.popUpContractApprowe.sellingMethod === "dgf-english-initial-qualification" ||
        props.popUpContractApprowe.sellingMethod === "dgf-dutch" ||
        props.popUpContractApprowe.sellingMethod === "dgf-dutch-fast" ||
        props.popUpContractApprowe.sellingMethod === "dgf-dutch-fast-manual" ||
        props.popUpContractApprowe.sellingMethod === "dgf-dutch-fast-auction-manual-qualification" ||
        props.popUpContractApprowe.sellingMethod === "dgf-dutch-initial-auction" ||
        props.popUpContractApprowe.sellingMethod === "dgf-dutch-initial-qualification" ||
        props.popUpContractApprowe.sellingMethod === "dgf-dutch-initial-auction-manual"

    ){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload?isDgf=1`;
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    }
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    const formData = new FormData();
    //formData.append('Content-Type', 'multipart/form-data')
    formData.append('documentType', props.popUpContractUniversal.type)
    formData.append('file', addFileInp[0])
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при збереженні файлу', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{
            const endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
            let typeOfAward = 'award'
            //if(props.awardReject.type === 'auctionProtocol') typeOfAward = 'bid'
            if(props.popUpContractUniversal.type === 'auctionProtocol'){
                if(props.userProfiles.canCreateBid === true){
                    typeOfAward = 'bid'
                }else{
                    typeOfAward = 'award'
                }
            }else{
                typeOfAward = 'award'
            }
            let body = {}
            if(props.popUpContractUniversal.title !== null && props.popUpContractUniversal.title !== '') body.title = props.popUpContractUniversal.title
            if(props.popUpContractUniversal.description !== null && props.popUpContractUniversal.description !== '') body.description = props.popUpContractUniversal.description
            if(props.popUpContractUniversal.type !== null && props.popUpContractUniversal.type !== '') body.documentType = props.popUpContractUniversal.type
            body.language = 'uk'
            body.documentOf = typeOfAward
            body.token = json.jwt
            const response1 = await fetch(endPoint1, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response1 === undefined || response1 === 'undefined'){
            }else{
                const json1 = await response1.json()
                if(json1.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при збереженні документу', message: JSON.stringify(json1)})
                    props.setUniversalError(tmp)
                    await props.changeLoader(false)
                }else{
                    let endPoint2 = null
                    if(props.popUpContractUniversal.type === 'auctionProtocol'){
                        if(props.userProfiles.canCreateBid === true){
                            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/bid/${props.popUpContractApprowe.bid.bid.id}/documents/${json1.id}`
                        }else{
                            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.contractId}/documents/${json1.id}`
                        }
                    }else{
                        endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.contractId}/documents/${json1.id}`
                    }
                    //const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/documents/${json1.id}`
                    const response2 = await fetch(endPoint2, {
                        method: "POST",
                        headers: {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    }).catch(console.error)
                    if(response2 === undefined || response2 === 'undefined'){
                    }else{
                        const json2 = await response2.json()
                        if(json2.hasOwnProperty('message') === true){
                            let tmp = JSON.stringify({title: 'Помилка при доданні документу до контракту', message: JSON.stringify(json2)})
                            props.setUniversalError(tmp)
                            await props.changeLoader(false)
                        }else{
                            let data1 = {
                                domen: process.env.REACT_APP_END_POINT_BD,
                                version: 'v1',
                                local: 'uk'
                            }
                            let headers1 = {
                                'Authorization': props.token.access_token,
                                'Content-Type': 'application/json'
                            }
                            await props.auctionsGetAwards(data1, headers1, props.auctionId.id)
                            await props.contractsAuctionIdGet(data1, headers1, props.auctionId.id)
                            await props.changeLoader(false)
                            await props.setContractDocId(null)
                            await props.setContractDocTitle('')
                            await props.setContractDocType('null')
                            await props.setContractDocDescription('')
                        }                
                    }
                }
            }
        }
    }
    await props.changeLoader(false)
    await props.setContractDocId(null)
    await props.setContractDocTitle('')
    await props.setContractDocType('null')
    await props.setContractDocDescription('')
    await props.changeContractApprowePopUpStatus(null)
}*/

async function uploadNewDoc(props){
    await props.changeLoader(true)
    const addFileInp = files;
    let endPoint;
    if(
        props.popUpContractApprowe.sellingMethod === "dgf-english" ||
        props.popUpContractApprowe.sellingMethod === "dgf-english-fast" ||
        props.popUpContractApprowe.sellingMethod === "dgf-english-fast-manual" ||
        props.popUpContractApprowe.sellingMethod === "dgf-english-fast-auction-manual-qualification" ||
        props.popUpContractApprowe.sellingMethod === "dgf-english-initial-auction" ||
        props.popUpContractApprowe.sellingMethod === "dgf-english-initial-auction-manual" ||
        props.popUpContractApprowe.sellingMethod === "dgf-english-initial-qualification" ||
        props.popUpContractApprowe.sellingMethod === "dgf-dutch" ||
        props.popUpContractApprowe.sellingMethod === "dgf-dutch-fast" ||
        props.popUpContractApprowe.sellingMethod === "dgf-dutch-fast-manual" ||
        props.popUpContractApprowe.sellingMethod === "dgf-dutch-fast-auction-manual-qualification" ||
        props.popUpContractApprowe.sellingMethod === "dgf-dutch-initial-auction" ||
        props.popUpContractApprowe.sellingMethod === "dgf-dutch-initial-qualification" ||
        props.popUpContractApprowe.sellingMethod === "dgf-dutch-initial-auction-manual"

    ){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload?isDgf=1`;
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    }
    const formData = new FormData();
    formData.append('documentType', props.popUpContractUniversal.type)
    formData.append('file', addFileInp[0])
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при збереженні файлу', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{
            //const endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
            let endPoint1
            let typeOfAward
            //if(props.awardReject.type === 'auctionProtocol') typeOfAward = 'bid'
            if(props.popUpContractUniversal.type === 'auctionProtocol'){
                if(props.userProfiles.canCreateBid === true){
                    typeOfAward = 'bid'
                    endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpContractApprowe.aucId}/bid/${props.popUpContractApprowe.bid.bid.id}/documents`
                }else{
                    typeOfAward = 'award'
                    endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.contractId}/documents`
                }
            }else{
                typeOfAward = 'award'
                endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.contractId}/documents`
            }
            let body = {}
            if(props.popUpContractUniversal.title !== null && props.popUpContractUniversal.title !== '') body.title = props.popUpContractUniversal.title
            if(props.popUpContractUniversal.description !== null && props.popUpContractUniversal.description !== '') body.description = props.popUpContractUniversal.description
            if(props.popUpContractUniversal.type !== null && props.popUpContractUniversal.type !== '') body.documentType = props.popUpContractUniversal.type
            body.language = 'uk'
            body.documentOf = typeOfAward
            body.token = json.jwt
            const response1 = await fetch(endPoint1, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response1 === undefined || response1 === 'undefined'){
            }else{
                const json2 = await response1.json()
                if(json2.hasOwnProperty('message') === true){
                    props.setUniversalError(JSON.stringify({title: 'Помилка при збереженні документу', message: JSON.stringify(json2)}))
                    await props.changeLoader(false)
                }else{
                    await setTimeout(() => combineUpadate(props, props.auctionId.id), 2000)
                }
            }
        }
    }
    //await props.changeLoader(false)
    await props.setContractDocId(null)
    await props.setContractDocTitle('')
    await props.setContractDocType('null')
    await props.setContractDocDescription('')
    await props.changeContractApprowePopUpStatus(null)
}




const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
        bidEdit: state.start.bidEdit,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        popUpContractApprowe: state.start.popUpContractApprowe,

        togetherApiPage: state.start.togetherApiPage,
        ecpData: state.start.ecpData,
        createDocuments: state.start.createDocuments,
        popUpContractUniversal: state.start.popUpContractUniversal,
        userProfiles: state.start.userProfiles,
        auctionAwards: state.start.auctionAwards
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,

    setUniversalError,
    changeContractApprowePopUpStatus,

    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,

    changeContractErrorPopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    
    contractsAuctionIdGet,

    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,

    ecpApiPass,
    ecpApiList,
    changeCA,

    setContractDocId,
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,
    
    changeLoader,
    editAwardDoc,

    saveHistoryDocsContracts,
    auctionsBidsGetId,
}

export default connect(mapStateToProps, mapDispatchToProps)(BidAddDocPopUp)