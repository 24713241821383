import {addProperty, addPropertyValue, previousAuctionId} from './utils'
import {addPropertyMonga} from "../../createAuction/procedure/lib/utilsCreateBody";

export function createBodyRMA(data){
    if(data !== null){
        let body = {}
        body = addProperty(data, body, 'title')
        body = addProperty(data, body, 'description')
        body = addProperty(data, body, 'bankGuaranteeDetails')
        body = addProperty(data, body, 'lotId')
        // body = addProperty(data, body, 'accessDetails')
        body = addProperty(data, body, 'tenderAttempts')
        // body = addProperty(data, body, 'lotIdentifier')
        body = addProperty(data, body, 'sellingMethod')
        if(data.base.x_documentRequirements !== null){
            body = addPropertyMonga(data, body, 'x_documentRequirements')
        }else{
            body.x_documentRequirements = null
        }
        if(data.base.x_additionalInformation !== null){
            body = addPropertyMonga(data, body, 'x_additionalInformation')
        }else{
            body.x_additionalInformation = null
        }
        body = addProperty(data, body, 'minNumberOfQualifiedBids')
        // body = addProperty(data, body, 'valueAddedTaxCharged')

        body = addPropertyValue(data, body, 'value')
        body = addPropertyValue(data, body, 'minimalStep')
        body = addProperty(data, body, 'previousAuctionId')
        // body = previousAuctionId(data, body, 'previousAuctionId')
        body = addProperty(data, body, 'auctionPeriod')
        console.log(data,body)
        return body
    }
    return null
}