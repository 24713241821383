import React from 'react';
import styles from './viewAward.module.css';

import {connect} from 'react-redux';

import ViewAward from './viewAward'

const ViewAwardList = (props) => {
    if (props?.auction?.awards?.length > 0) {
        return (
            <div className={styles.infoBlock}>
                <div className={styles.title_wrap}>{props.showWinners ? "Переможці" : "Всі учасники"}</div>
                {props.showWinners ? sortAwards(props) : viewAll(props)}
            </div>
        )
    }
    return null
}

function sortAwards(props) {
    let awards = []
    if (props.auction.awards.length > 0) {
        for (let t = 0; t < props.auction.awards.length; t++) {
            awards[t] = viewWinners(props, props.auction.awards[t])
        }
    }
    return awards
}

function viewWinners(props, awards) {
    if (props.auction.bids === null) return null
    if (props.auction.bids.length === 0) return null
    if (awards === 0) return null
    return props.auction.bids
        .filter((bid) => {
            if (bid.id === awards.bidId) {
                return bid
            }
        })
        .map((bid) => {
            return <ViewAward key={bid.id} bid={bid} awards={awards} showWinners={props.showWinners}/>
        })
}

function viewAll(props) {
    if (props.auction === null) return null
    if (props.auction.bids === null) return null
    if (props.auction.bids.length === 0) return null
    return props.auction.bids
        .filter((bid) => {
            return bid.status !== 'deleted'
        })
        .filter((bid) => {
            return bid.status !== 'draft'
        })
        .map((bid) => {
            return <ViewAward key={bid.id} bid={bid} awards={null} showWinners={props.showWinners}/>
        })
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(ViewAwardList)