import React, {useState} from 'react'
import styles from './aucInfo.module.css'

import AucDocItem from '../../../../documents/docItem'
import InstructionBlock from '../../../../elements/viewData/instructionBlock/instructionBlock'
import AddDocPopup from '../../../../documents/addDocPopup/addDocPopup'
import {viewAuctionType} from '../../../../../redux/procedure'
import {setAucDocTypes} from '../../../../../redux/docTypes'
import {
    isSUE, isSUD, isSPE, isSPD, isLAW, isLPE
} from '../../../../../redux/procedure'
import {setDataAddress} from '../../../../../redux/createAddress'
import ReturnButton from '../../../returnButton';
import Sidebar from '../sidebar/sidebar'
// 
const AucDocs = (props) => {
    const [addDocPopup, setAddDocPopup] = useState(false)
    const docScopeData = {}

    if (props.data !== null) {
        if (props.data.auctionsMy !== null) {
            let route = 'auction'
            if (props.data.auctionsMy.status === null) route = 'auction-draft'
            docScopeData.aucId = props.data.auctionsMy.id
            docScopeData.aucStatus = props.data.auctionsMy.status
            docScopeData.aucType = props.data.auctionsMy.sellingMethod

                docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/${route}/${props.data.auctionsMy.id}/document`
                docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/${route}/${props.data.auctionsMy.id}/document`
                docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/${route}/${props.data.auctionsMy.id}/document`
                docScopeData.deleteURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/${route}/${props.data.auctionsMy.id}/document`

            docScopeData.historyUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.data.auctionsMy.id}/documents`
            docScopeData.typeOf = 'auctionEdit'
            docScopeData.aucOwner = props.data.auctionsMy.createdByCurrentUser
            docScopeData.aucItems = props.data.auctionsMy.items !== null && props.data.auctionsMy.items.length > 0 ? props.data.auctionsMy.items : null
            docScopeData.reload = props.reload
            docScopeData.setDataadress = setDataAddress
            docScopeData.aucPeriods = props.data.auctionsMy.auctionPeriods
            docScopeData.docList = props.data.auctionsMy.documents
            docScopeData.isPerishable = props.data.auctionsMy.isPerishable
        }
    }

    function getObligateDocs(auction) { //Обов'язкові документи - підказка
        if (auction === null) return null
        if (auction.sellingMethod === null) {
            return null
        }
        let tmpList = setAucDocTypes(auction).filter((i) => {
            if (i.main) return i
        })
        for (let key in tmpList) {
            if (!auction.isPerishable) {
                if (tmpList[key].value === 'clarifications') {
                    tmpList[key].name = `${tmpList[key].name}( додається у разі редагування опису лоту, після опублікування аукціону)`
                }
            }
        }
        if (tmpList.length > 0
            && !isSUE(auction.sellingMethod) && !isSUD(auction.sellingMethod)
            && !isSPE(auction.sellingMethod) && !isSPD(auction.sellingMethod)
            && !isLAW(auction.sellingMethod) && !isLPE(auction.sellingMethod)
        ) {

            return <InstructionBlock
                title='Важлива інформація'
                content={words(auction, tmpList)}
            />
        }
        return null
    }

    function typeWords(data) {
        if (data !== null) return "редагування"
        return "публікації"
    }

    function words(data, tmpList) {
        let tmpEnd = null
        if (data.hasOwnProperty("rectificationPeriod") === true) {
            if (data.rectificationPeriod !== null) {
                if (data.rectificationPeriod.endDate !== null) {
                    tmpEnd = new Date(data.rectificationPeriod.endDate)
                }
            }
        }
        let tmpEnd1 = null
        if (tmpEnd !== null) tmpEnd1 = tmpEnd.getTime()
        if (tmpEnd === null || tmpEnd1 > Date.now()) {
            return (
                <div>Для {typeWords(data.status)} аукціону <span
                    className={styles.interest}>{viewAuctionType(data.sellingMethod)}</span>
                    , обов’язково потрібно завантажити наступні документи: <ul>{filterData(data, tmpList)}</ul>
                    <p> Інші типи документів не обов'язкові</p>
                </div>
            )
        }
        return <div>Період редагування завершився. Можна лише завантажувати нові та оновлювати вже додані
            документи</div>
    }

    function filterData(a, tmpList) {
        let tmp
        if (!a.isPerishable) {
            tmp = tmpList.map((i, count) => <li key={count}>{i.name}</li>)
        } else {
            tmp = tmpList.filter((i) => {
                return i.value !== 'clarifications'
            }).map((i, count) => <li key={count}>{i.name}</li>)
        }
        return tmp
    }

    function getDocContent(a, auctionFull) {
        function dataECPMapper(data, t) {
            let tmp = data
                .filter(
                    (item) => {
                        return item.documentType === 'digitalSignature' && item.relatedDocument === t.documentId
                    })
            if (tmp.length === 0) return null
            return tmp
        }

        let tmpDocs = []
        if (a !== null && a !== undefined && a !== 'undefined') {
            tmpDocs = a
                .filter(
                    (item) => {
                        return item.documentType !== 'digitalSignature'
                    })
                .map((item, count) => {
                    return <AucDocItem
                        auctionFull={auctionFull}
                        data={item}
                        key={count}
                        scopeData={docScopeData}
                        dataECP={dataECPMapper(a, item)}
                    />
                })
            return tmpDocs
        }
        return null
    }

    if (props.data !== null) {
        if (props.data.auctionsMy !== null) {

            let tmpButton = <div className={styles.addDocBtn}
                                 onClick={() => setAddDocPopup(true)}
            >
                Додати документ
            </div>
            if (
                isSPD(props.data.auctionsMy.sellingMethod)
                && props.data.auctionsMy.status !== "active_tendering"
            ) tmpButton = null
            if (
                (isSPE(props.data.auctionsMy.sellingMethod))
                && (props.data.auctionsMy.status === "active_auction"
                    || props.data.auctionsMy.status === "complete"
                    || props.data.auctionsMy.status === "cancelled"
                    || props.data.auctionsMy.status === "unsuccessful")
            ) tmpButton = null
            if (
                (isLPE(props.data.auctionsMy.sellingMethod))
                && (props.data.auctionsMy.status === "active_auction"
                    || props.data.auctionsMy.status === "active_qualification"
                    || props.data.auctionsMy.status === "pending_payment"
                    || props.data.auctionsMy.status === "active_awarded"
                    || props.data.auctionsMy.status === "complete"
                    || props.data.auctionsMy.status === "cancelled"
                    || props.data.auctionsMy.status === "unsuccessful")
            ) tmpButton = null
            return (
                <div className={styles.mainWrap}>
                    {addDocPopup ?
                        <AddDocPopup
                            scopeData={docScopeData}
                            actionClose={() => {
                                setAddDocPopup(false)

                            }}
                        /> : null
                    }
                    <Sidebar/>
                    <div className={styles.infoBlock}>
                        <div className={styles.infoBlockTitle}>
                            <div>Документи та пов'язані додатки</div>
                        </div>
                        {getObligateDocs(props.data.auctionsMy)}

                        {tmpButton}
                        <div className={styles.documentsBlock}>
                            <div className={styles.wrapContent}>
                                {getDocContent(props.data.auctionsMy.documents, props.data.auctionsMy)}
                            </div>
                        </div>
                        <ReturnButton/>
                    </div>
                </div>
            )
        }
    }
    return null
}

export default AucDocs