export async function getUpdateDataAuction(props, idAuction){
    let url
    url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${idAuction}/sync`
    let header
    if(props.token !== null){
        header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        header = {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetch(url, {
        method: "GET",
        headers: header
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        //props.setDataAddress(null, 'SET_FULL_AUCTION')
    }else{
        if(
            response.status !== 200 &&
            response.status !== 301 &&
            response.status !== 302
        ){
            props.setDataAddress(response.status, 'SET_FULL_AUCTION')
        }
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){

        }else{
            props.setDataAddress(json, 'SET_FULL_AUCTION')
        }
    }
}

export async function getUpdateDataBid(props, idBid){
    let header
    if(props.token !== null){
        header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        header = {
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${idBid}`
    const response = await fetch(url, {
        method: "GET",
        headers: header
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json2)})
            //props.setUniversalError(tmp)
        }else{
            props.saveArrBids(json)
        }
    }
}