import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

import { checkProp } from '../../../redux/check';
import {modifiDateString} from '../../../redux/modifiDate'

import {isBRE, isBRD, isBRW,} from '../../../redux/procedure';

import ContactPoints from '../../contactPoint/contactPoint'
import {TranslationsUk} from "../../../redux/translations.uk.js";

const InformationAboutTheOrganizer = (props) => {
    let procedure = props.auctionId.sellingMethodClear.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('') + 'Procedure'
    return (
        <div className={styles.infoBlock}>
            <h3>{TranslationsUk[props?.auctionId?.sellingMethodClear][procedure]?.sellingEntity?.legalName ?? "Інформація про Організатора"}</h3>
            <div className={styles.orgTitle}>
                <p className={styles.desc}>{TranslationsUk[props?.auctionId?.sellingMethodClear][procedure]?.sellingEntity?.name?.legalName ?? "Найменування організатора"}</p>
                <p>{contactName(props)}</p>
            </div>
            <div className={styles.orgContact1}>
                <p className={styles.desc}>{TranslationsUk[props?.auctionId?.sellingMethodClear][procedure]?.sellingEntity?.contactPoint?.legalName ?? "Контактні дані"}</p>
                {contactPoint(props)}
            </div>
            <div className={styles.orgId}>
                <p className={styles.desc}>{TranslationsUk[props?.auctionId?.sellingMethodClear][procedure]?.sellingEntity?.identifier?.legalName ?? "Код ЄДРПОУ або ІПН або паспорт"}</p>
                <p>{contactEDRPOU(props)}</p>
            </div>
            <div className={styles.orgAdress}>
                <p className={styles.desc}>{TranslationsUk[props?.auctionId?.sellingMethodClear][procedure]?.sellingEntity?.address?.legalName ?? "Юридична адреса"}</p>
                <p>{contactAddress(props)}</p>
            </div>
            {contactKOATUU(props)}
            {checkProp(props.auctionId.sellingEntity, 'representativeCertificateNumber')
                ? <div className={styles.orgAdress}>
                    <p className={styles.desc}>№ свідоцтва про право на провадження діяльності арбітражного
                        керуючого</p>
                    <p>{props.auctionId.sellingEntity.representativeCertificateNumber}</p>
                </div>
                : null}
            {checkProp(props.auctionId.sellingEntity, 'representativeCertificateDate')
                ? <div className={styles.orgAdress}>
                    <p className={styles.desc}>Дата видачі про право на провадження діяльності арбітражного
                        керуючого</p>
                    <p>{modifiDateString(props.auctionId.sellingEntity.representativeCertificateDate, 1, false)}</p>
                </div>
                : null}
            {representativeInfo(props)}
        </div>
    )
}

function representativeInfo(props) {
    let tmp = ''
    if (props.auctionId !== null) {
        if (props.auctionId.hasOwnProperty('sellingEntity') === true) {
            if(props.auctionId.sellingEntity !== null){
                if(props.auctionId.sellingEntity.hasOwnProperty('representativeInfo')){
                    if(props.auctionId.sellingEntity.representativeInfo !== null){
                        tmp = <div className={styles.orgTitle}>
                            <p className={styles.desc}>Інформація щодо підтвердження повноважень</p>
                            <p>{props.auctionId.sellingEntity.representativeInfo}</p>
                        </div>
                    }
                }
            }
        }
    }
    return tmp
}

function contactKOATUU(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(
            props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-english") ||
            props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
            props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
        ){
            if(props.auctionId.hasOwnProperty('relatedOrganizations')){
                if(props.auctionId.relatedOrganizations !== null){
                    if(props.auctionId.relatedOrganizations.hasOwnProperty('sellingEntity')){
                        if(props.auctionId.relatedOrganizations.sellingEntity !== null){
                            if(props.auctionId.relatedOrganizations.sellingEntity.hasOwnProperty('address')){
                                if(props.auctionId.relatedOrganizations.sellingEntity.address !== null){
                                    if(props.auctionId.relatedOrganizations.sellingEntity.address.hasOwnProperty('addressID')){
                                        if(props.auctionId.relatedOrganizations.sellingEntity.address.addressID !== null){
                                            if(props.auctionId.relatedOrganizations.sellingEntity.address.addressID.hasOwnProperty('id')){
                                                if(props.auctionId.relatedOrganizations.sellingEntity.address.addressID.id !== null){
                                                    tmp = <div className={styles.orgAdress}>
                                                        <p className={styles.desc}>КОАТУУ</p>
                                                        <p>{props.auctionId.relatedOrganizations.sellingEntity.address.addressID.id}</p>
                                                    </div>
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactName(props){
    let tmp = ''
    let tmpBreBrd = ''
    if(isBRE(props.auctionId.sellingMethod) || isBRD(props.auctionId.sellingMethod) || isBRW(props.auctionId.sellingMethod)) tmpBreBrd = 'Арбітражний керуючий '
    if(props.auctionId !== null){
        if(
            props.auctionId.sellingMethod.startsWith( "legitimatePropertyLease-english") ||
            props.auctionId.sellingMethod.startsWith( "legitimatePropertyLease-dutch") ||
            props.auctionId.sellingMethod.startsWith( "legitimatePropertyLease-priorityEnglish")
        ){
            if(props.auctionId.hasOwnProperty('relatedOrganizations')){
                if(props.auctionId.relatedOrganizations !== null){
                    if(props.auctionId.relatedOrganizations.hasOwnProperty('sellingEntity')){
                        if(props.auctionId.relatedOrganizations.sellingEntity !== null){
                            if(props.auctionId.relatedOrganizations.sellingEntity.hasOwnProperty('name')){
                                if(props.auctionId.relatedOrganizations.sellingEntity.name !== null){
                                    tmp += props.auctionId.relatedOrganizations.sellingEntity.name.uk_UA
                                }
                            }
                        }
                    }
                }
            }
        }else{
            if(props.auctionId.hasOwnProperty('sellingEntity')){
                if(props.auctionId.sellingEntity !== null){
                    if(props.auctionId.sellingEntity.hasOwnProperty('name')){
                        if(props.auctionId.sellingEntity.name !== null){
                            tmp += tmpBreBrd + props.auctionId.sellingEntity.name.uk_UA
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactPoint(props){
    if(props.auctionId !== null){
        if(
            props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-english") ||
            props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
            props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
        ){
            if(props.auctionId.hasOwnProperty('relatedOrganizations')){
                if(props.auctionId.relatedOrganizations !== null){
                    if(props.auctionId.relatedOrganizations.hasOwnProperty('sellingEntity')){
                        if(props.auctionId.relatedOrganizations.sellingEntity !== null){
                            if(props.auctionId.relatedOrganizations.sellingEntity.hasOwnProperty('contactPoint')){
                                if(props.auctionId.relatedOrganizations.sellingEntity.contactPoint !== null){
                                    return contactPoints(props.auctionId.relatedOrganizations.sellingEntity.contactPoint)
                                }
                            }
                        }
                    }
                }
            }
        }else{
            if(props.auctionId.hasOwnProperty('sellingEntity')){
                if(props.auctionId.sellingEntity !== null){
                    if(props.auctionId.sellingEntity.hasOwnProperty('contactPoint')){
                        if(props.auctionId.sellingEntity.contactPoint !== null){
                            return contactPoints(props.auctionId.sellingEntity.contactPoint)
                        }
                    }
                }
            }
        }
    }
    return null
}

function contactPoints(e){
    if(e !== null){
        return <ContactPoints data={e} />
    }
    return null
}

function contactEDRPOU(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(
            props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-english") ||
            props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
            props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
        ){
            if(props.auctionId.hasOwnProperty('relatedOrganizations')){
                if(props.auctionId.relatedOrganizations !== null){
                    if(props.auctionId.relatedOrganizations.hasOwnProperty('sellingEntity')){
                        if(props.auctionId.relatedOrganizations.sellingEntity !== null){
                            if(props.auctionId.relatedOrganizations.sellingEntity.hasOwnProperty('identifier')){
                                if(props.auctionId.relatedOrganizations.sellingEntity.identifier !== null){
                                    if(props.auctionId.relatedOrganizations.sellingEntity.hasOwnProperty('identifier')){
                                        if(props.auctionId.relatedOrganizations.sellingEntity.identifier.id !== null){
                                            tmp += props.auctionId.relatedOrganizations.sellingEntity.identifier.id
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }else{
            if(props.auctionId.hasOwnProperty('sellingEntity')){
                if(props.auctionId.sellingEntity !== null){
                    if(props.auctionId.sellingEntity.hasOwnProperty('identifier')){
                        if(props.auctionId.sellingEntity.identifier !== null){
                            if(props.auctionId.sellingEntity.hasOwnProperty('identifier')){
                                if(props.auctionId.sellingEntity.identifier.id !== null){
                                    tmp += props.auctionId.sellingEntity.identifier.id
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactAddress(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(
            props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-english") ||
            props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
            props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
        ){
            if(props.auctionId.hasOwnProperty('relatedOrganizations')){
                if(props.auctionId.relatedOrganizations !== null){
                    if(props.auctionId.relatedOrganizations.hasOwnProperty('sellingEntity')){
                        if(props.auctionId.relatedOrganizations.sellingEntity !== null){
                            if(props.auctionId.relatedOrganizations.sellingEntity.hasOwnProperty('address')){
                                if(props.auctionId.relatedOrganizations.sellingEntity.address !== null){
                                    if(props.auctionId.relatedOrganizations.sellingEntity.address.hasOwnProperty('postalCode')){
                                        if(props.auctionId.relatedOrganizations.sellingEntity.address.postalCode !== null){
                                            tmp += props.auctionId.relatedOrganizations.sellingEntity.address.postalCode + ', '
                                        }
                                    }
                                    if(props.auctionId.relatedOrganizations.sellingEntity.address.hasOwnProperty('countryName')){
                                        if(props.auctionId.relatedOrganizations.sellingEntity.address.countryName !== null){
                                            tmp += props.auctionId.relatedOrganizations.sellingEntity.address.countryName.uk_UA + ', '
                                        }
                                    }
                                    if(props.auctionId.relatedOrganizations.sellingEntity.address.hasOwnProperty('region')){
                                        if(props.auctionId.relatedOrganizations.sellingEntity.address.region !== null){
                                            tmp += props.auctionId.relatedOrganizations.sellingEntity.address.region.uk_UA + ', '
                                        }
                                    }
                                    if(props.auctionId.relatedOrganizations.sellingEntity.address.hasOwnProperty('locality')){
                                        if(props.auctionId.relatedOrganizations.sellingEntity.address.locality !== null){
                                            tmp += props.auctionId.relatedOrganizations.sellingEntity.address.locality.uk_UA + ', '
                                        }
                                    }
                                    if(props.auctionId.relatedOrganizations.sellingEntity.address.hasOwnProperty('streetAddress')){
                                        if(props.auctionId.relatedOrganizations.sellingEntity.address.streetAddress !== null){
                                            tmp += props.auctionId.relatedOrganizations.sellingEntity.address.streetAddress.uk_UA
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }else{
            if(props.auctionId.hasOwnProperty('sellingEntity')){
                if(props.auctionId.sellingEntity !== null){
                    if(props.auctionId.sellingEntity.hasOwnProperty('address')){
                        if(props.auctionId.sellingEntity.address !== null){
                            if(props.auctionId.sellingEntity.address.hasOwnProperty('locality')){
                                if(props.auctionId.sellingEntity.address.locality !== null){
                                    tmp += props.auctionId.sellingEntity.address.locality.uk_UA + ', '
                                }
                            }
                            if(props.auctionId.sellingEntity.address.hasOwnProperty('streetAddress')){
                                if(props.auctionId.sellingEntity.address.streetAddress !== null){
                                    tmp += props.auctionId.sellingEntity.address.streetAddress.uk_UA + ', '
                                }
                            }
                            if(props.auctionId.sellingEntity.address.hasOwnProperty('region')){
                                if(props.auctionId.sellingEntity.address.region !== null){
                                    tmp += props.auctionId.sellingEntity.address.region.uk_UA + ', '
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(InformationAboutTheOrganizer)
