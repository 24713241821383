import { 
    //Auctions
    SET_DGF_MASK,
    AUCTIONS_GET,
    AUCTIONS_GET_FLAG,
    AUCTIONS_GET_ID,
    AUCTIONS_SEARCH,
    AUCTIONS_SEARCH_MIN,
    AUCTIONS_SEARCH_COUNT,
    AUCTIONS_GET_MY,
    AUCTIONS_SWITCH_WINDOW,
    AUCTIONS_ITEMS_SWITCH_WINDOW,
    AUCTIONS_CURENT_PAGE,
    AUCTIONS_STATUS,
    CHANGE_MEASURES,
    SET_PRICE_FROM,
    SET_PRICE_FROM_BAG,
    SET_PRICE_TO,
    SET_PRICE_TO_BAG,
    SET_SEARCH_TEXT_STRING,
    SET_SEARCH_TYPE_KEYWORD,
    SET_SEARCH_DATE_START,
    SET_SEARCH_DATE_END,
    SET_SEARCH_SORT,
    SET_CLASSIFICATIONS_BY_TYPE,
    SET_CLASSIFICATIONS_BY_ENERGY_GENERATION_TYPE,
    SET_CLASSIFICATIONS_BY_TIMBER_SORTMENT,
    SET_CLASSIFICATIONS_BY_TIMBER_SPECIES,
    SET_CLASSIFICATIONS_BY_TIMBER_CLASS,
    SET_CLASSIFICATIONS_BY_TIMBER_DIAMETER,
    SET_CLASSIFICATIONS_BY_TIMBER_LENGTH,
    SET_CLASSIFICATIONS_BY_TIMBER_STORAGE,
    SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR,
    SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_SORTMENT,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_SPECIES,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_CLASS,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_DIAMETER,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH1,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH2,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_STORAGE,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER,
    CHANGE_UNIT_CODE,
    CHANGE_CLASSIFICATIONS_BY_GENERATION_TYPE,
    SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD,

    SET_POP_UP_SEND_AUCTION,
    SET_POP_UP_DEL_AUCTION,
    AUCTIONS_GET_MY_ID,

    SET_CLASSIFICATIONS_BY_TYPE_RAILWAY,
    SET_CLASSIFICATIONS_BY_WAGON_SPECIES,

    SET_CLASSIFICATIONS_BY_TYPE_NADRA_ENGLISH,
    SET_CLASSIFICATIONS_BY_TYPE_SUBSOIL_USAGE_TYPE,

    SET_POP_UP_ERROR_AUCTION,
    SET_POP_UP_ANSWER_AUCTION,

    CHANGE_POP_UP_AUCTION_CREATE,
    SET_CLASSIFICATIONS_BY_CARRY_TYPE,
    SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_CARGO_ADD,
    SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT,
    SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT_TYPE,

    SET_CREATE_RESTRICTION_FLAG,
	SET_CREATE_RESTRICTION_LOAD_OBJECT,
	SET_CREATE_RESTRICTION_LOAD_OBJECT_CODE,
	SET_CREATE_RESTRICTION_LOAD_OBJECT_NAME,
	SET_CREATE_RESTRICTION_UNLOAD_OBJECT,
	SET_CREATE_RESTRICTION_UNLOAD_OBJECT_CODE,
    SET_CREATE_RESTRICTION_UNLOAD_OBJECT_NAME,
    
    SET_CREATE_RESTRICTION_EXCLUSIONS_FLAF,
	SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT,
	SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_TYPE,
	SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_CODE,
    SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_NAME,
    
    SET_TIMBER_SORTMENT,
    SET_TIMBER_SPECIES,
    SET_TIMBER_CLASS,
    SET_TIMBER_DIAMETER,
    SET_TIMBER_STORAGE,
    SET_TIMBER_PRODUCTION_YEAR,
    SET_TIMBER_PRODUCTION_QUARTER,
    SET_POPUP_BID_TOGETHER_API,
    SET_TIMBER_LENGTH1,
    SET_TIMBER_LENGTH2,
    AUCTIONS_SEARCH_ELEM_ARR,

} from "../../types";
import {getAPI} from '../crud';

//Auctions

export function auctionGetId(_url){
    const data = {
        url: `${_url.domen}/api/v1/uk/auction/${_url.id}`,
        actionType: AUCTIONS_GET_ID,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function auctionGetIdToken(_url, token){
    const data = {
        url: `${_url.domen}/api/v1/uk/auction/${_url.id}`,
        actionType: AUCTIONS_GET_ID,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function auctionsGet(_url, header){
    const data = {
        //url: `${_url.domen}/api/v1/uk/auctions${_url.params}`,
        url: `${_url.domen}/api/v1/uk/public/auctions/search${_url.params}`,
        actionType: AUCTIONS_GET,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetMy(_url, header) {
    const data = {
        //url: `${_url.domen}/api/v1/uk/private/auctions/my${_url.params}`,
        url: `${_url.domen}/api/v2/uk/private/auction-drafts/my${_url.params}`,
        actionType: AUCTIONS_GET_MY,
        header: header
    }
    return getAPI(data);
}

export function auctionsSearch(_url, header, data)
{
    let data1 = {}
    if(data.keyword !== null && data.keyword !== '') data1.keyword = data.keyword
    if(data.authorName !== null && data.authorName !== '') data1.authorName = data.authorName
    if(data.registryId !== null) data1.registryId = data.registryId
    //
    if(data.auctionId !== null && data.auctionId !== '') data1.auctionId = data.auctionId
    if(data.category !== null){
        switch (true) {
            case (data.category === 'largePrivatization-all'):
                data1.sellingMethod = ['largePrivatization-english']
                break

            case (data.category === 'arrestedAssets'):
                data1.sellingMethod = 'arrestedAssets-english'
                break

            case (data.category === 'alienation'):
            case (data.category === 'alienation-english'):
                data1.sellingMethod = ['alienation-english']
                if (data.itemType !== null && data.itemType !== 'null' && data.itemType !== '') data1.itemPropsType = data.itemType
                break

            case (data.category === 'subsoil-all'):
                data1.sellingMethod = ['subsoil-english', 'subsoil-dutch']
                break

            case (data.category === 'railwayCargo-all'):
                data1.sellingMethod = ['railwayCargo-english', 'railwayCargo-dutch']
                break;

            case (data.category === 'basicSell-all'):
                data1.sellingMethod = ['basicSell-english', 'basicSell-dutch']
                if (data.itemType !== null && data.itemType !== 'null' && data.itemType !== '') data1.itemPropsType = data.itemType
                if (data.isPerishable !== null && data.isPerishable !== 'null' && data.isPerishable !== '') {
                    if (data.isPerishable === 'true') {
                        data1.isPerishable = true
                    } else if (data.isPerishable === 'false') {
                        data1.isPerishable = false
                    }
                }
                break

            case (data.category === 'basicSell-english'):
            case (data.category === 'basicSell-dutch'):
                data1.sellingMethod = data.category
                if (data.itemType !== null && data.itemType !== 'null' && data.itemType !== '') data1.itemPropsType = data.itemType
                if (data.isPerishable !== null && data.isPerishable !== 'null' && data.isPerishable !== '') {
                    if (data.isPerishable === 'true') {
                        data1.isPerishable = true
                    } else if (data.isPerishable === 'false') {
                        data1.isPerishable = false
                    }
                }
                break

            case (data.category === 'commercialSell-all'):
                data1.sellingMethod = ['commercialSell-english', 'commercialSell-dutch']
                break

            case (data.category === 'bankRuptcy-all'):
                data1.sellingMethod = ['bankRuptcy-english', 'bankRuptcy-dutch', 'bankRuptcy-withoutAuction']
                break

            case (data.category === 'regulationsPropertyLease-all'):
                data1.sellingMethod = ['regulationsPropertyLease-english', 'regulationsPropertyLease-dutch']
                break

            case (data.category === 'commercialPropertyLease-all'):
                data1.sellingMethod = ['commercialPropertyLease-english', 'commercialPropertyLease-dutch']
                break

            case (data.category === 'dgf'):
                data1.sellingMethod = ['dgf-english', 'dgf-dutch']
                break

            case (data.category === 'landRental'):
            case (data.category === 'landRental-english'):
                data1.sellingMethod = ['landRental-english']
                if (data.additionalClassifications === undefined || data.additionalClassifications === 'undefined') {
                } else {
                    if (data.additionalClassifications !== null && data.additionalClassifications !== 'null') {
                        data1.additionalClassifications = [data.additionalClassifications]
                    }
                }
                if (data.cadastralNumber !== null && data.cadastralNumber !== '') data1.cadastralNumber = data.cadastralNumber
                break

            case (data.category === 'landSell'):
                data1.sellingMethod = ['landSell-english', 'landSell-priorityEnglish']
                if (data.additionalClassifications === undefined || data.additionalClassifications === 'undefined') {
                } else {
                    if (data.additionalClassifications !== null && data.additionalClassifications !== 'null') {
                        data1.additionalClassifications = [data.additionalClassifications]
                    }
                }
                if (data.cadastralNumber !== null && data.cadastralNumber !== '') data1.cadastralNumber = data.cadastralNumber
                break

            case (data.category === 'landSell-english'):
            case (data.category === 'landSell-priorityEnglish'):
                data1.sellingMethod = data.category
                if (data.additionalClassifications === undefined || data.additionalClassifications === 'undefined') {
                } else {
                    if (data.additionalClassifications !== null && data.additionalClassifications !== 'null') {
                        data1.additionalClassifications = [data.additionalClassifications]
                    }
                }
                if (data.cadastralNumber !== null && data.cadastralNumber !== '') data1.cadastralNumber = data.cadastralNumber
                break

            case (data.category === 'legitimatePropertyLease'):
                data1.sellingMethod = ['legitimatePropertyLease-english', 'legitimatePropertyLease-dutch', 'legitimatePropertyLease-priorityEnglish']
                if (data.additionalClassifications === undefined || data.additionalClassifications === 'undefined') {
                } else {
                    if (data.additionalClassifications !== null && data.additionalClassifications !== 'null') {
                        data1.additionalClassifications = [data.additionalClassifications]
                    }
                }
                if (data.leaseDurationFrom > 0) data1.leaseDurationFrom = data.leaseDurationFrom
                if (data.leaseDurationTo > 0) data1.leaseDurationTo = data.leaseDurationTo
                break

            case (data.category === 'legitimatePropertyLease-english'):
            case (data.category === 'legitimatePropertyLease-dutch'):
            case (data.category === 'legitimatePropertyLease-priorityEnglish'):
                data1.sellingMethod = data.category
                if (data.additionalClassifications === undefined || data.additionalClassifications === 'undefined') {
                } else {
                    if (data.additionalClassifications !== null && data.additionalClassifications !== 'null') {
                        data1.additionalClassifications = [data.additionalClassifications]
                    }
                }
                if (data.leaseDurationFrom > 0) data1.leaseDurationFrom = data.leaseDurationFrom
                if (data.leaseDurationTo > 0) data1.leaseDurationTo = data.leaseDurationTo
                break

            case (data.category === 'smallPrivatization-all'):
                data1.sellingMethod = ['smallPrivatization-english', 'smallPrivatization-dutch']
                break

            case (data.category === 'nonperformingLoans-all'):
                data1.sellingMethod = ['nonperformingLoans-english', 'nonperformingLoans-dutch']
                if (data.itemType !== null && data.itemType !== 'null' && data.itemType !== '') data1.itemPropsType = data.itemType
                if (data.isPerishable !== null && data.isPerishable !== 'null' && data.isPerishable !== '') {
                    if (data.isPerishable === 'true') {
                        data1.isPerishable = true
                    } else if (data.isPerishable === 'false') {
                        data1.isPerishable = false
                    }
                }
                break

            case (data.category === 'nonperformingLoans-english'):
            case (data.category === 'nonperformingLoans-dutch'):
                data1.sellingMethod = data.category
                if (data.itemType !== null && data.itemType !== 'null' && data.itemType !== '') data1.itemPropsType = data.itemType
                if (data.isPerishable !== null && data.isPerishable !== 'null' && data.isPerishable !== '') {
                    if (data.isPerishable === 'true') {
                        data1.isPerishable = true
                    } else if (data.isPerishable === 'false') {
                        data1.isPerishable = false
                    }
                }
                break

            case (data.category === 'landArrested'):
                data1.sellingMethod = ['landArrested-english', 'landArrested-withoutAuction', 'landArrested-priorityEnglish']
                if (data.additionalClassifications === undefined || data.additionalClassifications === 'undefined') {
                } else {
                    if (data.additionalClassifications !== null && data.additionalClassifications !== 'null') {
                        data1.additionalClassifications = [data.additionalClassifications]
                    }
                }
                if (data.cadastralNumber !== null && data.cadastralNumber !== '') data1.cadastralNumber = data.cadastralNumber
                if (data.landAreaTo !== null && data.landAreaTo !== '') data1.landAreaTo = data.landAreaTo
                if (data.landAreaFrom !== null && data.landAreaFrom !== '') data1.landAreaFrom = data.landAreaFrom
                break

            case (data.category === 'landArrested-english'):
            case (data.category === 'landArrested-withoutAuction'):
                data1.sellingMethod = data.category
                if (data.additionalClassifications === undefined || data.additionalClassifications === 'undefined') {
                } else {
                    if (data.additionalClassifications !== null && data.additionalClassifications !== 'null') {
                        data1.additionalClassifications = [data.additionalClassifications]
                    }
                }
                if (data.cadastralNumber !== null && data.cadastralNumber !== '') data1.cadastralNumber = data.cadastralNumber
                if (data.landAreaTo !== null && data.landAreaTo !== '') data1.landAreaTo = data.landAreaTo
                if (data.landAreaFrom !== null && data.landAreaFrom !== '') data1.landAreaFrom = data.landAreaFrom
                break

            case (data.category === 'landArrested-priorityEnglish'):
                data1.sellingMethod = ['landArrested-priorityEnglish']
                if (data.additionalClassifications === undefined || data.additionalClassifications === 'undefined') {
                } else {
                    if (data.additionalClassifications !== null && data.additionalClassifications !== 'null') {
                        data1.additionalClassifications = [data.additionalClassifications]
                    }
                }
                if (data.classification === undefined || data.classification === 'undefined') {
                } else {
                    if (data.classification !== null && data.classification !== 'null') {
                        data1.classification = data.classification
                    }
                }
                if (data.cadastralNumber !== null && data.cadastralNumber !== '') data1.cadastralNumber = data.cadastralNumber
                if (data.landAreaTo !== null && data.landAreaTo !== '') data1.landAreaTo = data.landAreaTo
                if (data.landAreaFrom !== null && data.landAreaFrom !== '') data1.landAreaFrom = data.landAreaFrom
                break

            case (data.category === 'armaProperty-all'):
                data1.sellingMethod = ['armaProperty-english', 'armaProperty-dutch']
                break

            case (data.category === 'legacy'):
            case (data.isLegacy):
                data.isLegacy = data1.isLegacy = true
                data.category = data1.category = 'legacy'
                delete data.status
                delete data1.status
                break


            default:
                data1.sellingMethod = data.category
                break;
        }
    }

    if(data.status !== null) data1.status = data.status
    if(data.startDateFrom !== null) data1.startDateFrom = data.startDateFrom
    if(data.startDateTo !== null) data1.startDateTo = data.startDateTo
    
    if(data.areaFrom !== null) data1.areaFrom = data.areaFrom
    if(data.areaTo !== null) data1.areaTo = data.areaTo
    if(data.landAreaFrom !== null) data1.landAreaFrom = data.landAreaFrom
    if(data.landAreaTo !== null) data1.landAreaTo = data.landAreaTo
    
    if(data.region !== null && data.region !== "null") data1.region = data.region
    if(data.locality !== null && data.locality !== "null") data1.locality = data.locality

    if(data.priceTo !== null && data.region !== "null") data1.priceTo = data.priceTo
    if(data.priceFrom!== null && data.priceFrom !== "null") data1.priceFrom = data.priceFrom

    if(data.classification === undefined || data.classification === 'undefined'){
    }else{
        if(data.classification !== null && data.classification !== 'null'){
            data1.classification = data.classification
        }else{
            if(data.classificationMain !== null && data.classificationMain !== 'null'){
                switch(data.classificationMain){
                    case '04':
                        data1.classification = '04000000-8'
                        break
                    case '05':
                        data1.classification = '05000000-5'
                        break
                    case '06':
                        data1.classification = '06000000-2'
                        break
                    case '16':
                        data1.classification = '16000000-5'
                        break
                    case '30':
                        data1.classification = '30000000-9'
                        break
                    case '32':
                        data1.classification = '32000000-3'
                        break
                    case '34':
                        data1.classification = '34000000-7'
                        break
                    case '39':
                        data1.classification = '39000000-2'
                        break
                    case '42':
                        data1.classification = '42000000-6'
                        break
                    case '07':
                        data1.classification = '07000000-9'
                        break
                    case '08':
                        data1.classification = '08000000-6'
                        break
                    case '45':
                        data1.classification = '45000000-7'
                        break
                    case '48':
                        data1.classification = '48000000-8'
                        break
                    case '50':
                        data1.classification = '50000000-5'
                        break
                    case '51':
                        data1.classification = '51000000-9'
                        break
                    case '55':
                        data1.classification = '55000000-0'
                        break
                    case '60':
                        data1.classification = '60000000-8'
                        break
                    case '63':
                        data1.classification = '63000000-9'
                        break
                    case '64':
                        data1.classification = '64000000-6'
                        break
                    case '65':
                        data1.classification = '65000000-3'
                        break
                    case '66':
                        data1.classification = '66000000-0'
                        break
                    case '71':
                        data1.classification = '71000000-8'
                        break
                    case '72':
                        data1.classification = '72000000-5'
                        break
                    case '73':
                        data1.classification = '73000000-2'
                        break
                    case '75':
                        data1.classification = '75000000-6'
                        break
                    case '76':
                        data1.classification = '76000000-3'
                        break
                    case '77':
                        data1.classification = '77000000-0'
                        break
                    case '79':
                        data1.classification = '79000000-4'
                        break
                    case '80':
                        data1.classification = '80000000-4'
                        break
                    case '85':
                        data1.classification = '85000000-9'
                        break
                    case '90':
                        data1.classification = '90000000-7'
                        break
                    case '92':
                        data1.classification = '05000000-5'
                        break
                    case '98':
                        data1.classification = '98000000-3'
                        break
                    default:
                        break
                }
            }
        }
    }

    if(data.numberOfCurrentTenants !== null) data1.numberOfCurrentTenants = data.numberOfCurrentTenants

    return async dispatch => {
            const response = await fetch(`${_url.domen}/api/v1/uk/public/auctions/search${_url.params}`, {
                method: 'POST',
                body: JSON.stringify(data1),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

        const json = await response.json()
        dispatch({type: AUCTIONS_SEARCH, payload: json})
    }
}

export function auctionsSearchTogetherAPI(_url, header, data){
    let data1 = {}
    if(data.keyword !== null) data1.keyword = data.keyword
    if(data.authorName !== null && data.authorName !== '') data1.authorName = data.authorName
    if(data.classification !== null) data1.classification = data.classification
    if(data.auctionId !== null) data1.auctionId = data.auctionId
    if(data.category !== null){
        if(data.category === 'timber-english'){
            data1.sellingMethod = ['timber-english']
        }else if(data.category === 'subsoil-english'){
            data1.sellingMethod = ['subsoil-english', 'subsoil-dutch']
        }else if(data.category === 'railwayCargo-english'){
            data1.sellingMethod = ['railwayCargo-english', 'railwayCargo-dutch']
        }
    }
    if(data.startDateFrom !== null) data1.startDateFrom = data.startDateFrom
    if(data.startDateTo !== null) data1.startDateTo = data.startDateTo
    if(data.additionalClassifications !== null) data1.additionalClassifications = data.additionalClassifications
    if(data.lengthFrom !== null) data1.lengthFrom = data.lengthFrom
    if(data.lengthTo !== null) data1.lengthTo = data.lengthTo
    if (data.category !== 'legacy') {
        data1.status = ['active_tendering']
    } else {
        data1.isLegacy = true
    }
    return async dispatch => {
            const response = await fetch(`${_url.domen}/api/v1/uk/public/auctions/search${_url.params}`, {
                method: 'POST',
                body: JSON.stringify(data1),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

        const json = await response.json()
        dispatch({type: AUCTIONS_SEARCH, payload: json})
    }
}


export function auctionsSearchMin(value){
    return {
        type: AUCTIONS_SEARCH_MIN,
        payload: value
    }
}

export function auctionsSearchCount(_url, header, data){
    let data1 = {}
    if(data.order !== null) data1.order = data.order
    if(data.orderBy !== null) data1.orderBy = data.orderBy
    if(data.status !== null) data1.status = [data.status]
    if(data.region !== null) data1.region = data.region
    if(data.isLegacy !== null) data1.isLegacy = data.isLegacy
    if(data.category !== null){
        if(data.category === 'timber-english'){
            data1.sellingMethod = ['timber-english']
        }else if(data.category === 'subsoil-english'){
            data1.sellingMethod = ['subsoil-english', 'subsoil-dutch']
        }else if(data.category === 'railwayCargo-english'){
            data1.sellingMethod = ['railwayCargo-english', 'railwayCargo-dutch']
        }
    }
    if(data.priceCurrency !== null) data1.priceCurrency = data.priceCurrency
    if(data.priceFrom === undefined || data.priceFrom === 'undefined'){
    }else{
        if(data.priceFrom !== null) data1.priceFrom = Number(data.priceFrom)
    }
    if(data.priceTo === undefined || data.priceTo === 'undefined'){
    }else{
        if(data.priceTo !== null) data1.priceTo = Number(data.priceTo)
    }
    if(data.measures !== null) data1.measures = data.measures
    if(data.measuresFrom !== null) data1.measuresFrom = data.measuresFrom
    if(data.measuresTo !== null) data1.measuresTo = data.measuresTo
    return async dispatch => {
            const response = await fetch(`${_url.domen}/api/v1/uk/public/auctions/search?limit=10&offset=0&onlyCount=1`, {
                method: 'POST',
                body: JSON.stringify(data1),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

        const json = await response.json()
        dispatch({type: AUCTIONS_SEARCH_COUNT, payload: json})
    }
}

export function changeLotsFlag(value) {
    return {
        type: AUCTIONS_GET_FLAG,
        payload: value
    }
}

export function changeAuctionsSwitchWindow(value) {
    return {
        type: AUCTIONS_SWITCH_WINDOW,
        payload: value
    }
}

export function changeAuctionsItemsSwitchWindow(value) {
    return {
        type: AUCTIONS_ITEMS_SWITCH_WINDOW,
        payload: value
    }
}

export function changeCurentPage(value) {
    return {
        type: AUCTIONS_CURENT_PAGE,
        payload: value
    }
}

export function changeStatusAuction(value) {
    return {
        type: AUCTIONS_STATUS,
        payload: value
    }
}

export function changeEdVum(value) {
    return {
        type: CHANGE_MEASURES,
        payload: value
    }
}

export function praiceFrom(value) {
    return {
        type: SET_PRICE_FROM,
        payload: value
    }
}

export function praiceFromBag(value) {
    return {
        type: SET_PRICE_FROM_BAG,
        payload: value
    }
}

export function praiceTo(value) {
    return {
        type: SET_PRICE_TO,
        payload: value
    }
}

export function praiceToBag(value) {
    return {
        type: SET_PRICE_TO_BAG,
        payload: value
    }
}

export function setSearchTextSting(value) {
    return {
        type: SET_SEARCH_TEXT_STRING,
        payload: value
    }
}

export function setSearchSelectTypeKeyword(value) {
    return {
        type: SET_SEARCH_TYPE_KEYWORD,
        payload: value
    }
}

export function changeSearchDateStart(value) {
    return {
        type: SET_SEARCH_DATE_START,
        payload: value
    }
}

export function changeSearchDateEnd(value) {
    return {
        type: SET_SEARCH_DATE_END,
        payload: value
    }
}

export function setSearchSort(value) {
    return {
        type: SET_SEARCH_SORT,
        payload: value
    }
}

export function auctionsGetClassificationsByType(_url, header) {
    const data = {
        url: `${_url.domen}/api/v1/uk/classifications/byType/timber-english?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetClassificationsByGenerationType(_url, header) {
    const data = {
        url: `${_url.domen}/api/v1/uk/classifications/byScheme/generationType?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_ENERGY_GENERATION_TYPE,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetClassificationsByTimberSortment(_url, header) {
    const data = {
        url: `${_url.domen}/api/v1/uk/public/dictionaries/timberSortment?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_SORTMENT,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberSortmentFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_SORTMENT,
        payload: value
    }
}

export function auctionsGetClassificationsByTimberSpecies(_url, header) {
    const data = {
        url: `${_url.domen}/api/v1/uk/public/dictionaries/timberSpecies?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_SPECIES,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberSpeciesFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_SPECIES,
        payload: value
    }
}

export function auctionsGetClassificationsByTimberClass(_url, header) {
    const data = {
        //url: `${_url.domen}/api/v1/uk/classifications/byScheme/timberClass?limit=100&offset=0`,
        url: `${_url.domen}/api/v1/uk/public/dictionaries/timberClass?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_CLASS,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberClassFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_CLASS,
        payload: value
    }
}

export function auctionsGetClassificationsByTimberDiameter(_url, header) {
    const data = {
        //url: `${_url.domen}/api/v1/uk/classifications/byScheme/timberDiameter?limit=100&offset=0`,
        url: `${_url.domen}/api/v1/uk/public/dictionaries/timberDiameter?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_DIAMETER,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberDiameterFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_DIAMETER,
        payload: value
    }
}

export function auctionsGetClassificationsByTimberLength(_url, header) {
    const data = {
        //url: `${_url.domen}/api/v1/uk/classifications/byScheme/timberLength?limit=100&offset=0`,
        url: `${_url.domen}/api/v1/uk/public/dictionaries/timberLength?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_LENGTH,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberLengthFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_LENGTH,
        payload: value
    }
}

export function auctionsGetClassificationsByTimberStorage(_url, header) {
    const data = {
        //url: `${_url.domen}/api/v1/uk/classifications/byScheme/timberStorage?limit=100&offset=0`,
        url: `${_url.domen}/api/v1/uk/public/dictionaries/timberStorage?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_STORAGE,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberStorageFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_STORAGE,
        payload: value
    }
}

export function auctionsGetClassificationsByTimberProductionYear(_url, header) {
    const data = {
        url: `${_url.domen}/api/v1/uk/classifications/byScheme/timberProductionYear?limit=100&offset=0&orderBy=classificationId`,
        //url: `${_url.domen}/api/v1/uk/dictionaries/timberProductionYear?limit=100&offset=0&orderBy=classificationId`,
        //url: `${_url.domen}/api/v1/uk/dictionaries/timberProductionYear?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberProductionYearFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR,
        payload: value
    }
}

export function auctionsGetClassificationsByTimberProductionQuarter(_url, header) {
    const data = {
        url: `${_url.domen}/api/v1/uk/classifications/byScheme/timberProductionQuarter?limit=100&offset=0&orderBy=classificationId`,
        //url: `${_url.domen}/api/v1/uk/dictionaries/timberProductionQuarter?limit=100&offset=0&orderBy=classificationId`,
        //url: `${_url.domen}/api/v1/uk/dictionaries/timberProductionQuarter?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTimberProductionQuarterFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER,
        payload: value
    }
}

export function changeTimberSortment(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_TIMBER_SORTMENT,
        payload: value
    }
}

export function changeTimberSpecies(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_TIMBER_SPECIES,
        payload: value
    }
}

export function changeTimberClass(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_TIMBER_CLASS,
        payload: value
    }
}

export function changeTimberDiameter(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_TIMBER_DIAMETER,
        payload: value
    }
}

export function changeTimberLength(value, type) {
    if(type === 1){
        return {
            type: CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH1,
            payload: value
        }
    }else{
        return {
            type: CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH2,
            payload: value
        }
    }
}

export function changeTimberStorage(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_TIMBER_STORAGE,
        payload: value
    }
}

export function changeTimberProductionYear(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR,
        payload: value
    }
}

export function changeTimberProductionQuarter(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER,
        payload: value
    }
}

export function changeCreateItemUnitCode(value) {
    return {
        type: CHANGE_UNIT_CODE,
        payload: value
    }
}

export function changeCreateItemGenerationType(value) {
    return {
        type: CHANGE_CLASSIFICATIONS_BY_GENERATION_TYPE,
        payload: value
    }
}

export function setPopUpSendAuction(value) {
    return {
        type: SET_POP_UP_SEND_AUCTION,
        payload: value
    }
}

export function setPopUpDelAuction(value) {
    return {
        type: SET_POP_UP_DEL_AUCTION,
        payload: value
    }
}

export function auctionsGetMyId(_url, header, id) {
    const data = {
        url: `${_url.domen}/api/v1/uk/auction/${id}`,
        actionType: AUCTIONS_GET_MY_ID,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetClassificationsByTypeRailway(_url, header) {
    const data = {
        url: `${_url.domen}/api/v1/uk/classifications/byType/railwayCargo-english?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE_RAILWAY,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetClassificationsByWagonSpecies(_url, header) {
    const data = {
        //url: `${_url.domen}/api/v1/uk/classifications/byScheme/wagonSpecies?limit=100&offset=0`,
        url: `${_url.domen}/api/v1/uk/public/dictionaries/wagonSpecies?limit=100&offset=0`,

        actionType: SET_CLASSIFICATIONS_BY_WAGON_SPECIES,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByWagonSpeciesFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_WAGON_SPECIES,
        payload: value
    }
}

export function auctionsGetClassificationsByTypeNadraEnglish(_url, header) {
    const data = {
        url: `${_url.domen}/api/v1/uk/classifications/byType/subsoil-english?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE_NADRA_ENGLISH,
        header: header
    }
    return getAPI(data);
}
export function auctionsGetClassificationsByTypeNadraEnglishFromCashe(value) {
    return {
        type: SET_CLASSIFICATIONS_BY_TYPE_NADRA_ENGLISH,
        payload: value
    }
}

export function auctionsGetClassificationsByByShemaSubsoilUsageType(_url, header) {
    const data = {
        //url: `${_url.domen}/api/v1/uk/classifications/byScheme/subsoil-english?limit=100&offset=0`,
        url: `${_url.domen}/api/v1/uk/classifications/byScheme/subsoilUsageType?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE_SUBSOIL_USAGE_TYPE,
        header: header
    }
    return getAPI(data);
}

export function setPopUpErrorAuction(value) {
    return {
        type: SET_POP_UP_ERROR_AUCTION,
        payload: value
    }
}

export function setPopUpAnswerAuction(value) {
    return {
        type: SET_POP_UP_ANSWER_AUCTION,
        payload: value
    }
}

export function setPopUpAuctionCreate(value) {
    return {
        type: CHANGE_POP_UP_AUCTION_CREATE,
        payload: value
    }
}

export function setPopUpAuctionMyNull() {
    return {
        type: AUCTIONS_GET_MY,
        payload: null
    }
}

export function auctionsGetClassificationsByCarryType(_url, header) {
    const data = {
        //url: `${_url.domen}/api/v1/uk/classifications/byScheme/carryType?limit=100&offset=0`,
        url: `${_url.domen}/api/v1/uk/public/dictionaries/carryType?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_CARRY_TYPE,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetClassificationsByTypeRailwayCargoAdd(_url, header) {
    const data = {
        url: `${_url.domen}/api/v1/uk/classifications/byType/railwayCargoAdditional?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_CARGO_ADD,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetWagonRestrict(_url, header) {
    const data = {
        url: `${_url.domen}/api/v1/uk/classifications/byScheme/objectLoadUnloadOrExclusionRange?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetWagonRestrictType(_url, header) {
    const data = {
        url: `${_url.domen}/api/v1/uk/classifications/byScheme/railwayCargoExclusionObjectType?limit=100&offset=0`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT_TYPE,
        header: header
    }
    return getAPI(data);
}



/////////////////////////////////
export function setCreateRestrictionFlag(value) {
    return {
        type: SET_CREATE_RESTRICTION_FLAG,
        payload: value
    }
}

export function setCreateLoadObject(value) {
    return {
        type: SET_CREATE_RESTRICTION_LOAD_OBJECT,
        payload: value
    }
}

export function setCreateLoadObjectCode(value) {
    return {
        type: SET_CREATE_RESTRICTION_LOAD_OBJECT_CODE,
        payload: value
    }
}

export function setCreateLoadObjectName(value) {
    return {
        type: SET_CREATE_RESTRICTION_LOAD_OBJECT_NAME,
        payload: value
    }
}

export function setCreateUnloadObject(value) {
    return {
        type: SET_CREATE_RESTRICTION_UNLOAD_OBJECT,
        payload: value
    }
}

export function setCreateUnloadObjectCode(value) {
    return {
        type: SET_CREATE_RESTRICTION_UNLOAD_OBJECT_CODE,
        payload: value
    }
}

export function setCreateUnloadObjectName(value) {
    return {
        type: SET_CREATE_RESTRICTION_UNLOAD_OBJECT_NAME,
        payload: value
    }
}

export function setCreateRestrictionExclusionsFlag(value) {
    return {
        type: SET_CREATE_RESTRICTION_EXCLUSIONS_FLAF,
        payload: value
    }
}

export function setCreateRestrictionExclusionsObject(value) {
    return {
        type: SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT,
        payload: value
    }
}

export function setCreateRestrictionExclusionsObjectType(value) {
    return {
        type: SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_TYPE,
        payload: value
    }
}

export function setCreateRestrictionExclusionsObjectCode(value) {
    return {
        type: SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_CODE,
        payload: value
    }
}

export function setCreateRestrictionExclusionsObjectName(value) {
    return {
        type: SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_NAME,
        payload: value
    }
}

/////////////////////////////////////////////////
export function setTimberSortment(value) {
    return {
        type: SET_TIMBER_SORTMENT,
        payload: value
    }
}

export function setTimberSpecies(value) {
    return {
        type: SET_TIMBER_SPECIES,
        payload: value
    }
}

export function setTimberClass(value) {
    return {
        type: SET_TIMBER_CLASS,
        payload: value
    }
}

export function setTimberDiameter(value) {
    return {
        type: SET_TIMBER_DIAMETER,
        payload: value
    }
}

export function setTimberStorage(value) {
    return {
        type: SET_TIMBER_STORAGE,
        payload: value
    }
}

export function setTimberProductionYear(value) {
    return {
        type: SET_TIMBER_PRODUCTION_YEAR,
        payload: value
    }
}

export function setTimberProductionQuarter(value) {
    return {
        type: SET_TIMBER_PRODUCTION_QUARTER,
        payload: value
    }
}

export function setTimberLength1(value) {
    return {
        type: SET_TIMBER_LENGTH1,
        payload: value
    }
}

export function setTimberLength2(value) {
    return {
        type: SET_TIMBER_LENGTH2,
        payload: value
    }
}

export function setPopUpBidTogetherAPI(value) {
    return {
        type: SET_POPUP_BID_TOGETHER_API,
        payload: value
    }
}

export function auctionsSearchTest(_url, header, data){
    let data1 = {}
    if(data.keyword !== null) data1.keyword = data.keyword
    if(data.authorName !== null && data.authorName !== '') data1.authorName = data.authorName
    if(data.classification !== null) data1.classification = data.classification
    if(data.auctionId !== null) data1.auctionId = data.auctionId
    if(data.category !== null){
        if(data.category === 'timber-english'){
            data1.sellingMethod = ['timber-english']
        }else if(data.category === 'subsoil-english'){
            data1.sellingMethod = ['subsoil-english', 'subsoil-dutch']
        }else if(data.category === 'railwayCargo-english'){
            data1.sellingMethod = ['railwayCargo-english', 'railwayCargo-dutch']
        }
    }
    if(data.status !== null) data1.status = [data.status]
    if(data.startDateFrom !== null) data1.startDateFrom = data.startDateFrom
    if(data.startDateTo !== null) data1.startDateTo = data.startDateTo
    /*if(data.status !== null){
        data1 = JSON.stringify({
            keyword: data.keyword,
            authorName: data.authorName,
            classification: data.classification,
            auctionId: data.auctionId,
            sellingMethod: data.category,
            status: data.status,
            startDateFrom: data.startDateFrom,
            startDateTo: data.startDateTo
        })
    }else{
        data1 = JSON.stringify({
            keyword: data.keyword,
            authorName: data.authorName,
            classification: data.classification,
            auctionId: data.auctionId,
            sellingMethod: data.category,
            startDateFrom: data.startDateFrom,
            startDateTo: data.startDateTo
        })
    } */
    return async dispatch => {
            //const response = await fetch(`${_url.endPoint}/api/v1/uk/public/auctions/search`, {
            const response = await fetch(`${_url.endPoint}/api/v1/uk/public/auctions/search`, {
                method: 'POST',
                body: JSON.stringify(data1),
                //headers: header
                headers: {
                    'Content-Type': 'application/json'
                }
            });

        const json = await response.json()
        dispatch({type: AUCTIONS_SEARCH, payload: json})
    }
}

export function auctionsSearchTestElemArr(_url, header, elemArr){
    return async dispatch => {
            const response = await fetch(`${_url.domen}/api/v1/uk/auctions/search/has_bids?${elemArr}`, {
                method: 'GET',
                headers: header
            });

        const json = await response.json()
        dispatch({type: AUCTIONS_SEARCH_ELEM_ARR, payload: json})
    }
}


export function auctionsGetClassificationsByTypeDGFAdd(_url, header, mask) {
    const data = {
        url: `${_url.domen}/api/v1/uk/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`,
        actionType: SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD,
        header: header
    }
    return getAPI(data);
}

export function seDgfMask(value) {
    return {
        type: SET_DGF_MASK,
        payload: value
    }
}