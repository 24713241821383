import React, {useEffect} from 'react';
import styles from './createAuction.module.css';

import {connect} from 'react-redux';

////////////////////////
import {
    auctionsGetMy,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
} from '../../redux/actions/auctions/auctions.js';

import {
    setDataAddress,
} from '../../redux/createAddress';


import {
    changeCreateItemClassificationAddId,
    getMyAuction
} from '../../redux/actions.js';

import {bankAccountGet, bankAccountShemeGet, setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../redux/actions/bankAccount/bankAccount.js';

import {auctionsBidsGetMy} from  '../../redux/actions/auctionBid/auctionBid.js';

import {unitsGetByType, unitsGetByTypeEnergy, unitsGetByTypeRailway, unitsGetByTypeSubsoil, unitsGetByTypeZU} from '../../redux/actions/itemUnits/itemUnits.js';

import {addressesGetMy} from '../../redux/actions/address/address.js';

import {
    workMyPageCurrentAuction,
    workMyPageNextAuction
} from '../../redux/togetherApi.js';

////////////////////////

import {changeCreateAuctionTab} from '../../redux/actions.js';
import {setPopUpSendAuction, setPopUpErrorAuction} from '../../redux/actions/auctions/auctions.js';
import {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changePageNumber,
    setEditAuctionFromId,
    changeLoader,
} from '../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
    changeDiscountId,
} from '../../redux/actions/discount/discount.js';

import {setUniversalError} from '../../redux/actions.js';

import {auctionsGetAwards, auctionsGetAwardsId} from '../../redux/actions/awards/awards.js';
import {contractsAuctionIdGet} from '../../redux/actions/contracts/contracts.js';
import {setPopBankAccountId, setPopBankAccountIdArr} from '../../redux/actions/bankAccount/bankAccount.js';

import {Redirect} from 'react-router-dom';

import {getAPI} from '../../redux/actions/crud.js';

import {setLogo} from '../../redux/actions/support.js';

import {
    setPopUpAuctionMyNull,
} from '../../redux/actions/auctions/auctions.js';

import ListAuctions from './listAuction/actions'
import ListMyBids from './listMyBids/myBids'

function createArrayBankAccounts1(props, i){
    let tmp = [];
    if(i !== null){
        if(i.bankAccounts !== null && i.bankAccounts.length > 0){
            for(let t = 0; t < i.bankAccounts.length; t++){
                tmp[t] = i.bankAccounts[t].id
            }
        }
    }
    if(tmp !== null){
        props.setBankAccountSave(tmp)
        props.setPopBankAccountIdArr(tmp)
    }
}

const CrateAuctionLists = ( props ) => {
    if(props.logo === null){
        props.setLogo(window.location.pathname.length)
    }else{
        if(window.location.pathname.length === 1){
            if(props.logo !== window.location.pathname.length){
                props.setLogo(null)
            }
        }else{
            if(props.logo !== window.location.pathname.length){
                props.setLogo(window.location.pathname.length)
            }
        }
    }

    if(props.token !== null && props.userProfiles !== null){
        if(window.location.href.indexOf('/create_auctions/bridge/') !== -1){
            proxyBrege(props)
        }
    }else{
        if(window.location.pathname.indexOf('/create_auctions/bridge/') !== -1){
            props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
        }
    }

    useEffect(() => {
            loadData(props)
    }, [])

    if(props.token !== null && props.userProfiles !== null){
        let tmpLocal = JSON.parse(localStorage.getItem('token'))
        let tmpNowTimeStamp = Date.now();
        if(tmpLocal.timeStamp < tmpNowTimeStamp){
            return <Redirect to='/'/>
        }
        if(props.userProfiles.canCreateBid === true){
            return <div className={styles.auctions__wrap}><ListMyBids /></div>
        }
        return <div className={styles.auctions__wrap}><ListAuctions /></div>
    }
    return null
}

function loadData(props){
    if (localStorage.getItem('token') !== null){
        props.getMyAuction(null)
        props.setPopUpAuctionMyNull()
        props.changeAuctionsSwitchWindow(0)
        
        let header5 = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        let data1 = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            params: 'timber-english?limit=100'
        }
        props.unitsGetByType(data1, header5)
        props.unitsGetByTypeEnergy(data1, header5)
        props.unitsGetByTypeRailway(data1, header5)
        props.unitsGetByTypeSubsoil(data1, header5)
        props.unitsGetByTypeZU(data1, header5)

        let data5 = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            params: '?limit=100'
        }
        props.auctionsGetClassificationsByType(data5, header5)
        props.auctionsGetClassificationsByWagonSpecies(data5, header5)
        props.auctionsGetClassificationsByTypeNadraEnglish(data5, header5)
        props.auctionsGetClassificationsByByShemaSubsoilUsageType(data5, header5)
        props.auctionsGetClassificationsByCarryType(data5, header5)
        props.auctionsGetClassificationsByTypeRailwayCargoAdd(data5, header5)
        
        props.auctionsGetClassificationsByTypeRailway(data5, header5)
        props.bankAccountShemeGet(data5, header5)
        props.auctionsGetWagonRestrict(data5, header5)
        props.auctionsGetWagonRestrictType(data5, header5)

        let data = {}
        data.domen = process.env.REACT_APP_END_POINT_BD
        data.version = 'v1'
        data.local = props.langFlag
        if(props.myCabinetPage === 0){
            data.params = `?offset=${props.myCabinetPage}`
        }else{
            data.params = `?offset=${props.myCabinetPage}0` 
        }
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        //props.auctionsGetMy(data, header)
        getAucionsList(props)
        //TODO: Временно отключено
        props.auctionsBidsGetMy(data, header)
        let dataBank = {}
        dataBank.domen = process.env.REACT_APP_END_POINT_BD
        dataBank.version = 'v1'
        dataBank.local = props.langFlag
        dataBank.params = `?offset=0`

        //TODO: Нерабочий АПИ 20211018
        props.bankAccountGet(dataBank, header)

        getAdditionalClassificationZU(props)
        getAdditionalClassificationCVZU(props)
    }
}

async function getAdditionalClassificationCVZU(props){
    if(props.classificationCVZU === null){
        const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/CVZU`;
        const response = await fetch(endPoint, {
            method: "GET",
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response === undefined || response === 'undefined'){
        }else{
            const json = await response.json()
            await props.setDataAddress(json, 'CREATE_CVZU_DATA')
        }
    }
}

async function proxyBrege(props){
    await objectBrige(props)
}
async function objectBrige(props){
    let testPath = window.location.href.split('/bridge/');
    if(props.cloneObjectId !== testPath[1]){
        props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
        props.setDataAddress(testPath[1], 'SET_ID_OBJECT_CLONE_AUCTIONS')
        let url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/en/private/auction-draft/bridge/${testPath[1]}`
        const response = await fetch(url, {
            method: "POST",
            headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
            }
        })
        .catch(console.error)
        if(response === undefined || response === 'undefined'){
        }else{
            const json = await response.json()
            if(json.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json.message[0])})
                props.setUniversalError(tmp)
                let data = {}
                data.domen = process.env.REACT_APP_END_POINT_BD
                data.version = 'v1'
                data.local = props.langFlag
                if(props.myCabinetPage === 0){
                    data.params = `?offset=${props.myCabinetPage}`
                }else{
                    data.params = `?offset=${props.myCabinetPage}0` 
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
                return <Redirect to='/create_auctions'/>
            }else{
                bridgeAuction(props, json)
            }
        }
        return <Redirect to='/create_auctions'/>
    }
}


async function getAdditionalClassificationZU(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/kvtspz?limit=1000`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}
///////////////////////////////////////////


async function bridgeAuction(props, data){
    props.setBankAccountSave([])
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])

    await createArrayBankAccounts1(props, data)

    if(data.discount !== null){
        props.changeDiscountPercent(data.discount.discountPercent)
        props.changeDiscountId(data.discount.id)
        props.discountAuc(data.discount.discount)
        if(data.discount.previousAuctionValue !== null){
            props.changeDiscountPreviousAuctionCurrency(data.previousAuctionValue.currency)
            props.changeDiscountPreviousAuctionAmount(data.previousAuctionValue.amount)
        }
    }
    props.changeDiscountPreviousAuctionId(data.previousAuctionId)
    props.setEditAuctionFromId(null)
    await getAucionFromId(props, data.id, 1)
    return <Redirect to='/create_auctions'/>
}


////////////////////////////
async function getAucionFromId(props, aucId, type, tenderAttempts=null){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/${aucId}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setEditAuctionFromId(json)
            if(tenderAttempts === '2'){
                props.setDataAddress(tenderAttempts, 'CREATE_AUCTION_TENDER_ATTEMPTS')
            }else if(tenderAttempts === '3'){
                props.setDataAddress(tenderAttempts, 'CREATE_AUCTION_TENDER_ATTEMPTS')
                if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
                    props.setDataAddress("legitimatePropertyLease-dutch", 'CREATE_AUCTION_TYPE')
                }else{
                    props.setDataAddress("legitimatePropertyLease-dutch-fast-manual", 'CREATE_AUCTION_TYPE')
                }
            }
            await getAddressOrg(props, json, type)
        }
    }
}

async function getAddressOrg(props, auction, type){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/organizations?limit=30&offset=0`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            let tmpArr = json
            if(auction.relatedOrganizations !== null && auction.relatedOrganizations !== 'null'){
                if(auction.relatedOrganizations.currentTenant !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.currentTenant.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.currentTenant
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                    }
                }
                if(auction.relatedOrganizations.sellingEntity !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.sellingEntity.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.sellingEntity
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                    }
                }
                if(auction.relatedOrganizations.propertyOwner !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.propertyOwner.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.propertyOwner
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                    }
                }
            }
            props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
        }
    }
    props.changeAuctionsSwitchWindow(type)
    props.changeLoader(false)
    props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
}


async function getAucionsList(props){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-drafts/my`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            const tmp = await createList(json)
            if(tmp.length > 0){
                props.setDataAddress(tmp, 'AUCTIONS_GET_MY')
            }else{
                props.setDataAddress(null, 'AUCTIONS_GET_MY')
            }

        }
    }
    props.changeLoader(false)
}

function createList(json){
    let tmp = []
    if(json.length > 0){
        for(let i = 0; i < json.length; i++){
            if(json[i].publicAuction !== null){
                tmp[i] = json[i].publicAuction
                tmp[i].dataAuction = json[i]
            }else if(json[i].oldAuctionDraft !== null){
                tmp[i] = json[i].oldAuctionDraft
                tmp[i].dataAuction = json[i]
            }else if(json[i].auctionDraft !== null){
                tmp[i] = json[i].auctionDraft
                tmp[i].dataAuction = json[i]
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        auctionsMy: state.start.auctionsGetMy,
        auctionsMySingl: state.start.auctionsMy,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        auctionsMyNew: state.start.auctionsMyNew,
        userProfiles: state.start.userProfiles,
        myBids: state.start.myBids,
        myCabinetPage: state.start.myCabinetPage,
        cloneObjectId: state.start.cloneObjectId,
        classificationCVZU: state.start.classificationCVZU,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTab,

    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,

    getAPI,
    setPopUpSendAuction,

    auctionsGetAwards,
    auctionsGetAwardsId,
    changePageNumber,

    contractsAuctionIdGet,
    setPopUpErrorAuction,
    setPopBankAccountId,
    setPopBankAccountIdArr,

    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
    changeDiscountId,

    setLogo,

    setPopUpAuctionMyNull,

    setUniversalError,
    setEditAuctionFromId,

    changeLoader,

    //////////////////////////
    auctionsGetMy,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
    changeCreateItemClassificationAddId,
    getMyAuction,
	bankAccountGet,
	bankAccountShemeGet,
	auctionsBidsGetMy,
	unitsGetByType,
	unitsGetByTypeEnergy,
	unitsGetByTypeRailway,
	unitsGetByTypeSubsoil,
    unitsGetByTypeZU,
    addressesGetMy,
    
    workMyPageCurrentAuction,
    workMyPageNextAuction,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(CrateAuctionLists)