import React,{useState} from 'react';
import styles from './newAward.module.css';

import {connect} from 'react-redux';

import {changeAwardRejectPopUpStatus} from '../../../redux/actions/awards/awards.js';

import { setAwardAllUserPopUp, setAwardAllUserPopUpUser } from '../../../redux/actions.js';
import {isSUE, isSUD, isLLE, isLLD, isSPE, isSPD, isLPE, isLLP} from '../../../redux/procedure';

import {
    changeContractApprowePopUpStatus,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
} from '../../../redux/actions/contracts/contracts.js';

import { getUpdateDataAuction } from '../updateData';
import { saveArrBids } from '../../../redux/allAuctionBids';
import { setDataAddress } from '../../../redux/createAddress';

import TextFull from '../../elements/viewData/textFull'
import TextHalf from '../../elements/viewData/textHalf'
import PopDown from '../../elements/viewData/popdownBlock/popDownBlock'
import DocItem from '../../documents/docItem';

import {
    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,
} from '../../../redux/history.js';
import {setUniversalError, changeLoader} from '../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';

import {modifiDateString} from '../../../redux/modifiDate'
import { checkProp } from '../../../redux/check';

const Contract = (props) => {
    const [slim, setSlim] = useState({ state: false, text: 'Розгорнути' })

    
    function Slimmer() {
        slim.state ? setSlim({ state: false, text: 'Розгорнути'}) : setSlim({ state: true, text: 'Згорнути'})
    }

    if(props.auctionId !== null){
        if(props.auctionId === undefined || props.auctionId === 'undefined'){

        }else{
            if(
                props.auctionId.status === 'cancelled'
            ){
                if(props.auctionId.cancellations !== null){
                    return (<>
                                
                                <div className={styles.contractBlock}>
                                    <div className={styles.contractBlock_title}>Скасування Аукціону</div>
                                    <div className={styles.slimmer} onClick={() => Slimmer()}>{slim.text}</div>
                                    {slim.state ?
                                        <div className={styles.contractBlockContent}>
                                            {viewAwardArr(props)}
                                        </div> : null
                                    }
                                </div>
                            </>
                    )
                }
            }
        }
    }
    return null
}

function viewAwardArr(props){
    let tmp = null;
    if(props.auctionId !== null){
        if(props.auctionId.cancellations.length > 0){
            tmp = props.auctionId.cancellations.map(
                (i, count) => {
                    return (<>
                        <div className={styles.contractBlock}>
                            {viewTitle(i, props)}
                            {viewData(i)}
                            {viewContractDocuments(props, i, count)}
                        </div>
                        </>
                    )
                }
            )
        }
    }
    return tmp
}


function viewTitle(i, props){
    if(i.reason !== null && i.reason !== ''){
        return (
            <TextFull
                title='Причина скасування Аукціону'
                data={reasonDictionary(i.reason.uk_UA, props)}
            />
        )
    }
    return null
}

function reasonDictionary(data, props){
    if(isSUE(props.auctionId.sellingMethod) || isSUD(props.auctionId.sellingMethod)){
        switch(data){
            case "1": return "Наявність рішення суду, яке набрало законної сили"
            case "2": return "Визнання в установленому законодавством порядку протиправними дій та/або скасування рішень стосовно пропозицій щодо визначення переліку ділянок надр, дозволи на користування якими виставляються на аукціон."
            case "3": return "Визнання в установленому законодавством порядку протиправними дій та/або скасування рішень Міндовкілля стосовно погоджень щодо визначення переліку ділянок надр, дозволи на користування якими виставляються на аукціон для надання спеціального дозволу на геологічне вивчення, у тому числі дослідно-промислову розробку, корисних копалин з подальшим видобуванням корисних копалин (промислову розробку родовищ)."
            case "4": return "Зміна виду користування надрами, визначеного законодавчими актами."
            default: return data
        }
    }else if(isLLE(props.auctionId.sellingMethod) || isLLD(props.auctionId.sellingMethod) || isLLP(props.auctionId.sellingMethod)){
        switch(data){
            case "1": return "Скасування рішення про включення об’єкта до Переліку першого типу"
            case "2": return "Прийняття рішення про виключення об’єкта з Переліку першого типу"
            case "3": return "Зміна рішення про включення об’єкта до Переліку першого типу"
            case "4": return "Порушення порядку підготовки аукціону"
            case "5": return "Виникнення технічного збою, підтвердженого адміністратором електронної торгової системи"
            case "6": return "Рішення суду або інші підстави"
            default: return data
        }
    }else if(isSPE(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod)){
        switch(data){
            case "1": return "Включення об’єкта приватизації до переліку об’єктів права державної власності, що не підлягають приватизації"
            case "2": return "Ухвалення місцевою радою рішення про скасування попереднього рішення про приватизацію відповідного об’єкта комунальної власності"
            case "3": return "Порушено порядок підготовки до аукціону"
            case "4": return "Виникнення технічного збою, підтвердженого адміністратором електронної торгової системи"
            case "5": return "Рішення суду або інші підстави"
            case "6": return "Рішення суду"
            case "7": return "Інша підстава за рішенням органу приватизації"
            default: return data
        }
    }else if(isLPE(props.auctionId.sellingMethod)){
        switch(data){
            case "1": return "Включення об’єкта приватизації до переліку об’єктів права державної власності, що не підлягають приватизації"
            case "2": return "Ухвалення місцевою радою рішення про скасування попереднього рішення про приватизацію відповідного об’єкта комунальної власності"
            case "3": return "Порушено порядок підготовки до аукціону"
            case "4": return "Виникнення технічного збою, підтвердженого адміністратором електронної торгової системи"
            case "5": return "Рішення суду або інші підстави"
            case "6": return "Рішення суду"
            case "7": return "Інша підстава за рішенням органу приватизації"
            default: return data
        }
    }
    return data
}

function viewData(data){
    if(data.hasOwnProperty('datePublished')){
        if(data.datePublished !== null){
            let tmp = `${modifiDateString(data.datePublished, 3)}`
            return (
                <TextHalf
                    title='Дата скасування'
                    data={tmp}
                />
            )
        }
    }
    return null
}

function viewContractDocuments(props, data, count, setPopAddDoc){
    if(data !== null){
        if (data.documents !== null) {
            let TmpBidDocs = viewDocAwardBid(props, data)
            let tmpArr =  TmpBidDocs !== null ? TmpBidDocs.length : null
            if((data.documents !== null && data.documents.length > 0) || (TmpBidDocs !== null && TmpBidDocs.length > 0)){// || viewDocAwardBidCount(props, data) !== null){
                if(data.documents.length !== 0)
                if(viewDocAwardBid(props, data) !== null && viewDocAwardBid(props, data).length !== 0)
                return <PopDown
                    title={`Додані документи (${data.documents.length + tmpArr})`}
                    content={
                        <>
                            {viewDocContract(data, count, props)}
                        </>
                    }
                />
            }
        }
    }
    return null
}

function viewDocContract(data, countContract, props) { // документы организатора
    
    function dataECPMapper(data, t) {
        let tmp = data
            .filter(
            (item) => {
                return item.documentType === 'digitalSignature' && item.relatedDocument === t.id
            })
        if (tmp.length === 0) return null
        return tmp
    }
    
    if(data !== null){
        if(data === undefined || data === 'undefined'){
        }else{
            let tmp = data.documents
            .filter(
                (t) => {
                    return t.documentType !== 'digitalSignature'
                }
            )
            .map(
                (t, count) => {

                        const getHistoryURL = (props, data) => {
                            if (t.documentOf === 'award') {
                                    return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/awards/${data.id}/documents`
                                }
                                if (t.documentOf === 'bid') {
                                    return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/bids/${data.bidId}/documents`
                                }
                        }
                        
                        const getBidOwner = (props, data) => {
                            if (props.auctionId.privateData.hasBids) {
                                for (let key in props.auctionId.privateData.bids) {
                                    if (data.bidId === props.auctionId.privateData.bids[key].bidId) return true
                                }
                            }
                            return false
                        }
                    

                        const docScopeData = {}
                        docScopeData.aucId = props.auctionId.id
                        docScopeData.aucStatus = props.auctionId.status
                        docScopeData.aucType = props.auctionId.sellingMethod
                        docScopeData.reload = () => {getUpdateDataAuction(props, props.auctionId.id)}
                        docScopeData.aucOwner = props.auctionId.privateData.isOwner
                        docScopeData.historyUrl = getHistoryURL(props, data)
                        
                           
                        docScopeData.bidOwner = getBidOwner( props, data)
                        docScopeData.docTypesList = [{ value: '"cancellationDetails"', name: 'Причини скасування', main: null }]
                        docScopeData.awardStatus = data.status
                        docScopeData.docList = data.documents

                        if(props.auctionId.privateData.isOwner){
                            docScopeData.typeOf = 'cancellation'
                            //docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/cancellation/${data.id}/document`
                            //docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/cancellation/${data.id}/document`
                            //docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/cancellation/${data.id}/document`

                            docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/cancelation/${data.id}/document`
                            docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/cancelation/${data.id}/document`
                            docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/cancelation/${data.id}/document`

                            
                        }
                    
                        return (
                            <DocItem
                                data={t}
                                scopeData={docScopeData}
                                dataECP={ dataECPMapper(data.documents, t) }
                                key={count}
                            />
                        )
                }
            )
            return tmp
        }
    }
}

function viewDocAwardBid(props, data) { //документы участника
    const getBidOwner = (props, data) => {
        if (props.auctionId.privateData.hasBids) {
            for (let key in props.auctionId.privateData.bids) {
                if (data.bidId === props.auctionId.privateData.bids[key].bidId) return true
            }
        }
        return false
    }

    function dataECPMapper(data, t) {
        let tmp = data
            .filter(
            (item) => {
                return item.documentType === 'digitalSignature' &&  item.relatedDocument === t.documentId
            })
        if (tmp.length === 0) return null
        return tmp
    }

    //TODO: Пофиксить вывод документов бида
    let tmpArr = null

    if (checkProp(props.auctionId, 'bids') && props.auctionId.bids.length > 0) {
        for (let key in props.auctionId.bids) {
            if (props.auctionId.bids[key].id === data.bidId) {
                if (checkProp(props.auctionId.bids[key], 'documents') && props.auctionId.bids[key].documents.length > 0) {
                    // ecpArr = props.auctionId.bids[key].documents
                    tmpArr = props.auctionId.bids[key].documents.filter(
                        (t) => {
                            return t.documentType === 'auctionProtocol'
                                // || t.documentType === "digitalSignature"
                                            }
                    )
                    break
                }
            }
        }
    }
    
    if (tmpArr !== null) {
        let tmp = tmpArr.map(
            (t, count) => {
                const docScopeData = {}
                docScopeData.aucId = props.auctionId.id
                docScopeData.aucStatus = props.auctionId.status
                docScopeData.aucType = props.auctionId.sellingMethod
                docScopeData.reload = () => {
                    // await getUpdateDataBid(props, props.auctionId.privateData.bids[0].id)
                    getUpdateDataAuction(props, props.auctionId.id)
                }
                docScopeData.aucOwner = props.auctionId.privateData.isOwner
                
                docScopeData.bidOwner = getBidOwner( props, data)
                docScopeData.docTypesList = [{ value: 'cancellationDetails', name: 'Причини скасування', main: null }]
                docScopeData.awardStatus = data.status
                docScopeData.typeOf = 'cancellation'
                docScopeData.docList = data.documents

                function getOldDocId(props, t){ // Достаем старый айдишник документа для старого запроса
                    if (checkProp(props.auctionsAllBids, 'arr') && checkProp(props.auctionsAllBids.arr, 'documents') && props.auctionsAllBids.arr.documents.length > 0) {
                        for (let key in props.auctionsAllBids.arr.documents) {
                            if (props.auctionsAllBids.arr.documents[key].documentId === t.id) return props.auctionsAllBids.arr.documents[key].id
                        }
                    }
                }

                const docOldId = getOldDocId(props, t)
                docScopeData.historyUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/bids/${data.bidId}/documents`
                if (checkProp(props.auctionId.privateData, 'bids') && props.auctionId.privateData.bids.length > 0 ){
                // docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents`
                    docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents/${docOldId}`
                    docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents/${docOldId}`
                }
                return (
                    <>
                        <DocItem
                            data={t}
                            scopeData={docScopeData}
                            key={count}
                            dataECP={dataECPMapper(tmpArr, t)}
                        />
                        {/* {viewDocAwardBidKEP(data, countContract, props, data.relatedDocument)} */}
                    </>
                )
            }
        )
        return tmp
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionAwards: state.start.auctionAwards,
        allUserPopUp: state.start.allUserPopUp,
        allUserPopUpUser: state.start.allUserPopUpUser,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        timberContractPut: state.start.timberContractPut,
        pageNumber: state.start.pageNumber,
        auctionsGetId: state.start.auctionsGetId,
        auctionsAllBids: state.start.auctionsAllBids,
        // history: state.start.history,
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
        bidEdit: state.start.bidEdit,
        userProfiles: state.start.userProfiles
        // historyDocsContracts: state.start.historyDocsContracts,
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,
    setAwardAllUserPopUp,
    setAwardAllUserPopUpUser,

    changeContractApprowePopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,

    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,
    setContractDatePaid,

    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
    setDataAddress,
    saveArrBids
}

export default connect(mapStateToProps, mapDispatchToProps)(Contract)
