export function modifiDateString(value, type, typeZu=false){
    if(typeZu === false){
        if(value === undefined || value === 'undefined' || value === '***'){
            return '***'
        }else{
            if(value !== null && value !== '***'){
                let tmp = value.split('T');
                let date = tmp[0].split('-');
                let timeBuff = tmp[1].split('+');
                let time = timeBuff[0].split(':');
                let x = new Date();
                let currentTimeZoneOffsetInHours = x.getTimezoneOffset() / 60;
                currentTimeZoneOffsetInHours = currentTimeZoneOffsetInHours * -1
                let hours = Number(time[0]) + currentTimeZoneOffsetInHours
                let day = Number(date[2])
                let month = new Date(date[0], date[1], 0).getDate()
                if(hours >= 24){
                    day = day + 1
                    hours = hours - 24
                    if(day > month){
                        date[1] = Number(date[1]) + 1
                        if(date[1] > 12) {
                            date[1] = 1
                            date[0] = Number(date[0]) + 1
                        }
                        day = '01'
                        if(date[1] < 10){
                            date[1] = '0' + date[1]
                        }
                    }
                }
                let tmpTime1
                if(time[1] === '0'){
                    tmpTime1 = '00'
                }else{
                    tmpTime1 = time[1]
                }

                if(day < 10) day = '0' + day

                if(type === 1) return day + '.' + date[1] + '.' + date[0]
                if(type === 2) return hours + ':' + tmpTime1
                if(type === 3) return day + '.' + date[1] + '.' + date[0] + ' ' + hours + ':' + tmpTime1
            }
        }
    }else{
        if(value === undefined || value === 'undefined' || value === '***'){
            return '***'
        }else{
            if(type === 1){
                let tmp = null
                if(value.indexOf('/') !== -1) tmp = value.split('/');
                if(value.indexOf('-') !== -1) tmp = value.split('-');
                return tmp[2] + '.' + tmp[1] + '.' + tmp[0]
            }else{
                let tmpData = new Date(value)
                let tmpStr = tmpData.getHours() + ':' + tmpData.getMinutes()
                return tmpStr
            }
        }
    }
    return null
}

export function transformData(data, type){
    switch(type){
        case 'Y':
            if(data.indexOf('Y') !== -1){
                let tmpArr = data.split('P')
                let tmpArr1 = tmpArr[1].split('Y')
                if(
                    tmpArr1[0].indexOf('12') !== -1 ||
                    tmpArr1[0].indexOf('11') !== -1 ||
                    tmpArr1[0].indexOf('10') !== -1
                ){
                    return tmpArr1[0] + ' років'
                }else if(tmpArr1[0].indexOf('0') !== -1){
                    return ''
                }else if(tmpArr1[0].indexOf('1') !== -1){
                    return tmpArr1[0] + ' рік'
                }else if(
                    tmpArr1[0].indexOf('2') !== -1 ||
                    tmpArr1[0].indexOf('3') !== -1 ||
                    tmpArr1[0].indexOf('4') !== -1
                ){
                    return tmpArr1[0] + ' роки'
                }else{
                    return tmpArr1[0] + ' років'
                }
            }
            return ''
        case 'M':
            if(data.indexOf('M') !== -1){
                if(data.indexOf('Y') !== -1){
                    let tmpArr = data.split('Y')
                    let tmpArr1 = tmpArr[1].split('M')
                    if(tmpArr1[0].indexOf('0') !== -1){
                        return ''
                    }else if(
                        tmpArr1[0].indexOf('12') !== -1 ||
                        tmpArr1[0].indexOf('11') !== -1 ||
                        tmpArr1[0].indexOf('10') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' місяців'
                    }else if(tmpArr1[0].indexOf('1') !== -1){
                        return ' ' + tmpArr1[0] + ' місяць'
                    }else if(
                        tmpArr1[0].indexOf('2') !== -1 ||
                        tmpArr1[0].indexOf('3') !== -1 ||
                        tmpArr1[0].indexOf('4') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' місяця'
                    }else if(
                        tmpArr1[0].indexOf('4') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' місяці'
                    }else{
                        if(tmpArr1[0] !== '0') return ' ' + tmpArr1[0] + ' місяців'
                    }
                }else{
                    let tmpArr = data.split('P')
                    let tmpArr1 = tmpArr[1].split('M')
                    if(tmpArr1[0].indexOf('0') !== -1){
                        return ''
                    }else if(
                        tmpArr1[0].indexOf('12') !== -1 ||
                        tmpArr1[0].indexOf('11') !== -1 ||
                        tmpArr1[0].indexOf('10') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' місяців'
                    }else if(tmpArr1[0].indexOf('1') !== -1){
                        return ' ' + tmpArr1[0] + ' місяць'
                    }else if(
                        tmpArr1[0].indexOf('2') !== -1 ||
                        tmpArr1[0].indexOf('3') !== -1 ||
                        tmpArr1[0].indexOf('4') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' місяця'
                    }else if(
                        tmpArr1[0].indexOf('4') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' місяці'
                    }else{
                        if(tmpArr1[0] !== '0') return ' ' + tmpArr1[0] + ' місяців'
                    }
                }
            }
            return ''
        case 'D':
            if(data.indexOf('D') !== -1){
                if(data.indexOf('M') !== -1){
                    let tmpArr = data.split('M')
                    let tmpArr1 = tmpArr[1].split('D')
                    /*if(tmpArr1[0].indexOf('0') !== -1){
                        return ''
                    }else */
                    if(
                        tmpArr1[0].indexOf('10') !== -1 ||
                        tmpArr1[0].indexOf('11') !== -1 ||
                        tmpArr1[0].indexOf('12') !== -1 ||
                        tmpArr1[0].indexOf('13') !== -1 ||
                        tmpArr1[0].indexOf('14') !== -1 ||
                        tmpArr1[0].indexOf('15') !== -1 ||
                        tmpArr1[0].indexOf('16') !== -1 ||
                        tmpArr1[0].indexOf('17') !== -1 ||
                        tmpArr1[0].indexOf('18') !== -1 ||
                        tmpArr1[0].indexOf('19') !== -1 ||
                        tmpArr1[0].indexOf('20') !== -1 ||
                        tmpArr1[0].indexOf('21') !== -1 ||
                        tmpArr1[0].indexOf('22') !== -1 ||
                        tmpArr1[0].indexOf('23') !== -1 ||
                        tmpArr1[0].indexOf('24') !== -1 ||
                        tmpArr1[0].indexOf('25') !== -1 ||
                        tmpArr1[0].indexOf('26') !== -1 ||
                        tmpArr1[0].indexOf('27') !== -1 ||
                        tmpArr1[0].indexOf('28') !== -1 ||
                        tmpArr1[0].indexOf('29') !== -1 ||
                        tmpArr1[0].indexOf('30') !== -1 ||
                        tmpArr1[0].indexOf('31') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' днів'
                    }else if(tmpArr1[0].indexOf('1') !== -1){
                        return ' ' + tmpArr1[0] + ' день'
                    }else if(
                        tmpArr1[0].indexOf('2') !== -1 ||
                        tmpArr1[0].indexOf('3') !== -1 ||
                        tmpArr1[0].indexOf('4') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' дні'
                    }else{
                        if(tmpArr1[0] !== '0') return ' ' + tmpArr1[0] + ' днів'
                    }
                }else if(data.indexOf('Y') !== -1){
                    let tmpArr = data.split('Y')
                    let tmpArr1 = tmpArr[1].split('D')
                    /*if(tmpArr1[0].indexOf('0') !== -1){
                        return ''
                    }*/
                    if(
                        tmpArr1[0].indexOf('10') !== -1 ||
                        tmpArr1[0].indexOf('11') !== -1 ||
                        tmpArr1[0].indexOf('12') !== -1 ||
                        tmpArr1[0].indexOf('13') !== -1 ||
                        tmpArr1[0].indexOf('14') !== -1 ||
                        tmpArr1[0].indexOf('15') !== -1 ||
                        tmpArr1[0].indexOf('16') !== -1 ||
                        tmpArr1[0].indexOf('17') !== -1 ||
                        tmpArr1[0].indexOf('18') !== -1 ||
                        tmpArr1[0].indexOf('19') !== -1 ||
                        tmpArr1[0].indexOf('20') !== -1 ||
                        tmpArr1[0].indexOf('21') !== -1 ||
                        tmpArr1[0].indexOf('22') !== -1 ||
                        tmpArr1[0].indexOf('23') !== -1 ||
                        tmpArr1[0].indexOf('24') !== -1 ||
                        tmpArr1[0].indexOf('25') !== -1 ||
                        tmpArr1[0].indexOf('26') !== -1 ||
                        tmpArr1[0].indexOf('27') !== -1 ||
                        tmpArr1[0].indexOf('28') !== -1 ||
                        tmpArr1[0].indexOf('29') !== -1 ||
                        tmpArr1[0].indexOf('30') !== -1 ||
                        tmpArr1[0].indexOf('31') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' днів'
                    }else if(tmpArr1[0].indexOf('1') !== -1){
                        return ' ' + tmpArr1[0] + ' день'
                    }else if(
                        tmpArr1[0].indexOf('2') !== -1 ||
                        tmpArr1[0].indexOf('3') !== -1 ||
                        tmpArr1[0].indexOf('4') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' дні'
                    }else{
                        if(tmpArr1[0] !== '0') return ' ' + tmpArr1[0] + ' днів'
                    }
                }else{
                    let tmpArr = data.split('P')
                    let tmpArr1 = tmpArr[1].split('D')
                    /*if(tmpArr1[0].indexOf('0') !== -1){
                        return ''
                    }*/
                    if(
                        tmpArr1[0].indexOf('10') !== -1 ||
                        tmpArr1[0].indexOf('11') !== -1 ||
                        tmpArr1[0].indexOf('12') !== -1 ||
                        tmpArr1[0].indexOf('13') !== -1 ||
                        tmpArr1[0].indexOf('14') !== -1 ||
                        tmpArr1[0].indexOf('15') !== -1 ||
                        tmpArr1[0].indexOf('16') !== -1 ||
                        tmpArr1[0].indexOf('17') !== -1 ||
                        tmpArr1[0].indexOf('18') !== -1 ||
                        tmpArr1[0].indexOf('19') !== -1 ||
                        tmpArr1[0].indexOf('20') !== -1 ||
                        tmpArr1[0].indexOf('21') !== -1 ||
                        tmpArr1[0].indexOf('22') !== -1 ||
                        tmpArr1[0].indexOf('23') !== -1 ||
                        tmpArr1[0].indexOf('24') !== -1 ||
                        tmpArr1[0].indexOf('25') !== -1 ||
                        tmpArr1[0].indexOf('26') !== -1 ||
                        tmpArr1[0].indexOf('27') !== -1 ||
                        tmpArr1[0].indexOf('28') !== -1 ||
                        tmpArr1[0].indexOf('29') !== -1 ||
                        tmpArr1[0].indexOf('30') !== -1 ||
                        tmpArr1[0].indexOf('31') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' днів'
                    }else if(tmpArr1[0].indexOf('1') !== -1){
                        return ' ' + tmpArr1[0] + ' день'
                    }else if(
                        tmpArr1[0].indexOf('2') !== -1 ||
                        tmpArr1[0].indexOf('3') !== -1 ||
                        tmpArr1[0].indexOf('4') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' дні'
                    }else{
                        if(tmpArr1[0] !== '0') return ' ' + tmpArr1[0] + ' днів'
                    }
                }
            }
            return ''
        default:
            return null
    }
}

export function dateTransform(a, b) {
    if(a === null) return null
    let tmpStamp = Date.parse(a)

    let res = new Date(tmpStamp)
    let tmpYear = res.getFullYear()
    let tmpMonth = res.getMonth()+1 < 10 ? `0${res.getMonth()+1}` : res.getMonth()+1
    let tmpDay = res.getDate() < 10 ? `0${res.getDate()}` : res.getDate()
    let tmpTime = `${res.getHours() < 10 ? `0${res.getHours()}` : res.getHours()}:${res.getMinutes() < 10 ? `0${res.getMinutes()}` : res.getMinutes()}`
    
    //вывод времени при необходимости
    switch (b) {
        case 'time':
            return `${tmpDay}.${tmpMonth}.${tmpYear} ${tmpTime}`
        case 'onlyTime':
            return `${tmpTime}`
        default:
            return `${tmpDay}.${tmpMonth}.${tmpYear}`
    }
}