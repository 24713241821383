import React, {useState} from 'react';
import styles from './awardEditModule.module.css';

import {connect} from 'react-redux';

import {changeAwardRejectPopUpStatus,} from '../../../../../redux/actions/awards/awards.js';

import {setAwardAllUserPopUp, setAwardAllUserPopUpUser} from '../../../../../redux/actions.js';

import {
    changeContractApprowePopUpStatus,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
} from '../../../../../redux/actions/contracts/contracts.js';

import {getUpdateDataAuction, getUpdateDataBid} from '../../../../product/updateData';
import {saveArrBids} from '../../../../../redux/allAuctionBids';
import {setDataAddress} from '../../../../../redux/createAddress';

import TextHalf from '../../../../elements/viewData/textHalf'
import PopDown from '../../../../elements/viewData/popdownBlock/popDownBlock'
import AddDocPopup from '../../../../documents/addDocPopup/addDocPopup';
import DocItem from '../../../../documents/docItem';

import {
    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,
} from '../../../../../redux/history.js';
import {setUniversalError, changeLoader} from '../../../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../../../redux/actions/contracts/contracts.js';

import {modifiDateString} from '../../../../../redux/modifiDate'
import {viewStringValue} from '../../../../../api/valueToString.js'
import {getAddressAsString} from "../../../../../redux/check";
import procedure from "../../../../../redux/procedure";
import PopAsk from "../../../../_popups/popAsk/popAsk";
import {getDefaultHeaders} from "../../../../_api/bidApi";

const AwardEditREM = (props) => {
    const [popAddDoc, setPopAddDoc] = useState(false)
    const [popApproveDocuments, setPopApproveDocuments] = useState(false)

    const bid = procedure.getBidByAward(props.auction?.bids, props.award)
    return (
        <div className={styles.awardBlock} key={props.award.id}>
            {props.award?.title ? <TextHalf title='Назва' data={props.award?.title}/> : null}
            <TextHalf title='Назва учасника' data={props.award?.buyers[0].name?.uk_UA}/>
            {viewBidValue(props, props.award)}
            {initialValueAmount(props, props.award)}
            {bid?.quantity ? <TextHalf title='Розмір частки квоти в заяві' data={bid?.quantity + ' кВт'}/> : null}
            {props.award.terminationReason ?
                <TextHalf title='Причина дискваліфікації'
                          data={viewTypeTerminationReason(props, props.award?.terminationReason)}
                />
                :
                null
            }
            {viewStatus(props, props.award)}
            <TextHalf title='Юридична адреса' data={getAddressAsString(props.award?.buyers[0]?.address)}/>
            {props.award.admissionPeriod ?
                <TextHalf
                    title='Період прийняття рішення щодо набуття статусу переможця'
                    data={getPeriodAsText(props.award.admissionPeriod)}
                />
                : null}
            {props.award.signingPeriod ?
                <TextHalf
                    title="Період публікації протоколу/підписання договору"
                    data={getPeriodAsText(props.award.signingPeriod)}
                />
                : null}
            {props.award.terminationReason === null ? <div className={styles.btnBlock}>
                {viewAwardActionButtonsBlock(props, props.award, props.count, setPopAddDoc, popAddDoc, popApproveDocuments, setPopApproveDocuments)}
            </div> : null}
            {viewDocumentsBlock(props, props.award, props.count)}
        </div>
    )
}

function viewAwardActionButtonsBlock(props, award, count, setPopAddDoc, popAddDoc, popApproveDocuments, setPopApproveDocuments) {

    if (
        props.auction.status === 'active'
        || (props.auction.status === 'waiting' && procedure.isAwardOwner(props.auction, award))
        || props.auction.status === 'cancelled'
        || props.auction.status === 'unsuccessful'
        || props.auction.status === 'complete'
        || award.status === 'cancelled'
        || award.status === 'unsuccessful'
        || award.status === 'pending_waiting'
        || procedure.getContractByAward(props?.auction?.contracts, award)?.status === 'active'
    ) {
        return null
    }

    function showAddDocPopup(popAddDoc, setPopAddDoc) {
        return (
            <>
                {popAddDoc ?
                    <AddDocPopup
                        scopeData={docScopeData}
                        actionClose={() => {
                            setPopAddDoc(false)
                        }}
                        actionNo={() => {
                            setPopAddDoc(false)
                        }}
                    />
                    : null}
                <div
                    className={styles.btnAccept}
                    onClick={
                        () => {
                            setPopAddDoc(true)
                        }
                    }
                >
                    Документи кваліфікації
                </div>
            </>
        )
    }

    const docScopeData = {}
    docScopeData.aucId = props.auction.id
    docScopeData.aucStatus = props.auction.status
    docScopeData.aucType = props.auction.sellingMethod
    docScopeData.awardId = award.id
    docScopeData.awardStatus = award.status
    docScopeData.aucOwner = props?.auction?.privateData?.isOwner
    docScopeData.bidOwner = procedure.isAwardOwner(props.auction, award)
    docScopeData.bidId = award.bidId
    docScopeData.docTypesList = getAwardDocTypes(props.auction, award)
    docScopeData.reload = () => {
        getUpdateDataAuction(props, props.auction.id)
        if (!props?.auction?.privateData?.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
    }
    docScopeData.docList = award.documents
    if (props?.auction?.privateData?.isOwner) {
        docScopeData.typeOf = 'award'
        docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/award/${award.id}/documents`
    } else if (props.auction?.privateData?.hasBids) {
        let bid = procedure.getBidByAward(props.auction?.bids, award)
        if (bid === null || award?.status === 'protocol_signed') {
            return
        }
        docScopeData.typeOf = 'bidAward'
        docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/bid/${bid.id}/documents`
    }
    if (props.auction?.privateData?.isOwner === true || procedure.isAwardOwner(props.auction, award)) {
        return (
            <>
                {showAddDocPopup(popAddDoc, setPopAddDoc)}
                {viewUserActionButtonsBlock(props, award, count, popApproveDocuments, setPopApproveDocuments)}
            </>
        )
    }
    return null
}


function getAwardDocTypes(auction, award) {
    if (award.status === 'cancelled' || award.status === 'unsuccessful') {
        return []
    }
    let documents = []

    if (award?.status === 'pending' || award?.status === 'pending_admission') {
        documents.push({value: 'auctionProtocol', name: 'Протокол аукціону', main: null})
    }

    if (procedure.isAwardOwner(auction, award)) {
        if (award?.status === 'verification') {
            documents.push({value: 'x_guarantee', name: 'Фінансове забезпечення'},)
            documents.push({
                value: 'x_ultimateBeneficiaryInfo',
                name: 'Інформація про кінцевого бенефіціарного власника'
            },)
            documents.push({value: 'x_governingBodyInfo', name: 'Інформація про органи управління'},)
            documents.push({value: 'x_relatedParties', name: 'Інформація про пов\'язаних осіб'},)
            documents.push({
                value: 'x_generationType',
                name: 'Довідка із зазначенням виду альтернативного джерела енергії'
            },)
            documents.push({value: 'eligibilityDocuments', name: 'Договір про приєднання об\'єкта електроенергетики'},)
        } else {
            documents.push({value: 'x_guarantee', name: 'Фінансове забезпечення', main: null})
        }
    }
    if (auction?.privateData?.isOwner) {
        if (award.status !== 'protocol_signed') {
            documents.push({value: 'rejectionProtocol', name: 'Акт про невідповідність', main: null})
        }
        documents.push({value: 'act', name: 'Акт про відмову', main: null})
    }

    return documents
}

function viewBidValue(props, award) {
    if (props?.auction?.bids?.length > 0) {
        for (let tmp = 0; tmp < props.auction.bids.length; tmp++) {
            if (props.auction.bids[tmp].value !== null) {
                if (props.auction.bids[tmp].id === award.bidId) {
                    let tmpValue = viewStringValue(props.auction.bids[tmp].value)
                    return (
                        <TextHalf
                            title='Розмір цінової пропозиції'
                            data={tmpValue}
                        />
                    )
                }
            }
        }
    }
    return null
}

function initialValueAmount(props, award) {
    if (props.auction?.bids?.length > 0) {
        for (let tmp = 0; tmp < props.auction.bids.length; tmp++) {
            if (props.auction.bids[tmp].value !== null) {
                if (props.auction.bids[tmp].id === award.bidId) {
                    let tmpCost = {
                        amount: props.auction.bids[tmp].initialValueAmount,
                        currency: "eurocent"
                    }
                    let tmpValue = viewStringValue(tmpCost)
                    return props.auction.bids[tmp].initialValueAmount ? <TextHalf
                        title='Розмір закритої цінової пропозиції'
                        data={tmpValue}
                    /> : null
                }
            }
        }

    }
    return null
}

//@todo use API dictionary
function viewTypeTerminationReason(props, data) {

    switch (data) {
        case '1':
            return "Подання неповного пакету документів або відомостей"
        case '2':
            return "Надання недостовірної інформації"
        case '3':
            return "Не розкриття інформації про кінцевих бенефіціарних власників"
        case '4':
            return "Поширення обмежувальних заходів"
        case '5':
            return "Перевищення обсягу річної квоти (25%)"
        case '6':
            return "Відмова від підписання протоколу"
        case '7':
            return "Відмова від підписання договору"
        default:
            return data
    }
}

function viewStatus(props, data) {
    if (data.status !== null && data.status !== '' && data.status !== 'null') {
        return (
            <TextHalf
                title='Статус'
                data={viewStatusAwards(props, data.status)}
            />
        )
    }
    return null
}

function viewStatusAwards(props, status) {
    switch (status) {
        case 'verification':
            return 'Перевірка документів'
        case 'waiting':
            return 'Документи перевірено'
        case 'pending':
            return 'Очікується протокол'
        case 'pending_waiting':
            return 'Очікується рішення'
        case 'active':
            return 'Переможець. Договір підписано'
        case 'unsuccessful':
            return 'Дискваліфіковано'
        case 'cancelled':
            return 'Учасник не став переможцем'
        case 'rejected':
            return 'Відхилено'
        case 'protocol_signed':
            return 'Підписано протокол'
        case 'pending_admission':
            return 'Підтвердження набуття статусу переможця'
        default:
            return status
    }

}

function getPeriodAsText(period) {
    return `${modifiDateString(period.startDate, 3)} - ${modifiDateString(period.endDate, 3)}`
}

function viewDocumentsBlock(props, award, count) {
    let bidDocuments = viewAwardBidDocuments(props, award)
    if ((award?.documents?.length > 0) || (bidDocuments?.length > 0)) {
        return <PopDown
            title={`Додані документи (${award?.documents?.length + bidDocuments?.length})`}
            key={award.id}
            content={
                <>
                    {award?.documents?.length > 0
                        ?
                        <>
                            <div className={styles.blockHeader}>Документи організатора:</div>
                            {viewAwardDocuments(award, count, props)}
                        </>
                        : null
                    }
                    {bidDocuments?.length > 0
                        ?
                        <>
                            <div className={styles.blockHeader}>Документи учасника:</div>
                            {bidDocuments}
                        </>
                        : null
                    }
                </>
            }
        />
    }

    return null
}

function viewAwardDocuments(award, countContract, props) { // документы организатора

    function dataECPMapper(award, t) {
        let tmp = award
            .filter(
                (item) => {
                    return item.documentType === 'digitalSignature' && item.relatedDocument === t.id
                })
        if (tmp.length === 0) return null
        return tmp
    }


    return award?.documents
        .map(
            (document) => {
                const getHistoryURL = (props, award) => {
                    if (document.documentOf === 'award') {
                        return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auction.id}/awards/${award.id}/documents`
                    }
                    if (document.documentOf === 'bid') {
                        return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auction.id}/bids/${award.bidId}/documents`
                    }
                }

                const docScopeData = {}
                docScopeData.aucId = props.auction.id
                docScopeData.aucStatus = props.auction.status
                docScopeData.aucType = props.auction.sellingMethod
                docScopeData.reload = () => {
                    if (!props?.auction?.privateData?.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
                    getUpdateDataAuction(props, props.auction.id)
                }
                docScopeData.aucOwner = props?.auction?.privateData?.isOwner
                docScopeData.historyUrl = getHistoryURL(props, award)


                docScopeData.bidOwner = procedure.isAwardOwner(props.auction, award)
                docScopeData.docTypesList = getAwardDocTypes(props.auction, award)
                docScopeData.awardStatus = award.status
                docScopeData.docList = award.documents

                if (props?.auction?.privateData?.isOwner) {
                    docScopeData.typeOf = 'award'
                    docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/award/${award.id}/documents`
                    docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/award/${award.id}/documents`
                    docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/award/${award.id}/documents`
                }

                return (
                    <DocItem
                        data={document}
                        scopeData={docScopeData}
                        dataECP={dataECPMapper(award.documents, document)}
                        key={document.id}
                    />
                )
            }
        )
}

function viewAwardBidDocuments(props, award) {
    function dataECPMapper(documents, t) {
        let result = documents
            .filter(
                (item) => {
                    return item.documentType === 'digitalSignature' && item.relatedDocument === t.documentId
                })
        if (result.length === 0) return null
        return result
    }

    const bid = procedure.getBidByAward(props.auction.bids, award)

    let documents = null
    documents = bid?.documents.filter(
        (document) => {
            return document.documentType !== 'auctionProtocol'// || document.documentType === 'x_guarantee';
        }
    )

    if (documents !== null) {
        return documents.map(
            (t) => {
                const docScopeData = {}
                docScopeData.aucId = props.auction.id
                docScopeData.aucStatus = props.auction.status
                docScopeData.aucType = props.auction.sellingMethod
                docScopeData.reload = () => {
                    if (!props?.auction?.privateData?.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
                    getUpdateDataAuction(props, props.auction.id)
                }
                docScopeData.aucOwner = props?.auction?.privateData?.isOwner

                docScopeData.bidOwner = procedure.isAwardOwner(props.auction, award)
                docScopeData.docTypesList = getAwardDocTypes(props.auction, award)
                docScopeData.awardStatus = award.status
                docScopeData.typeOf = 'bidAward'
                docScopeData.docList = award.documents

                function getDocId(id) {
                    for (let key in props?.auctionsAllBids?.arr?.documents) {
                        if (props.auctionsAllBids.arr.documents[key].documentId === id) return props.auctionsAllBids.arr.documents[key].id
                    }
                }

                let bid = procedure.getBidByAward(props.auction?.bids, award)
                docScopeData.historyUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auction.id}/bids/${award.bidId}/documents`
                if (props.auction?.privateData?.bids?.length > 0) {
                    docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${bid?.id}/documents/${getDocId(t.id)}`
                    docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${bid?.id}/documents/${getDocId(t.id)}`
                }
                return (
                    <>
                        <DocItem
                            data={t}
                            scopeData={docScopeData}
                            key={t.id}
                            dataECP={dataECPMapper(documents, t)}
                        />
                    </>
                )
            }
        )
    }
    return null
}

//////////////////////////////////////////
function viewUserActionButtonsBlock(props, award, count, popApproveDocuments, setPopApproveDocuments) {
    if (
        props.auction.status !== 'cancelled' &&
        props.auction.status !== 'unsuccessful' &&
        props.auction.status !== 'complete'
    ) {
        if (props.auction?.privateData?.hasBids) {
            return viewBidderActionButtonsBlock(props, award, count)
        }
        if (props.auction?.privateData?.isOwner) {
            return viewOwnerActionButtonsBlock(props, award, count, popApproveDocuments, setPopApproveDocuments)
        }
    }
    return null
}

function viewOwnerActionButtonsBlock(props, award, count, popApproveDocuments, setPopApproveDocuments) {
    let rejectionProtocolWasAdded = false,
        actWasAdded = false,
        protocolWasAdded = false

    for (let tt = 0; tt < award.documents.length; tt++) {
        if (award.documents[tt].documentType === 'rejectionProtocol') {
            rejectionProtocolWasAdded = true
        }
        if (award.documents[tt].documentType === 'act') {
            actWasAdded = true
        }
        if (award.documents[tt].documentType === 'auctionProtocol') {
            protocolWasAdded = true
        }
    }

    function showRejectionButton() {
        return (
            <div
                className={styles.btnAccept}
                onClick={
                    () => {
                        props.changeAwardRejectPopUpStatus({
                            aucId: props.auction.id,
                            awardId: award.id,
                            status: award.status,
                            awards: count,
                            sellingMethod: props.auction.sellingMethod
                        })
                    }
                }
            >
                Дії з учасником
            </div>
        )
    }

    function showApproveUserDocumentsButton(props, award) {
        return (
            <>
                {
                    popApproveDocuments
                        ?
                        <PopAsk
                            title={`Підтвердити перевірку документів?`}
                            actionYes={() => {
                                props.changeLoader(true)
                                const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/award/${award.id}/set_status/waiting`
                                fetch(endPoint, {
                                    method: "POST",
                                    headers: getDefaultHeaders(props)
                                }).catch(error => {
                                    props.changeLoader(false)
                                    setPopApproveDocuments(false)
                                    console.error('Api request error:', error);
                                    props.setUniversalError(JSON.stringify({
                                        title: 'Помилка',
                                        message: 'Під час підтвердження перевірки документів виникла помилка'
                                    }))
                                }).then(response => {
                                    if (response.status === 200) {
                                        getUpdateDataAuction(props, props.auction.id)
                                        props.changeLoader(false)
                                        setPopApproveDocuments(false)
                                        return
                                    }
                                    props.changeLoader(false)
                                    setPopApproveDocuments(false)
                                    console.error('Api request failed with code:', response.status, response);
                                    props.setUniversalError(JSON.stringify({
                                        title: 'Помилка',
                                        message: 'Під час підтвердження перевірки документів виникла помилка'
                                    }))
                                })
                                setPopApproveDocuments(false)
                            }}
                            actionNo={() => {
                                setPopApproveDocuments(false)
                            }}
                        />
                        : null
                }
                <div
                    className={styles.btnAccept}
                    onClick={
                        () => {
                            setPopApproveDocuments(true)
                        }
                    }>
                    'Підтвердити документи'
                </div>
            </>
        )
    }


    switch (award.status) {
        // case 'pending':
        //     if (!rejectionProtocolWasAdded && !actWasAdded) {
        //         return (
        //             <div className={styles.btnDecline}>
        //                 Дії з учасником
        //             </div>
        //         )
        //     }
        //NO BREAK HERE!
        // eslint-disable-next-line no-fallthrough
        case 'pending':
        case 'pending_waiting':
            if (!protocolWasAdded && !rejectionProtocolWasAdded && !actWasAdded) {
                return (
                    <div className={styles.btnDecline}>
                        Дії з учасником
                    </div>
                )
            }
            return showRejectionButton()
        case 'verification':
            return (
                <>
                    {(actWasAdded || rejectionProtocolWasAdded) ?
                        showRejectionButton()
                        : null
                    }
                    {showApproveUserDocumentsButton(props, award)}
                </>
            )
        case 'protocol_signed':
            return (
                <>
                    {actWasAdded ?
                        showRejectionButton()
                        : null
                    }
                </>
            )
        default:
            return (
                <div className={styles.btnDecline}>
                    Дії з учасником
                </div>
            )

    }
}

function viewBidderActionButtonsBlock(props, award, count) {
    if (procedure.isAwardOwner(props.auction, award) && award?.status === 'pending_admission') {
        return (
            <div
                className={styles.btnAccept}
                onClick={
                    () => {
                        props.changeAwardRejectPopUpStatus({
                            aucId: props.auction.id,
                            awardId: award.id,
                            status: award.status,
                            awards: count,
                            sellingMethod: props.auction.sellingMethod
                        })
                    }
                }
            >
                Відмовитись від участі
            </div>
        )
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionAwards: state.start.auctionAwards,
        allUserPopUp: state.start.allUserPopUp,
        allUserPopUpUser: state.start.allUserPopUpUser,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        timberContractPut: state.start.timberContractPut,
        pageNumber: state.start.pageNumber,
        auctionsGetId: state.start.auctionsGetId,
        auctionsAllBids: state.start.auctionsAllBids,
        auction: state.start.auctionFull,
        bidEdit: state.start.bidEdit,
        userProfiles: state.start.userProfiles
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,
    setAwardAllUserPopUp,
    setAwardAllUserPopUpUser,

    changeContractApprowePopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,

    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,
    setContractDatePaid,

    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
    setDataAddress,
    saveArrBids
}

export default connect(mapStateToProps, mapDispatchToProps)(AwardEditREM)
