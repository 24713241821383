import React, {useState, useEffect} from 'react';
import styles from './bankAccounts.module.css';

import { useSelector } from 'react-redux';
import BankItem from './bankItem';

const BankGroup = ( { data, bankAccounts, setBankAccounts, deleteBanks, setDelBankArr, disabledBtn} ) => {
    const [del] = useState(false);
    const [banksGroups, setBanksGroups] = useState(data)

    useSelector(state => state.start.auctionsMy);

    useEffect(() => {
        setBanksGroups(data)
    }, [bankAccounts]);

    let obj = {}
    obj.tmpText = 'Видалити групу'
    obj.tmpColorButton = 'primary'

    if(data.hasOwnProperty('holderType')){
        if(data.holderType === 'balanceHolder'){
        }else if(data.holderType === 'landlord'){
        }else if(data.holderType === 'budget'){
        }
    }

    let tmpAccountType = null
    if(data.accountType === 'registrationFee'){
        tmpAccountType = 'Рахунок для реєстраційного внеску'
    }else if(data.accountType === 'guarantee'){
        //tmpAccountType = 'Рахунок для гарантійного внеску'
        tmpAccountType = 'Гарантійний внесок'
    }else if(data.accountType === 'securityDeposit'){
        tmpAccountType = 'Рахунок для забезпечувального депозиту'
    }else if(data.accountType === 'improvementCompensation'){
        tmpAccountType = 'Рахунок для компенсації невід\'ємних поліпшень'
    }else if(data.accountType === 'other'){
        //tmpAccountType = 'Інші банківські рахунки'
        tmpAccountType = 'Інші платежі'
    }else if(data.accountType === 'advancePayment'){
        tmpAccountType = 'Рахунок для авансового внеску'
    }else if(data.accountType === 'lease'){
        tmpAccountType = 'Рахунок для оплати оренди'
    }else if(data.accountType === 'organizer'){
        tmpAccountType = 'Організатор'
    }else if(data.accountType === 'advancePayment'){
        tmpAccountType = 'Авансовий внесок'
    }else if(data.accountType === 'preparationPayment'){
        tmpAccountType = 'Плата за підготовку лота'
    }else if(data.accountType === 'payment'){
        tmpAccountType = 'Плата за лот'
    }

    function viewDeleteBanks(data, bankAccounts, setBankAccounts, type){ //банки которые есть уже
        if(data.length > 0){
            return data.map(
                (i) => { 
                    return <BankItem 
                                key={i.id}
                                data={i}
                                bankAccounts={bankAccounts}
                                setBankAccounts={setBankAccounts}
                                group={type}
                                disabledBtn={disabledBtn}
                                allDel={del}
                            />
                }
            )
        }
    }


    return (
        <div className={del ? `${styles.viewBankGroup} ${styles.bankDelete}` : styles.viewBankGroup}>
            <div className={styles.groupeBank}>
                <div className={styles.banksNameLine}>
                    {tmpAccountType}
                </div>
                {/* <button
                        className={del ? styles.btnGroupDeleteCancel : styles.btnGroupDelete}
                        title={obj.tmpText}
                        onClick={
                             () => {
                                let tmpBanks = bankAccounts
                                if(!del){
                                    setDel(!del)
                                    tmpBanks.forEach(e => { if (e.accountType === data.accountType){
                                        e.accounts.forEach( e => e.del = true)
                                        }
                                    });
                                    // setBankAccounts(null)
                                    setBankAccounts(tmpBanks)
                                } else {
                                    tmpBanks.forEach(e => { if (e.accountType === data.accountType){
                                        e.accounts.forEach( e => delete e.del)
                                        }
                                    });
                                    // setBankAccounts(null)
                                    setBankAccounts(tmpBanks)
                                    setDel(!del)
                                }
                            }
                        }
                    >{ del ?
                        <svg width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path  className={styles.btnCancelSVG} fillRule="evenodd" clipRule="evenodd" d="M22.8598 19.942C24.0009 18.2439 24.6667 16.1998 24.6667 14.0001C24.6667 8.10904 19.8911 3.33341 14 3.33341C11.7985 3.33341 9.75268 4.00039 8.05372 5.14329L22.8598 19.942ZM21.1038 21.9572L6.03936 6.90023C4.35631 8.78601 3.33335 11.2736 3.33335 14.0001C3.33335 19.8911 8.10898 24.6667 14 24.6667C16.7284 24.6667 19.2175 23.6424 21.1038 21.9572ZM14 0.666748C21.3638 0.666748 27.3334 6.63628 27.3334 14.0001C27.3334 21.3639 21.3638 27.3334 14 27.3334C6.63622 27.3334 0.666687 21.3639 0.666687 14.0001C0.666687 6.63628 6.63622 0.666748 14 0.666748Z" 
                                fill="#696974"
                            />
                        </svg> :
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className={styles.btnDeleteSVG} fillRule="evenodd" clipRule="evenodd" d="M5.87966 3.065L6.51813 1.14958C6.64744 0.761659 7.01047 0.5 7.41938 0.5H12.5494C12.9583 0.5 13.3213 0.761659 13.4506 1.14958L14.0891 3.065H18.5343C19.059 3.065 19.4843 3.49033 19.4843 4.015C19.4843 4.53967 19.059 4.965 18.5343 4.965H17.7201L17.0229 16.8174C16.9343 18.3238 15.6869 19.5 14.1779 19.5H5.7909C4.28189 19.5 3.03443 18.3238 2.94582 16.8174L2.24862 4.965H1.44951C0.924841 4.965 0.499512 4.53967 0.499512 4.015C0.499512 3.49033 0.924841 3.065 1.44951 3.065H5.87966ZM7.88243 3.065H12.0863L11.8647 2.4H8.1041L7.88243 3.065ZM15.8169 4.965H4.1519L4.84254 16.7058C4.87207 17.2079 5.28789 17.6 5.7909 17.6H14.1779C14.6809 17.6 15.0967 17.2079 15.1262 16.7058L15.8169 4.965ZM11.6012 7.37574C11.634 6.85209 12.085 6.45412 12.6086 6.48685C13.1323 6.51958 13.5303 6.97061 13.4975 7.49426L13.07 14.3343C13.0373 14.8579 12.5863 15.2559 12.0626 15.2232C11.539 15.1904 11.141 14.7394 11.1737 14.2157L11.6012 7.37574ZM8.79503 14.2157C8.82775 14.7394 8.42979 15.1904 7.90614 15.2232C7.38249 15.2559 6.93146 14.8579 6.89873 14.3343L6.47123 7.49426C6.4385 6.97061 6.83647 6.51958 7.36012 6.48685C7.88377 6.45412 8.3348 6.85209 8.36753 7.37574L8.79503 14.2157Z" fill="#FFA047"/>
                        </svg>
                    }
                </button> */}
            </div>
            {viewDeleteBanks(data.accounts, bankAccounts, setBankAccounts, data.accountType, deleteBanks, setDelBankArr ) }
        </div>
    )


}

export default BankGroup