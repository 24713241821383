import React,{useState} from 'react';
import styles from '../newContract.module.css';

import {connect} from 'react-redux';

import { checkProp } from '../../../../redux/check';
import { isTIE, isSUE, isSUD, isGFE, isGFD, isLLE, isLLD,
            isLLP, isLRE, isLSE, isLSP, isBSE, isBSD, isALE, isBRE, isBRD, isBRW, isRLE, isRLD, isCLE, isCLD, isSPE, isSPD,
            isLAE, isLAW, isLAP, isLPE
        } from '../../../../redux/procedure';
    
import { changeAwardRejectPopUpStatus, } from '../../../../redux/actions/awards/awards.js';

import { setAwardAllUserPopUp, setAwardAllUserPopUpUser } from '../../../../redux/actions.js';
import { getUpdateDataAuction } from '../../updateData';
import { setDataAddress } from '../../../../redux/createAddress';

import {
    changeContractApprowePopUpStatus,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
} from '../../../../redux/actions/contracts/contracts.js';

import TextHalf from '../../../elements/viewData/textHalf'
import TextFull from '../../../elements/viewData/textFull'
import PopDown from '../../../elements/viewData/popdownBlock/popDownBlock'
import DocItem from '../../../documents/docItem';
import AddDocPopup from '../../../documents/addDocPopup/addDocPopup';

import {
    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,
} from '../../../../redux/history.js';
import {setUniversalError, changeLoader} from '../../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../../redux/actions/contracts/contracts.js';

import ContractComponentsDate from '../contractComponents/contractComponentsDate/contractComponentsDate'

import {modifiDateString} from '../../../../redux/modifiDate'

import InputDate from '../../../elements/inputs/inputDate';

import {viewStringValue} from '../../../../api/valueToString.js'

const ContractLAE = (props) => {
    const [slim, setSlim] = useState({ state: false, text: 'Розгорнути' })
    const [popAddDoc, setPopAddDoc] = useState(false)
    
    function Slimmer() {
        slim.state ? setSlim({ state: false, text: 'Розгорнути'}) : setSlim({ state: true, text: 'Згорнути'})
    }

    

    const docScopeData = {}
    docScopeData.typeOf = 'contract'
    if(props?.userProfiles?.isAdmin === true){
        docScopeData.aucOwner = true
    }else{
        docScopeData.aucOwner = props.auctionId.privateData.isOwner
    }
    
    docScopeData.reload = () => { getUpdateDataAuction(props, props.auctionId.id) }
    docScopeData.aucId = props.auctionId.id
    docScopeData.aucStatus = props.auctionId.status
    docScopeData.aucType = props.auctionId.sellingMethod
    if(props.auctionId.contracts !== null && props.auctionId.contracts.length > 0){
        docScopeData.docList = props.auctionId.contracts[0].documents
    }
    // docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${props.auctionId.contracts[0].id}/documents`
    // docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${props.auctionId.contracts[0].id}/documents`
    // docScopeData.deleteURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.elemBid.id}/documents`
    // docScopeData.historyUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/contracts/${props.auctionId.contracts[0].id}/documents`

    let tmp = null;
    if(props.auctionId !== null){
        if(props.auctionId === undefined || props.auctionId === 'undefined'){
        }else{
            if(
                props.auctionId.status === 'active_auction' ||
                props.auctionId.status === 'active_awarded' ||
                props.auctionId.status === 'active_qualification' ||
                props.auctionId.status === 'pending_payment' ||
                props.auctionId.status === 'pending_admission' ||
                props.auctionId.status === 'cancelled' ||
                props.auctionId.status === 'complete' ||
                props.auctionId.status === 'unsuccessful'
            ){
                if(props.auctionId.contracts !== null){
                    tmp = (
                        <div className={styles.contractBlock}>
                            <div className={styles.contractBlock_title}>Кваліфікація учасників акту/договору</div>
                            <div className={styles.slimmer} onClick={() => Slimmer()}>{slim.text}</div>
                            {slim.state ?
                                <div className={styles.contractBlockContent}>
                                    {viewDataContract(props, docScopeData, setPopAddDoc, popAddDoc)}
                                </div> : null
                            }
                        </div>
                    )
                }
            }
        }
    }
    return tmp
}

function viewDataContract(props, docScopeData, setPopAddDoc, popAddDoc){
    if(checkProp(props, 'auctionId')){
        //if(props.auctionsGetId.status === 'complete'){
            if(props.auctionId !== null){
                return props.auctionId.contracts.map(
                    (i, count) => {
                        let tmpValueContracts = null
                        if(i.value !== null){
                            tmpValueContracts = <div>{viewStringValue(i.value)}</div>
                        }else{
                            tmpValueContracts = <div></div>
                        }
                        
                        let startDate = null
                        let endDate = null
                        let signedDate = null
                        let prepaynentDate = null
                        let x_prepaymentDetails = null
                        
                        if(i.x_prepaymentDetails !== null){
                            x_prepaymentDetails = modifiDateString(i.x_prepaymentDetails.date, 3, false)
                        }
                        let tmpX_prepaymentDetails = null
                        let tmpX_prepaymentDetailsAmount = null
                        if(x_prepaymentDetails !== null){
                            tmpX_prepaymentDetails = <TextHalf
                                title='Дата передоплати'
                                data={x_prepaymentDetails}
                            />
                            tmpX_prepaymentDetailsAmount = <TextHalf
                                title='Розмір передоплати в грн.'
                                data={viewStringValue(i.x_prepaymentDetails.prepayment)}
                            />
                        }
                        if(i.contractTime !== null){
                            if(i.contractTime.dateFrom !== null){
                                //startDate = dataDayCreate(i.contractTime.startDate)
                                startDate = i.contractTime.dateFrom
                            }
                            if(i.contractTime.dateTill !== null){
                                //endDate = dataDayCreate(i.contractTime.endDate)
                                endDate = i.contractTime.dateTill
                            }
                        }
                        if(i.dateSigned !== null){
                            signedDate = modifiDateString(i.dateSigned, 3, false)
                        }
                        if(i.datePaid !== null){
                            prepaynentDate = modifiDateString(i.datePaid, 3, false)
                        }

                        if(props.auctionId !== null){
                            if (
                                isSUE(props.auctionId.sellingMethod) ||
                                isSUD(props.auctionId.sellingMethod)
                            ){
                                statusContracts(props, i.status)
                            }
                        }
                        let tmpDataPaid
                        let tmpEndValueContracts = null
                        if(
                            isGFE(props.auctionId.sellingMethod) || isGFD(props.auctionId.sellingMethod)
                        ){
                            tmpDataPaid = <TextHalf
                                                title='Дата сплати коштів за лот'
                                                data={prepaynentDate}
                                            />
                            if(i.contractTotalValue !== null){
                                tmpEndValueContracts = <TextHalf
                                                            title='Заключна вартість акту/договору'
                                                            data={viewStringValue(i.contractTotalValue)}
                                                        />
                            }
                        } else if(
                            isBSE(props.auctionId.sellingMethod) ||
                            isBSD(props.auctionId.sellingMethod) ||
                            isALE(props.auctionId.sellingMethod) ||
                            isTIE(props.auctionId.sellingMethod) ||
                            isBRE(props.auctionId.sellingMethod) ||
                            isBRD(props.auctionId.sellingMethod) ||
                            isBRW(props.auctionId.sellingMethod) ||
                            isSPE(props.auctionId.sellingMethod) ||
                            isSPD(props.auctionId.sellingMethod) ||
                            isLPE(props.auctionId.sellingMethod)
                        ){
                            tmpDataPaid = null
                            if(i.contractTotalValue !== null){
                                if(i.contractTotalValue.amount !== null){
                                    tmpEndValueContracts = <TextHalf
                                                        title='Заключна вартість акту/договору'
                                                        data={viewStringValue(i.contractTotalValue)}
                                                    />
                                }
                            }
                        } else if (
                            isLLE(props.auctionId.sellingMethod) ||
                            isLLD(props.auctionId.sellingMethod) ||
                            isLLP(props.auctionId.sellingMethod) ||
                            isRLE(props.auctionId.sellingMethod) ||
                            isRLD(props.auctionId.sellingMethod) ||
                            isCLE(props.auctionId.sellingMethod) ||
                            isCLD(props.auctionId.sellingMethod)
                        ){
                            tmpDataPaid = null
                            if(i.contractTotalValue !== null){
                                if(i.contractTotalValue.amount !== null){
                                    tmpEndValueContracts = <TextHalf
                                                        title='Заключна вартість акту/договору з урахуванням податків'
                                                        data={viewStringValue(i.contractTotalValue)}
                                                    />
                                }
                            }
                        } else if (
                            isLRE(props.auctionId.sellingMethod) ||
                            isLSE(props.auctionId.sellingMethod) ||
                            isLSP(props.auctionId.sellingMethod) ||
                            isLAE(props.auctionId.sellingMethod) ||
                            isLAW(props.auctionId.sellingMethod) ||
                            isLAP(props.auctionId.sellingMethod)
                        ){
                            if(i.x_prepaymentDetailsDate !== null) prepaynentDate = modifiDateString(i.x_prepaymentDetailsDate, 3, false)
                            if(prepaynentDate !== null){
                                /***tmpDataPaid = <TextHalf
                                    title='Дата передплати'
                                    data={prepaynentDate}
                                />*/
                                tmpDataPaid = null
                            }else{
                                tmpDataPaid = null
                            }
                        }else{
                            if(prepaynentDate){
                                tmpDataPaid = <TextHalf
                                                title='Дата сплати до державного бюджету України'
                                                data={prepaynentDate}
                                            />
                            }else{
                                tmpDataPaid = null
                            }
                        }

                        if (
                            isLAE(props.auctionId.sellingMethod) ||
                            isLAP(props.auctionId.sellingMethod)
                        ){
                            tmpDataPaid = null
                            if(i.contractTotalValue !== null){
                                if(i.contractTotalValue.amount !== null){
                                    tmpEndValueContracts = <TextHalf
                                                        title='Заключна вартість акту/договору з урахуванням податків'
                                                        data={`${viewStringValue(i.contractTotalValue)}, з ПДВ`}
                                                    />
                                }
                            }
                        }
                        

                        let tmpStartDate = null
                        if(startDate !== null){
                            tmpStartDate = <ContractComponentsDate 
                                            dateText='Дата початку дії акту/договору'
                                            dateData={startDate}
                                        />
                        }
                        let tmpEndDate = null
                        if(endDate !== null){
                            tmpEndDate = <ContractComponentsDate 
                                dateText='Дата завершення дії акту/договору'
                                dateData={endDate}
                            />
                        }
                        let dateCreateContract = null
                        if(i.datePublished !== null) dateCreateContract = modifiDateString(i.datePublished, 3, false)
                        let dateModifContract = null
                        if(i.dateModified !== null) dateModifContract = modifiDateString(i.dateModified, 3, false)
                        return (<>
                                {
                                    popAddDoc ?
                                        <AddDocPopup
                                            scopeData={docScopeData}
                                            actionClose={() => {
                                                setPopAddDoc(false)
                                            }}
                                            actionNo={() => {
                                                setPopAddDoc(false)
                                            }}
                                           />
                                    : null
                                }
                                <div className={styles.contractBlock}>
                                    {viewName(i.buyers[0])}
                                    {viewContractNumber(i.contractNumber)}
                                    {viewTitle(i.title)}
                                    {viewDescription(i.description)}
                                    {viewStatus(props, i.status)}
                                    {tmpValueContracts ? <TextHalf
                                        title='Вартість акту/договору'
                                        data={tmpValueContracts}
                                    /> : null}
                                    {tmpEndValueContracts}
                                    {dateCreateContract !== null ? <TextHalf
                                        title="Дата створення об'єкта акту/договору"
                                        data={dateCreateContract}
                                    /> : null}
                                    {dateModifContract !== null ? <TextHalf
                                        title="Остання дата редагування акту/договору"
                                        data={dateModifContract}
                                    /> : null}

                                    {tmpStartDate}
                                    {tmpEndDate}
                                    {signedDate ? <TextHalf
                                        title='Дата підписання акту/договору'
                                        data={signedDate}
                                    /> : null}
                                    {tmpX_prepaymentDetails}
                                    {tmpX_prepaymentDetailsAmount}
                                    {tmpDataPaid}
                                    {(isSPE(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod) || isLPE(props.auctionId.sellingMethod)) && i.preliminaryContract !== null ? <div className={styles.contractBlock}>
                                        {i.preliminaryContract !== null ? viewPreliminaryContract(props, i) : null}
                                    </div> : null}
                                    {i.securityPaymentConfirmation !== null
                                        ? <TextHalf
                                            title='Підтвердження сплати забезпечувального платежу'
                                            data={i.securityPaymentConfirmation ? "Оплату підтверджено" : "Оплату не підтверджено"}
                                        />
                                        : null
                                    }
                                    {i.lotPaymentConfirmation !== null
                                        ? <TextHalf
                                            title='Підтвердження сплати за лот'
                                            data={i.lotPaymentConfirmation ? "Оплату підтверджено" : "Оплату не підтверджено"}
                                        />
                                        : null
                                    }
                                    {(isLRE(props.auctionId.sellingMethod) || isLSE(props.auctionId.sellingMethod) || isLSP(props.auctionId.sellingMethod)
                                    || isLAE(props.auctionId.sellingMethod) || isLAW(props.auctionId.sellingMethod) || isLAP(props.auctionId.sellingMethod)) && i.lotPaymentConfirmation === false ? <div className={styles.contractBlock}>
                                        {i.lotPaymentConfirmation === false ? viewPreliminaryContractLSP(props, i) : null}
                                    </div> : null}
                                    <div className={styles.btnBlock}>
                                        {buttonContractPopUp(props, i, count, docScopeData, setPopAddDoc, popAddDoc)}
                                    </div>
                                    {viewDateSUESUD(props, {
                                                        aucId: props.auctionId.id,
                                                        contractId: i.id,
                                                        sellingMethod: props.auctionId.sellingMethod,
                                                        count: count,
                                                        fullAuction: props.auctionId,
                                                        typeView: 2,
                                                    })}
                                    {viewContractDocuments(props, i, count, docScopeData, setPopAddDoc)}
                                </div>
                            </>
                        )
                    }
                )
            }
        //}
    }
}

function viewPreliminaryContractLSP(props, i){
    return (
        !i.lotPaymentConfirmation
        ? <TextFull
            title='Важлива інформація'
            data={`Обраний вами варіант підтверджує, що оплата за лот не здійснена учасником ${i.buyers[0].name.uk_UA}`}
        /> : null
    )
}

function viewPreliminaryContract(props, i){
    if(isSPE(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod) || isLPE(props.auctionId.sellingMethod)){
        return (
            <>
                <TextHalf
                    title='Номер попереднього договору'
                    data={i.preliminaryContract.contractNumber}
                />
                <TextHalf
                    title='Назва попереднього договору'
                    data={i.preliminaryContract.title !== null ? i.preliminaryContract.title.uk_UA : ''}
                />
                <TextHalf
                    title='Опис попереднього договору'
                    data={i.preliminaryContract.description !== null ? i.preliminaryContract.description.uk_UA : ''}
                />
                <TextHalf
                    title="Дата підписання попереднього договору"
                    data={modifiDateString(i.preliminaryContract.dateSigned, 3, false)}
                />
            </>
        )
    }
    return null
}

function buttonContractPopUp(props, i, count, docScopeData, setPopAddDoc, popAddDoc){
    if(checkProp(props, 'auctionId') && checkProp(props.auctionId, 'privateData')){
        if(props.auctionId.privateData.isOwner === true || props?.userProfiles?.isAdmin === true){
            let tmp = null
            if(
                (props.auctionId.status === 'active_awarded'
                || props.auctionId.status === 'pending_payment')
                //&& i.status === 'pending'
                //&& i.status === 'signed'
                && i.status !== 'cancelled'
                //&& (i.securityPaymentConfirmation === null || i.securityPaymentConfirmation === false)
                //&& flag
            ){
                tmp = LREButton(props, {
                    aucId: props.auctionId.id,
                    contractId: i.id,
                    sellingMethod: props.auctionId.sellingMethod,
                    count: count,
                    fullAuction: props.auctionId,
                    typeView: 2,
                })
            }
            return (
                <>
                    {viewApproveContractDoc(props, i, count, 1, docScopeData, setPopAddDoc, popAddDoc)}
                    {tmp}
                    {props?.auctionId?.privateData?.isOwner === true ? viewApproveContractData(props, i, count, 2) : null}
                    {props?.auctionId?.privateData?.isOwner === true
                        ? viewApproveContract(
                            props,
                            {
                                aucId: props.auctionId.id,
                                contractId: i.id,
                                sellingMethod: props.auctionId.sellingMethod,
                                count: count,
                                fullAuction: props.auctionId,
                                typeView: 2,
                            }
                        )
                        : null
                    }
                </>
            )
        }
    }
    return null
}

//25.11.22 Добить логику появления кнопки

function viewApproveContractDoc(props, data, count, type, docScopeData, setPopAddDoc, popAddDoc) {
    if(props.auctionId.contracts !== null){
        if(props.auctionId.contracts.length > 0){
            if(data.status === 'pending' || data.status === 'signed'){
                if
                    // (data.lotPaymentConfirmation === null) //TODO: recomment
                    (!data.lotPaymentConfirmation)
                {
                    docScopeData.docTypesList = listContractDoc(props, data)
                    docScopeData.contractId = data.id
                    docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${data.id}/documents`
                     return (
                            <>
                                {
                                    popAddDoc ?
                                        <AddDocPopup
                                            scopeData={docScopeData}
                                            actionClose={() => {
                                                setPopAddDoc(false)
                                            }}
                                            actionNo={() => {
                                                setPopAddDoc(false)
                                            }}
                                           />
                                    : null
                                }
                            <div
                                className={styles.btnAccept}
                                onClick={
                                    () => {
                                        if(checkProp(data, 'title')){
                                            if(props.timberContractPut.title === '') props.setContractTitle(data.title)
                                        }
                                        if(checkProp(data, 'description')){
                                            if(props.timberContractPut.description === '') props.setContractDescription(data.description)
                                        }
                                        if(checkProp(data, 'contractNumber')){
                                            if(props.timberContractPut.contractNumber === '') props.setContractContractNumber(data.contractNumber)
                                        }
                                        if(checkProp(data.value, 'amount')){
                                            if(props.timberContractPut.xValueUAH.amount === null) props.setContractAmount(data.value.amount)
                                        }
                                        if(checkProp(data, 'dateSigned')){
                                            if(props.timberContractPut.dateSigned === '') {
                                                let tmp = data.dateSigned.split('+')
                                                props.setContractDateSigned(tmp[0])
                                            }
                                        }
                                        if(checkProp(data, 'x_prepaymentDetailsDate')){
                                            if(props.timberContractPut.contractTime.dateFrom === '') {
                                                let tmp = data.x_prepaymentDetailsDate.split('+')
                                                props.setContractDateFrom(tmp[0])
                                            }
                                        }
                                        if(checkProp(data, 'contractTime')){
                                            if(checkProp(data.contractTime, 'dateTill')){
                                                if(props.timberContractPut.contractTime.dateTill === '') {
                                                    let tmp = data.contractTime.dateTill.split('+')
                                                    props.setContractEndDate(tmp[0])
                                                }
                                            }
                                            if(checkProp(data.contractTime, 'dateFrom')){
                                                if(props.timberContractPut.contractTime.dateFrom === '') {
                                                    let tmp = data.contractTime.dateFrom.split('+')
                                                    props.setContractStartDate(tmp[0])
                                                }
                                            }
                                        }
                                        setPopAddDoc(true)
                                    }
                                }
                            >
                                Документи акту/договору
                            </div>
                        </>
                    )
                }
            }
        }
    }
    return (
        <div className={styles.btnToList}>
            Документи акту/договору
        </div>
    )
}

function viewApproveContractData(props, data, count, type){
    if(props.auctionId !== null){
        if(props.auctionId.contracts.length > 0){
            if(props.auctionId.contracts[count].status === 'pending'/* || props.contractAuctionIdGet[count].status === 'signed'*/){
                return (
                    <div
                        className={styles.btnAccept}
                        onClick={
                            () => {
                                if(data.title !== null){
                                    if(props.timberContractPut.title === '' && props.timberContractPut.title !== null) props.setContractTitle(data.title.uk_UA)
                                }
                                if(data.description !== null){
                                    if(props.timberContractPut.description === '') props.setContractDescription(data.description.uk_UA)
                                }
                                if(data.contractNumber !== null){
                                    if(props.timberContractPut.contractNumber === '') props.setContractContractNumber(data.contractNumber)
                                }
                                if(data.value !== null){
                                    props.setDataAddress(data.value.currency, 'SET_CONTRACT_VALUE_CURRENCY')
                                    props.setDataAddress(data.value.amount, 'SET_CONTRACT_VALUE_AMOUNT')
                                    props.setDataAddress(data.value.valueAddedTaxIncluded, 'SET_CONTRACT_VALUE_TAX')
                                }
                                if(
                                    isBRE(props.auctionId.sellingMethod) ||
                                    isBRD(props.auctionId.sellingMethod) ||
                                    isBRW(props.auctionId.sellingMethod)
                                ){
                                    if(data.contractTotalValue !== null){
                                        props.setDataAddress(data.contractTotalValue.currency, 'SET_CONTRACT_CURRENCY')
                                        props.setDataAddress(data.contractTotalValue.amount, 'SET_CONTRACT_AMOUNT')
                                        props.setDataAddress(data.contractTotalValue.valueAddedTaxIncluded, 'SET_CONTRACT_TAX')
                                    }
                                }
                                if(data.dateSigned !== null){
                                    if(props.timberContractPut.dateSigned === '') {
                                        let tmp = data.dateSigned.split('+')
                                        props.setContractDateSigned(tmp[0])
                                    }
                                }
                                if(data.x_prepaymentDetailsDate !== null){
                                    if(props.timberContractPut.contractTime.dateFrom === '') {
                                        let tmp = data.x_prepaymentDetailsDate.split('+')
                                        props.setContractDateFrom(tmp[0])
                                    }
                                }
                                if(data.contractTime !== null){
                                    if(data.contractTime.hasOwnProperty('dateTill') && data.contractTime.dateTill !== null){
                                        if(props.timberContractPut.contractTime.dateTill === '') {
                                            let tmp = data.contractTime.dateTill.split('+')
                                            props.setContractEndDate(tmp[0])
                                        }
                                    }
                                    if(data.contractTime.hasOwnProperty('dateFrom') && data.contractTime.dateFrom !== null){
                                        if(props.timberContractPut.contractTime.dateFrom === '') {
                                            let tmp = data.contractTime.dateFrom.split('+')
                                            props.setContractStartDate(tmp[0])
                                        }
                                    }
                                }
                                if(data.datePaid !== null){
                                    if(props.timberContractPut.datePaid === '') {
                                        let tmp = data.datePaid.split('+')
                                        props.setContractDatePaid(tmp[0])
                                    }
                                }
                                props.changeContractApprowePopUpStatus({
                                    aucId: props.auctionId.id,
                                    contractId: data.id,
                                    sellingMethod: props.auctionId.sellingMethod,
                                    count: count,
                                    fullAuction: props.auctionId,
                                    typeView: type,
                                    typePopUp: 'contracts'
                                })
                            }
                        }
                    >
                        Дані акту/договору
                    </div>
                )
            }
        }
    }
    return <div className={styles.btnToList}>
                Дані акту/договору
            </div>
}

function viewApproveContract(props, data){
    if(props.auctionId !== null){
        if(props.auctionId.contracts.length > 0){
            if(props.auctionId.contracts[data.count].status === 'signed' && props.auctionId.contracts[data.count].lotPaymentConfirmation === true){
                let tmpMainDoc = false
                if(props.auctionId.contracts[data.count].documents !== null){
                    if(props.auctionId.contracts[data.count].documents.length > 0){
                        for(let t = 0; t < props.auctionId.contracts[data.count].documents.length; t++){
                            if(props.auctionId.contracts[data.count].documents[t].documentType === 'contractSigned'){
                                //if(props.contractAuctionIdGet[data.count].documents[t].createdByCurrentUser === true){
                                    //tmpMainDoc = true/////++///
                                //}
                                if(props.auctionId !== null){
                                    if(props.auctionId.privateData.isOwner === true){
                                        tmpMainDoc = true
                                    }
                                }
                            }
                        }
                    }
                }
                if(
                    props.auctionId.contracts[data.count].title !== null &&
                    props.auctionId.contracts[data.count].title !== '' &&
                    props.auctionId.contracts[data.count].contractNumber !== null &&
                    props.auctionId.contracts[data.count].contractNumber !== '' &&
                    props.auctionId.contracts[data.count].dateSigned !== null &&
                    props.auctionId.contracts[data.count].dateSigned !== ''&&
                    tmpMainDoc === true
                ){
                    return (
                        <div
                            className={styles.btnAccept}
                            onClick={
                                () => {
                                    //promisApproveContract(props, data)
                                    promisApproveContractLR(props, data)
                                }
                            }
                        >
                            {nameButtonAppruveContract(props)}
                        </div>
                    )
                }
            }
        }
    }
    return (
        <div className={styles.btnToList}>
            {nameButtonAppruveContract(props)}
        </div>
    )
}

function LREButton(props, data){
    if(props.auctionId !== null){
        if(props.auctionId.contracts.length > 0){
            if(props.auctionId.contracts[data.count].status === 'pending' || props.auctionId.contracts[data.count].status === 'signed'){
                if(
                    //props.auctionId.contracts[data.count].lotPaymentConfirmation === null &&
                    props.auctionId.contracts[data.count].status !== 'cancelled'
                ){
                    return (
                        <>
                            {props.auctionId.contracts[data.count].securityPaymentConfirmation !== true
                            && props?.userProfiles?.isAdmin === true
                                ? <div className={styles.buttonsBlockYesNo}>
                                    <div className={styles.titleTextButtons}>Підтвердження сплати забезпечувального платежу</div>
                                    <div className={styles.buttonsBlokFlex}>
                                        <div className={styles.btnAccept}
                                            onClick={
                                                () => {
                                                    promisContractLRUpdate(props, data, true)
                                                }
                                            }
                                        >
                                            Так
                                        </div>
                                        <div className={styles.btnDecline}
                                            onClick={
                                                () => {
                                                    promisContractLRUpdate(props, data, false)
                                                }
                                            }
                                        >
                                            Ні
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            {props?.auctionId?.privateData?.isOwner === true  &&
                                props.auctionId.contracts[data.count].status === 'pending' &&
                                props.auctionId.contracts[data.count].title !== null &&
                                props.auctionId.contracts[data.count].title !== '' &&
                                props.auctionId.contracts[data.count].contractNumber !== null &&
                                props.auctionId.contracts[data.count].contractNumber !== '' &&
                                props.auctionId.contracts[data.count].dateSigned !== null &&
                                props.auctionId.contracts[data.count].dateSigned !== ''// &&
                                //tmpMainDoc === true
                                //&& props.auctionId.contracts[data.count].lotPaymentConfirmation
                                ? <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            //promisApproveContractLR(props, data, true)
                                            promisApproveContract(props, data)
                                        }
                                    }
                                >
                                    Підписати договір
                                </div>
                                : props?.auctionId?.privateData?.isOwner === true
                                    && props.auctionId.contracts[data.count].status === 'pending' 
                                        ? <div>
                                            <div className={styles.btnToList}>Підписати договір</div>
                                            {props.auctionId.contracts[data.count].securityPaymentConfirmation !== true ? <div style={{fontSize: "12px", color: "#8b0000", textAlign: "center"}}>Забезпечувальний платіж не сплачено. Без підтвердження сплати забезпечувального платежу неможливе завершення роботи з актом/договором.</div> : null}
                                        </div>
                                        : null
                            }
                            {props?.auctionId?.privateData?.isOwner === true
                            && props.auctionId.contracts[data.count].status === 'signed'
                                ? <div className={styles.buttonsBlockYesNo}>
                                    <div className={styles.titleTextButtons}>Підтвердження сплати за лот</div>
                                    <div className={styles.buttonsBlokFlex}>
                                        <div className={styles.btnAccept}
                                            onClick={
                                                () => {
                                                    promisContractLRUpdateLot(props, data, true)
                                                }
                                            }
                                        >
                                            Так
                                        </div>
                                        <div className={styles.btnDecline}
                                            onClick={
                                                () => {
                                                    promisContractLRUpdateLot(props, data, false)
                                                }
                                            }
                                        >
                                            Ні
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            
                        </>
                    )
                }
            }
        }
    }
    return null
}

function listContractDoc(props, data) {
    if(props?.userProfiles?.isAdmin === true){
        return [
            { value: 'securityPayment', name: 'Підтвердження сплати забезпечувального платежу' },
        ]
    }else if(props?.auctionId?.privateData?.isOwner === true && data.status !== 'signed'){
        return [
            { value: 'contractSigned', name: 'Підписаний акт/договір' },
            { value: 'contractAnnexe', name: 'Додатки до акту/договору' }
        ]
    }else if(props?.auctionId?.privateData?.isOwner === true && data.status === 'signed'){
        return [
            { value: 'contractSigned', name: 'Підписаний акт/договір' },
            { value: 'contractAnnexe', name: 'Додатки до акту/договору' },
            { value: 'paymentInformation', name: 'Відомості про сплату' }
        ]
    }
}
function viewDateSUESUD(props, data){
    if (props.auctionId.contracts[data.count].status === 'signed') {
        if(props.auctionId.privateData.isOwner === true){
            if (
                props.auctionId.sellingMethod === "subsoil-english" ||
                props.auctionId.sellingMethod === "subsoil-english-fast" ||
                props.auctionId.sellingMethod === "subsoil-english-fast-manual" ||
                props.auctionId.sellingMethod === "subsoil-english-initial-auction" ||
                props.auctionId.sellingMethod === "subsoil-english-initial-qualification" ||
                props.auctionId.sellingMethod === "subsoil-dutch" ||
                props.auctionId.sellingMethod === "subsoil-dutch-fast" ||
                props.auctionId.sellingMethod === "subsoil-dutch-fast-manual" ||
                props.auctionId.sellingMethod === "subsoil-dutch-initial-auction" ||
                props.auctionId.sellingMethod === "subsoil-dutch-initial-qualification" ||
                props.auctionId.sellingMethod === "subsoil-dutch-initial-auction-manual" ||
                props.auctionId.sellingMethod === "subsoil-english-fast-auction-manual-qualification"
            ) {
                let dataContractTimeStartDate = {
                    targetType: 'SET_CONTRACT_DATE_PAID',
                    target: props.timberContractPut.datePaid,
                    typePopUp: 'contracts',
                    functions: props.setContractDatePaid,
                    label: `Кошти до бюджету сплачено`,
                    necessarily: true,
                    time: true,
                    desc: 'Ведіть дату внесення коштів'
                }

                const PaidButton = () => {
                    if (props.timberContractPut.datePaid !== null && props.timberContractPut.datePaid !== '') {
                        return <div className={styles.btnPaid}
                            onClick={
                                () => {
                                    //promisApproveContract(props, data)
                                    updateContractDatePaid(props, data)
                                }
                            }
                        >
                            {nameButtonAppruveContract(props, data)}
                        </div>
                    }
                    return <div className={styles.btnPaidInact}>
                            {nameButtonAppruveContract(props, data)}
                        </div>
                    
                }

                return (
                    <div className={styles.btnBlockDate}>
                        <InputDate 
                            data={dataContractTimeStartDate}
                            onChange={ e => props.setContractDatePaid(e)}
                        />
                        {PaidButton()}
                    </div>
                )
            }
        }
    }
    return null
}

function nameButtonAppruveContract(){
    return 'Підтвердити договір/акт'
}

async function updateContractDatePaid(props, data){
    await props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${props.auctionId.contracts[data.count].id}`
    const response = await fetch(endPoint, {
        method: "PATCH",
        body: JSON.stringify({
            'datePaid': props.timberContractPut.datePaid
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }

    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка оновлення данних контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{
            //Добавить очистку полей
            await postEndingContract(props, data)


            await setTimeout(() => syncPromisContract(props), 2000)
        }
    }
}

async function postEndingContract(props, data){
    await props.changeLoader(true)
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${props.auctionId.contracts[data.count].id}/approve/set_status/paid`
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${props.auctionId.contracts[data.count].id}/set_status/paid`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }

    }).catch(console.error)
    if(response === undefined || response === 'undefined'){

    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка підпису контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{


            await setTimeout(() => syncPromisContract(props), 2000)
        }
    }    
}

/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
async function promisContractLRUpdate(props, data, status){
    props.changeLoader(true)
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${data.aucId}/contract/${data.contractId}/confirm_security_payment?securityPaymentConfirmation=${status}`
    

    let body = {}
    body.lotPaymentConfirmation = status
    const response = await fetch(endPoint, {
        method: 'PATCH',
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при затвердженні контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            await setTimeout(() => syncPromisContract(props), 2000)
        }
    }
    await props.changeLoader(false)
}
async function promisContractLRUpdateLot(props, data, status){
    props.changeLoader(true)
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${data.aucId}/contract/${data.contractId}/confirm_payment?lotPaymentConfirmation=${status}`
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${data.aucId}/contract/${data.contractId}`
    //let method = null
    //let body = {}
    //body.lotPaymentConfirmation = status
    const response = await fetch(endPoint, {
        method: 'PATCH',
        //body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при затвердженні контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            await setTimeout(() => syncPromisContract(props), 2000)
        }
    }
    await props.changeLoader(false)
}

async function promisApproveContract(props, data){
    props.changeLoader(true)
    let endPoint
    if (
        isSUE(props.auctionId.sellingMethod) || isSUD(props.auctionId.sellingMethod) ||
        isLRE(props.auctionId.sellingMethod) || isLSE(props.auctionId.sellingMethod) ||
        isLSP(props.auctionId.sellingMethod) ||
        isLAE(props.auctionId.sellingMethod) || isLAW(props.auctionId.sellingMethod) || isLAP(props.auctionId.sellingMethod)
    ){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${data.aucId}/contract/${data.contractId}/set_status/signed`
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${data.aucId}/contract/${data.contractId}/set_status/active`
    }
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при затвердженні контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            await props.changeLoader(false)
            await setTimeout(() => syncPromisContract(props), 2000)
        }
    }
    await props.changeLoader(false)
}

async function syncPromisContract(props){


    await props.changeLoader(true)
    //await baseSyncAuc(props, data)
    //await props.contractsAuctionIdGet(data1, headers1, data.aucId)
    await getUpdateDataAuction(props, props.auctionId.id)
    await props.changeLoader(false)
}
/////////////////////////////////////////////////////////////////////

async function promisApproveContractLR(props, data){
    props.changeLoader(true)
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${data.aucId}/contract/${data.contractId}/set_status/active`
    const response = await fetch(endPoint, {
        method: 'POST',
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при затвердженні контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            await setTimeout(() => syncPromisContract(props), 2000)
        }
    }
    await props.changeLoader(false)
}


function viewContractDocuments(props, data, count, docScopeData, setPopAddDoc){
    if(data !== null){
        if(data.documents !== null){
            if(data.documents.length > 0){
                return <PopDown
                    title={`Документи та додатки пов’язані з актом/договором (${data.documents.length})`}
                    content={
                        <>
                            {viewDocContract(data, count, props, docScopeData, setPopAddDoc)}
                            {/* {viewAllBidsDocHis(props)} */}
                        </>
                    }
                />
            }
        }
    }
    return null
}

function viewName(data){
    if(data !== null){
        return <TextHalf
            title='Переможець'
            data={
                <>
                    <div>{data.name.uk_UA}</div>
                    <div>{data.identifier.scheme}, {data.identifier.id}, {data.identifier.legalName.uk_UA}</div>
                </>
            }
        />
    }
    return null
}

function viewStatus(props, data){
    if(data !== null){
        return <TextHalf
            title='Статус акту/договору'
            data={typeStatus(props, data)}
        />
    }
    return null
}

function typeStatus(props, data){
    switch(data){
        case 'pending': return 'Очікується акт'
        case 'signed': return 'Акт підписано'
        case 'active': return 'Отримання оплати підтверджено'
        case 'cancelled': return 'Акт скасовано'
        case 'unsuccessful': return 'Статус не використовується'
    }
}

function viewContractNumber(data){
    if(data !== null){
        return <TextHalf
            title='№ акту/договору'
            data={data}
        />
    }
    return null
}

function viewTitle(data){
    if(data !== null){
        return <TextHalf
            title='Назва акту/договору'
            data={data.uk_UA}
        />
    }
    return null
}

function viewDescription(data){
    if(data !== null){
        return <TextHalf
            title='Опис акту/договору'
            data={data.uk_UA}
        />
    }
    return null
}

function statusContracts(props, data){
    let tmp = null
    switch(data){
        case 'pending':
            if(isBRE(props.auctionId.sellingMethod) || isBRD(props.auctionId.sellingMethod) || isBRW(props.auctionId.sellingMethod)){
                tmp = <div className={styles.lineWrapAwards}>
                    <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Очікується акт/договір</div>
                </div>
            }else{
                tmp = <div className={styles.lineWrapAwards}>
                    <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Очікується договір</div>
                </div>
            }
            break
        case 'signed':
            tmp = <div className={styles.lineWrapAwards}>
                <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Договір підписано</div>
            </div>
            break
        case 'paid':
            tmp = <div className={styles.lineWrapAwards}>
                <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Кошти до бюджету сплачено</div>
            </div>
            break
        case 'active':
            if(isBRE(props.auctionId.sellingMethod) || isBRD(props.auctionId.sellingMethod) || isBRW(props.auctionId.sellingMethod)){
                tmp = <div className={styles.lineWrapAwards}>
                    <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Акт/договір підтверджено</div>
                </div>
            }else{
                tmp = <div className={styles.lineWrapAwards}>
                    <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Умови договору виконано</div>
                </div>
            }
            break
        case 'cancelled':
            if(isBRE(props.auctionId.sellingMethod) || isBRD(props.auctionId.sellingMethod) || isBRW(props.auctionId.sellingMethod)){
                tmp = <div className={styles.lineWrapAwards}>
                    <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Акт/договір скасовано</div>
                </div>
            }else{
                tmp = <div className={styles.lineWrapAwards}>
                    <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Договір скасовано</div>
                </div>
            }
            break
        case 'unsuccessful':
            tmp = <div className={styles.lineWrapAwards}>
                <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Статус не використовується</div>
            </div>
            break
        default:
            break
    }
    return tmp
}

function viewDocContract(data, countContract, props, docScopeData, setPopAddDoc) {
    if(data !== null){
        if(data === undefined || data === 'undefined'){
        } else {
            docScopeData.contractStatus = data.status
                docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${data.id}/documents`
                docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${data.id}/documents`
                // docScopeData.deleteURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.elemBid.id}/documents`
                docScopeData.historyUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/contracts/${data.id}/documents`
            let tmp = data.documents.map(
                (t, count) => {
                    if (t.datePublished !== null) {
                        modifiDateString(t.datePublished, 3, false)
                    }
                    if (t.dateModified !== null) {
                        modifiDateString(t.dateModified, 3, false)
                    }
                    docScopeData.docTypesList = listContractDoc(props, data)
                    return (
                        <>
                            <DocItem
                                data={t}
                                // contractData={data}
                                scopeData={docScopeData}
                                dataFuncOpenPopUp={setPopAddDoc}
                                  key={count}
                            />
                    </>
                    )
                }
            )
            return tmp
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionAwards: state.start.auctionAwards,
        allUserPopUp: state.start.allUserPopUp,
        allUserPopUpUser: state.start.allUserPopUpUser,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        timberContractPut: state.start.timberContractPut,
        pageNumber: state.start.pageNumber,
        auctionsGetId: state.start.auctionsGetId,
        history: state.start.history,
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
        historyDocsContracts: state.start.historyDocsContracts,
        userProfiles: state.start.userProfiles
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,
    setAwardAllUserPopUp,
    setAwardAllUserPopUpUser,

    changeContractApprowePopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,

    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,
    setContractDatePaid,

    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractLAE)
