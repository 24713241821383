import React,{useState, useEffect} from 'react';
import styles from '../procedure.module.css'

import {useSelector} from 'react-redux';
import {TranslationsUk} from '../../../../../../redux/translations.uk.js';

import { isMongo, checkProp } from '../../../../../../redux/check';

import TextArea from '../../../../../elements/inputs/textArea'
import Input from '../../../../../elements/inputs/input';
import Select from '../../../../../elements/inputs/inputSelect'
import Button from '../../../../../elements/buttons/button';
import InputAmount from '../../../../../elements/inputs/inputAmountMult';
import InputDate from '../../../../../elements/inputs/inputDate';
import InstructionBlock from '../../../../../elements/viewData/instructionBlock/instructionBlock'

//import BankAccount from '../../bankAccount/bankAccount';
import BanksAccount from '../../bankNew/banks';

//import { setTenderAttemptList, createBodyCSE } from './utils';
import { createBody } from '../lib/createBody';
import { setTenderAttemptList } from '../lib/utils';

import {editAuc, createAuc, editAucCurrentTenant} from '../../../createEditAndCloneAuction/edit/editTabMonga'

const AuctionLSP = ( {data, ...props} ) => {
    const auction = useSelector(state => state.start.auctionsMy)
    const bankAccount = useSelector(state => state.start.bankAccount)
    useSelector(state => state.start.token);
    const [lotId, setLotId] = useState(checkProp(data, 'lotId') ? data.lotId : null)
    const [title, setTitle] = useState(checkProp(data, 'title') ? isMongo(data.title) : null)
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [tenderAttempts, setTenderAttempts] = useState(checkProp(data, 'tenderAttempts') ? data.tenderAttempts : 1)
    const [accessDetails, setAccessDetails] = useState(checkProp(data, 'accessDetails') ? isMongo(data.accessDetails) : null)

    const [restrictions, setRestrictions] = useState(checkProp(data, 'restrictions') ? isMongo(data.restrictions) : null)
    const [conditions, setConditions] = useState(checkProp(data, 'conditions') ? isMongo(data.conditions) : null)

    const [x_additionalInformation, setX_additionalInformation] = useState(checkProp(data, 'x_additionalInformation') ? isMongo(data.x_additionalInformation) : null)
    const [x_documentRequirements, setX_documentRequirements] = useState(checkProp(data, 'x_documentRequirements') ? isMongo(data.x_documentRequirements) : null)
    //const [minNumberOfQualifiedBids, setMinNumberOfQualifiedBids] = useState(checkProp(data, 'minNumberOfQualifiedBids') ? data.minNumberOfQualifiedBids : 1)
    const [previousAuctionId, setPreviousAuctionId] = useState(checkProp(data, 'previousAuctionId') ? data.previousAuctionId : null)
    //const [discount, setDiscount] = useState(checkProp(data,'discount') && checkProp(data.discount,'discount') ? data.discount.discount : false)
    //const [discountPercent, setDiscountPercent] = useState(checkProp(checkProp(data,'discount') && data.discount, 'discountPercent') ? data.discount.discountPercent : null)
    //const [previousAuctionValue, setPreviousAuctionValue] = useState(checkProp(checkProp(data,'discount') && data.discount, 'previousAuctionValue') ? data.discount.previousAuctionValue : null)
    const [value, setValue] = useState(checkProp(data, 'value') ? data.value : null)
    const [guarantee, setGuarantee] = useState(checkProp(data, 'guarantee') ? data.guarantee : null)
    const [minimalStep, setMinimalStep] = useState(checkProp(data, 'minimalStep') ? data.minimalStep : null)
    const [registrationFee, setRegistrationFee] = useState(checkProp(data, 'registrationFee') ? data.registrationFee : {
        amount: 710,
        currency: "UAH",
    })
    const [normativeMonetaryValuation, setNormativeMonetaryValuation] = useState(checkProp(data, 'normativeMonetaryValuation') ? data.normativeMonetaryValuation : null)
    const [expertMonetaryValuation, setExpertMonetaryValuation] = useState(checkProp(data, 'expertMonetaryValuation') ? data.expertMonetaryValuation : null)
    const [budgetSpent, setBudgetSpent] = useState(checkProp(data, 'budgetSpent') ? data.budgetSpent : null)
    const [startDate, setStartDate] = useState(checkProp(data, 'auctionPeriod') && checkProp(data.auctionPeriod, 'startDate') ? data.auctionPeriod.startDate : null)
    const [bankAccounts, setBankAccounts] = useState(checkProp(data,'bankAccounts') ? data.bankAccounts : [])
    const [saleType, setSaleType] = useState(checkProp(data, 'saleType') && data.saleType !== null && data.saleType !== 'null' ? data.saleType : 'landSale')
    const [numberOfCurrentTenants, setNumberOfCurrentTenants] = useState(checkProp(data, 'numberOfCurrentTenants') ? data.numberOfCurrentTenants : 1)
    
    // const [, setNameCurrentTenants] = useState(null)
    // const [, setNameCurrentTenants2] = useState(null)
    const [identifierLegalNameCurrentTenants, setIdentifierLegalNameCurrentTenants] = useState(null)
    const [identifierLegalNameCurrentTenants2, setIdentifierLegalNameCurrentTenants2] = useState(null)
    const [identifierSchemeCurrentTenants, setIdentifierSchemeCurrentTenants] = useState(null)
    const [identifierSchemeCurrentTenants2, setIdentifierSchemeCurrentTenants2] = useState(null)
    const [identifierIdCurrentTenants, setIdentifierIdCurrentTenants] = useState(null)
    const [identifierIdCurrentTenants2, setIdentifierIdCurrentTenants2] = useState(null)
    const [addressCurrentTenants, setAdressCurrentTenants] = useState(null)
    const [addressCurrentTenants2, setAdressCurrentTenants2] = useState(null)
    const [contactRepresentativeInfoCurrentTenants, setContactRepresentativeInfoCurrentTenants] = useState(null)
    const [contactRepresentativeInfoCurrentTenants2, setContactRepresentativeInfoCurrentTenants2] = useState(null)
    const [priority, setContactPriority] = useState(null)
    const [priority2, setContactPriority2] = useState(null)
    const [contactValueCurrentTenants, setContactValueCurrentTenants] = useState(null)
    const [contactValueCurrentTenants2, setContactValueCurrentTenants2] = useState(null)
    const [contactTimeDateFromCurrentTenants, setContactTimeDateFromCurrentTenants] = useState(null)
    const [contactTimeDateFromCurrentTenants2, setContactTimeDateFromCurrentTenants2] = useState(null)
    const [contactTimeDateTillCurrentTenants, setContactTimeDateTillCurrentTenants] = useState(null)
    const [contactTimeDateTillCurrentTenants2, setContactTimeDateTillCurrentTenants2] = useState(null)

    useEffect(() => {
        if(checkProp(data, 'currentTenants')){
            // if(checkProp(data.currentTenants[0], 'name')) setNameCurrentTenants(isMongo(data.currentTenants[0].name))
            // if(checkProp(data.currentTenants[1], 'name')) setNameCurrentTenants2(isMongo(data.currentTenants[1].name))
            if(checkProp(data.currentTenants[0], 'identifier')
                && checkProp(data.currentTenants[0].identifier, 'legalName')
            ) setIdentifierLegalNameCurrentTenants(isMongo(data.currentTenants[0].identifier.legalName))
            if(checkProp(data.currentTenants[1], 'identifier')
                && checkProp(data.currentTenants[1].identifier, 'legalName')
            ) setIdentifierLegalNameCurrentTenants2(isMongo(data.currentTenants[1].identifier.legalName))
            if(checkProp(data.currentTenants[0], 'identifier')
                && checkProp(data.currentTenants[0].identifier, 'scheme')
            ) setIdentifierSchemeCurrentTenants(data.currentTenants[0].identifier.scheme)
            if(checkProp(data.currentTenants[1], 'identifier')
                && checkProp(data.currentTenants[1].identifier, 'scheme')
            ) setIdentifierSchemeCurrentTenants2(data.currentTenants[1].identifier.scheme)
            if(checkProp(data.currentTenants[0], 'identifier')
                && checkProp(data.currentTenants[0].identifier, 'id')
            ) setIdentifierIdCurrentTenants(data.currentTenants[0].identifier.id)
            if(checkProp(data.currentTenants[1], 'identifier')
                && checkProp(data.currentTenants[1].identifier, 'id')
            ) setIdentifierIdCurrentTenants2(data.currentTenants[1].identifier.id)
            if(checkProp(data.currentTenants[0], 'address')) setAdressCurrentTenants(data.currentTenants[0].address)
            if(checkProp(data.currentTenants[1], 'address')) setAdressCurrentTenants2(data.currentTenants[1].address)
            if(checkProp(data.currentTenants[0], 'representativeInfo')) setContactRepresentativeInfoCurrentTenants(data.currentTenants[0].representativeInfo)
            if(checkProp(data.currentTenants[1], 'representativeInfo')) setContactRepresentativeInfoCurrentTenants2(data.currentTenants[1].representativeInfo)
            if(checkProp(data.currentTenants[0], 'priority')) setContactPriority(data.currentTenants[0].priority)
            if(checkProp(data.currentTenants[1], 'priority')) setContactPriority2(data.currentTenants[1].priority)
            if(checkProp(data.currentTenants[0], 'currentTenantValue')) setContactValueCurrentTenants(data.currentTenants[0].currentTenantValue)
            if(checkProp(data.currentTenants[1], 'currentTenantValue')) setContactValueCurrentTenants2(data.currentTenants[1].currentTenantValue)
            if(checkProp(data.currentTenants[0], 'currentContractTime')
                && checkProp(data.currentTenants[0].currentContractTime, 'dateFrom')
            ) setContactTimeDateFromCurrentTenants(data.currentTenants[0].currentContractTime.dateFrom)
            if(checkProp(data.currentTenants[1], 'currentContractTime')
                && checkProp(data.currentTenants[1].currentContractTime, 'dateFrom')
            ) setContactTimeDateFromCurrentTenants2(data.currentTenants[1].currentContractTime.dateFrom)
            if(checkProp(data.currentTenants[0], 'currentContractTime')
                && checkProp(data.currentTenants[0].currentContractTime, 'dateTill')
            ) setContactTimeDateTillCurrentTenants(data.currentTenants[0].currentContractTime.dateTill)
            if(checkProp(data.currentTenants[1], 'currentContractTime')
                && checkProp(data.currentTenants[1].currentContractTime, 'dateTill')
            ) setContactTimeDateTillCurrentTenants2(data.currentTenants[1].currentContractTime.dateTill)
        }
    }, [])

    const regionsList = [
        { name: 'Севастополь', value: 'Севастополь', koatuu: "8500000000"},
        { name: 'Київ', value: 'Київ', koatuu: "8000000000"},
        { name: 'Чернігівська область', value: 'Чернігівська область', koatuu: "7400000000"},
        { name: 'Чернівецька область', value: 'Чернівецька область', koatuu: "7300000000"},
        { name: 'Черкаська область', value: 'Черкаська область', koatuu: "7100000000"},
        { name: 'Хмельницька область', value: 'Хмельницька область', koatuu: "6800000000"},
        { name: 'Херсонська область', value: 'Херсонська область', koatuu: "6500000000"},
        { name: 'Харківська область', value: 'Харківська область', koatuu: "6300000000"},
        { name: 'Тернопільська область', value: 'Тернопільська область', koatuu: "6100000000"},
        { name: 'Сумська область', value: 'Сумська область', koatuu: "5900000000"},
        { name: 'Рівненська область', value: 'Рівненська область', koatuu: "5600000000"},
        { name: 'Полтавська область', value: 'Полтавська область', koatuu: "5300000000"},
        { name: 'Одеська область', value: 'Одеська область', koatuu: "5100000000"},
        { name: 'Миколаївська область', value: 'Миколаївська область', koatuu: "4800000000"},
        { name: 'Львівська область', value: 'Львівська область', koatuu: "4600000000"},
        { name: 'Луганська область', value: 'Луганська область', koatuu: "4400000000"},
        { name: 'Кіровоградська область', value: 'Кіровоградська область', koatuu: "3500000000"},
        { name: 'Київська область', value: 'Київська область', koatuu: "3200000000"},
        { name: 'Івано-Франківська область', value: 'Івано-Франківська область', koatuu: "2600000000"},
        { name: 'Запорізька область', value: 'Запорізька область', koatuu: "2300000000"},
        { name: 'Закарпатська область', value: 'Закарпатська область', koatuu: "2100000000"},
        { name: 'Житомирська область', value: 'Житомирська область', koatuu: "1800000000"},
        { name: 'Донецька область', value: 'Донецька область', koatuu: "1400000000"},
        { name: 'Дніпропетровська область', value: 'Дніпропетровська область', koatuu: "1200000000"},
        { name: 'Волинська область', value: 'Волинська область', koatuu: "0700000000"},
        { name: 'Вінницька область', value: 'Вінницька область', koatuu: "0500000000"},
        { name: 'Автономна Республіка Крим', value: 'Автономна Республіка Крим', koatuu: "0100000000"},
    ]



    let ct1 = [
        {
            identifier: {
                scheme: identifierSchemeCurrentTenants,
                id: `${identifierIdCurrentTenants}`,
                legalName: {
                    uk_UA: identifierLegalNameCurrentTenants
                }
            },
            address: addressCurrentTenants,
            representativeInfo: contactRepresentativeInfoCurrentTenants,
            currentTenantValue: {
                currency: checkProp(contactValueCurrentTenants,'currency') ? contactValueCurrentTenants.currency : null,
                amount: checkProp(contactValueCurrentTenants,'amount') && contactValueCurrentTenants?.amount !== '' ? +contactValueCurrentTenants.amount : null,
            },
            currentContractTime: {
                dateFrom: contactTimeDateFromCurrentTenants,
                dateTill: contactTimeDateTillCurrentTenants
            },
            priority: priority,
            // priorityTransferInfo: {
            //     transferredFrom: priorityTransferInfoTransferredFrom,
            //     name: {
            //         uk_UA: priorityTransferInfoName
            //     },
            //     identifier: {
            //         scheme: priorityIdentifierScheme,
            //         id: `${priorityIdentifierId}`,
            //         legalName: {
            //             uk_UA: priorityIdentifierLegalName
            //         }
            //     },
            // }
        },
    ]
    
    let ct2 = [
        {
            identifier: {
                scheme: identifierSchemeCurrentTenants2,
                id: `${identifierIdCurrentTenants2}`,
                legalName: {
                    uk_UA: identifierLegalNameCurrentTenants2
                }
            },
            address: addressCurrentTenants2,
            representativeInfo: contactRepresentativeInfoCurrentTenants2,
            currentTenantValue: {
                currency: checkProp(contactValueCurrentTenants2,'currency') ? contactValueCurrentTenants2.currency : null,
                amount: checkProp(contactValueCurrentTenants2,'amount') && contactValueCurrentTenants2?.amount !== '' ? +contactValueCurrentTenants2.amount : null,
            },
            currentContractTime: {
                dateFrom: contactTimeDateFromCurrentTenants2,
                dateTill: contactTimeDateTillCurrentTenants2
            },
            priority: priority2,
            // priorityTransferInfo: {
            //     transferredFrom: priorityTransferInfoTransferredFrom2,
            //     name: {
            //         uk_UA: priorityTransferInfoName2
            //     },
            //     identifier: {
            //         scheme: priorityIdentifierScheme2,
            //         id: `${priorityIdentifierId2}`,
            //         legalName: {
            //             uk_UA: priorityIdentifierLegalName2
            //         }
            //     },
            // }
        }
    ]

    let requestBody = {
        title: title !== null && title !== ""
            ? {
                uk_UA: title
            }
            : null,
        description: description !== null && description !== ""
            ? {
                uk_UA: description
            }
            : null,
        accessDetails: accessDetails !== null && accessDetails !== ''
            ? {
                uk_UA: accessDetails
            }
            : null,

        restrictions: restrictions !== null && restrictions !== ""
            ? {
                uk_UA: restrictions
            }
            : null,
        conditions: conditions !== null && conditions !== ""
            ? {
                uk_UA: conditions
            }
            : null,

        tenderAttempts: +tenderAttempts,
        lotId: lotId,
        auctionPeriod: {
            startDate: startDate
        },
        sellingMethod: data.sellingMethod,
        x_additionalInformation: {
            uk_UA: x_additionalInformation
        },
        x_documentRequirements: {
            uk_UA: x_documentRequirements
        },
        value: value?.amount !== null && value?.amount > 0
            ? {
                currency: checkProp(value,'currency') ? value.currency : null,
                amount: checkProp(value,'amount') ? +value.amount : null,
                valueAddedTaxIncluded: checkProp(value,'addTax') ? value.addTax : null
            }
            : null,
        minimalStep: minimalStep?.amount !== null && minimalStep?.amount > 0
            ? {
                currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
                amount: checkProp(minimalStep,'amount') ? +minimalStep.amount : null,
            }
            : null,
        guarantee: guarantee?.amount !== null && guarantee?.amount > 0
            ? {
                currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
                amount: checkProp(guarantee,'amount') ? +guarantee.amount : null
            }
            : null,
        registrationFee: registrationFee?.amount !== null && registrationFee?.amount > 0
            ? {
                currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
                amount: checkProp(registrationFee,'amount') ? +registrationFee.amount : null
            }
            : null,

        normativeMonetaryValuation: normativeMonetaryValuation?.amount !== null && normativeMonetaryValuation?.amount > 0
            ? {
                currency: checkProp(normativeMonetaryValuation,'currency') ? normativeMonetaryValuation.currency : null,
                amount: checkProp(normativeMonetaryValuation,'amount') ? +normativeMonetaryValuation.amount : null
            }
            : null,
        expertMonetaryValuation: expertMonetaryValuation?.amount !== null && expertMonetaryValuation?.amount > 0
            ? {
                currency: checkProp(expertMonetaryValuation,'currency') ? expertMonetaryValuation.currency : null,
                amount: checkProp(expertMonetaryValuation,'amount') ? +expertMonetaryValuation.amount : null
            }
            : null,
        budgetSpent: budgetSpent?.amount !== null && budgetSpent?.amount > 0
            ? {
                currency: checkProp(budgetSpent,'currency') ? budgetSpent.currency : null,
                amount: checkProp(budgetSpent,'amount') ? +budgetSpent.amount : null
            }
            : null,
        previousAuctionId: previousAuctionId,
        bankAccounts: saveBanks(),
        saleType: saleType,
        numberOfCurrentTenants: numberOfCurrentTenants,

        currentTenants: numberOfCurrentTenants === 1
            ? ct1
            : [...ct1, ...ct2]
    }

    function discountBlock(){
        if (tenderAttempts === 1) return null
        return(
            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Дані попереднього аукціону</div>
                <div className={styles.twoInLine}>
                    <Input //"Ідентифікатор попереднього аукціону"
                        label="Номер попереднього аукціону (аукціону з єдиним учасником)"
                        value={previousAuctionId}
                        onChange={(e)=>{ setPreviousAuctionId(e) }}
                        disabled={isDisabled()}
                        required
                    />
                </div>
            </div>
        )
    }

    function saveBanks(){
        let tmp = []
        for(let ii = 0; ii < bankAccounts.length; ii++){
            if(bankAccounts[ii].hasOwnProperty('status') !== true){
                tmp.push(bankAccounts[ii])
            }else{
                if(!bankAccounts[ii].status) tmp.push(bankAccounts[ii])
            }
        }
        return tmp
    }

    function colUch(){
        /// if(status === null){
        //     return [
        //         {value: 1, name: '1'}
        //     ]
        // }
        return [
            {value: 1, name: '1'},
            {value: 2, name: '2'}
        ]
    }

    function setKoatuu(data){
        for (let key in regionsList) { 
            if( data === regionsList[key].value) return regionsList[key].koatuu
        } 
    }

    function isDisabled(){
        if(auction.status === 'active_tendering') return true
        return false
    }

    return (
        <>
            <Input //"Номер лота" 
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.lotId.legalName} 
                value={lotId}
                onChange={(e)=>{ setLotId(e) }}
                disabled={isDisabled()}
                //required
            />
            <Input //Назва аукціону"
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.title.legalName} 
                value={title}
                onChange={(e)=>{ setTitle(e) }}
                required
                disabled={isDisabled()}
            />
            <TextArea //"Опис аукціону"
                value={description}
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.description.legalName} 
                required
                onChange={(e)=>{ setDescription(e) }}
                disabled={isDisabled()}
            />


            <TextArea //"Опис аукціону"
                value={restrictions}
                label="Обтяження прав на земельну ділянку, обмеження у використанні земель"
                //required
                onChange={(e)=>{ setRestrictions(e) }}
                disabled={isDisabled()}
            />
            <TextArea //"Опис аукціону"
                value={conditions}
                label="Містобудівні умови земельної ділянки"
                //required
                onChange={(e)=>{ setConditions(e) }}
                disabled={isDisabled()}
            />


            <Select //'Лот виставляється',
                data={{
                    label: "Порядковий номер аукціону", 
                    target: tenderAttempts,
                    
                    required: true,
                }}
                disabled={isDisabled()}
                onChange={(e) => { 
                        setTenderAttempts(e)
                    }
                }
                options={setTenderAttemptList()}
            />
            <Input //"Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
                label="Порядок та можливий час ознайомлення з лотом" 
                value={accessDetails}
                onChange={(e)=>{ setAccessDetails(e) }}
                disabled={isDisabled()}
            />
            <Input
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.x_additionalInformation.legalName} //"Додаткові відомості"
                value={x_additionalInformation}
                onChange={(e)=>{ setX_additionalInformation(e) }}
                disabled={isDisabled()}
            />
            <Input
                label="Перелік та вимоги до оформлення документів"
                value={x_documentRequirements}
                onChange={(e)=>{ setX_documentRequirements(e) }}
                disabled={isDisabled()}
            />
            <Select //"Тип продажу" 
                data={{
                    label: "Тип продажу", 
                    target: saleType,
                    
                    required: true,
                }}
                onChange={(e) => { setSaleType(e) }}
                options={
                    [
                        {value: 'landSale', name: "Продаж земельних ділянок"},
                        {value: 'superficiesSale', name: "Продаж прав суперфіцію"},
                        {value: 'emphyteusisSale', name: "Продаж прав емфітевзису"}
                    ]
                }
                disabled={isDisabled()}
            />
            <Select //"Кількість учасників з переважним правом" 
                data={{
                    label: "Кількість учасників з переважним правом", 
                    target: numberOfCurrentTenants,
                    
                    required: true,
                }}
                onChange={(e) => { setNumberOfCurrentTenants(e) }}
                options={colUch()}
                disabled={isDisabled()}
            />

            {discountBlock()}

            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Вартісні характеристики аукціону</div>
                <InstructionBlock
                    title='Важлива інформація'
                    content="Шановні користувачі! Починаючи з 01.01.2024р. мінімальна заробітна плата в Україні встановлена на рівні 7100 грн. Просимо враховувати це при встановленні реєстраційного та гарантійного внесків!"
                />
                <InputAmount //"Стартова ціна лота"
                    title="Стартова ціна"
                    lable="Стартова ціна"
                    data={value === null || value === '' || value === "null" || (value.currency === 'null' && value.amount === 0.01)
                        ? {amount: 0,
                            currency: null,
                            addTax: false
                        }
                        : {amount: value.amount,
                            currency: value.currency,
                            addTax: value.valueAddedTaxIncluded
                        }
                    }
                    onChange={(e) => {
                                setValue(e)
                                let tmp = {
                                    amount: (e.amount / 100) * 1,
                                    currency: minimalStep === null || minimalStep?.currency === null || minimalStep.currency === "null" ? null : minimalStep.currency,
                                }
                                setMinimalStep(tmp)
                                // if(Number(e.amount) <= 20000){
                                //     setRegistrationFee({registrationFee, amount: 17})
                                // }else if(Number(e.amount) <= 50000){
                                //     setRegistrationFee({registrationFee, amount: 119})
                                // }else if(Number(e.amount) <= 200000){
                                //     setRegistrationFee({registrationFee, amount: 340})
                                // }else if(Number(e.amount) <= 1000000){
                                //     setRegistrationFee({registrationFee, amount: 510})
                                // }else if(Number(e.amount) > 1000000){
                                //     setRegistrationFee({registrationFee, amount: 1700})
                                // }
                    }}
                    required
                    disabled={isDisabled()}
                    tax
                />
                <InputAmount //"Гарантійний внесок"
                    title="Гарантійний внесок"
                    lable="Гарантійний внесок"
                    data={{
                        amount: checkProp(guarantee,'amount') && guarantee.amount !== '' ? guarantee.amount : null,
                        currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
                    }}
                    onChange={(e) => { setGuarantee(e) }}
                    required
                    disabled={isDisabled()}
                />
                <InputAmount //"Розмір кроку аукціону"
                    title="Мінімальний крок"
                    lable="Мінімальний крок"
                    data={{
                        amount: checkProp(minimalStep,'amount') && minimalStep.amount !== '' ? minimalStep.amount : null,
                        currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
                    }}
                    onChange={(e) => { setMinimalStep(e) }}
                    disabled={isDisabled()}
                />
                <InputAmount //"Реєстраційний внесок"
                    title="Реєстраційний внесок"
                    lable="Реєстраційний внесок"
                    data={{
                        amount: checkProp(registrationFee,'amount') && registrationFee.amount !== '' ? registrationFee.amount : 710,
                        currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
                    }}
                    onChange={(e) => { setRegistrationFee(e) }}
                    required
                    disabled={isDisabled()}
                />


                <InputAmount //"Реєстраційний внесок"
                    title="Нормативна грошова оцінка"
                    lable="Нормативна грошова оцінка"
                    data={{
                        amount: checkProp(normativeMonetaryValuation,'amount') ? normativeMonetaryValuation.amount : null,
                        currency: checkProp(normativeMonetaryValuation,'currency') ? normativeMonetaryValuation.currency : null,
                    }}
                    onChange={(e) => { setNormativeMonetaryValuation(e) }}
                    disabled={isDisabled()}
                />
                <InputAmount //"Реєстраційний внесок"
                    title="Експертна грошова оцінка"
                    lable="Експертна грошова оцінка"
                    data={{
                        amount: checkProp(expertMonetaryValuation,'amount') ? expertMonetaryValuation.amount : null,
                        currency: checkProp(expertMonetaryValuation,'currency') ? expertMonetaryValuation.currency : null,
                    }}
                    onChange={(e) => { setExpertMonetaryValuation(e) }}
                    disabled={isDisabled()}
                />
                <InputAmount //"Реєстраційний внесок"
                    title="Сума витрат (видатків), здійснених на підготовку лота"
                    lable="Сума витрат (видатків), здійснених на підготовку лота"
                    data={{
                        amount: checkProp(budgetSpent,'amount') ? budgetSpent.amount : null,
                        currency: checkProp(budgetSpent,'currency') ? budgetSpent.currency : null,
                    }}
                    onChange={(e) => { setBudgetSpent(e) }}
                    disabled={isDisabled()}
                />
            </div>

            <div className={styles.costBlock}>
                <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Інформація про учасника з переважним правом</div>
                    <Input //Повна юридична назва організації або ПІБ
                        label='Повна юридична назва організації або ПІБ'
                        value={identifierLegalNameCurrentTenants}
                        // onChange={(e)=>{ setNameCurrentTenants(e) }}
                        disabled='true'
                    />
                    <div className={styles.orgBlock}>
                        <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                        <Input //Ідентифікатори організації
                            label='Повна юридична назва організації'
                            value={identifierLegalNameCurrentTenants}
                            onChange={(e)=>{ 
                                setIdentifierLegalNameCurrentTenants(e)
                            }}
                            required
                        />
                        <Select 
                            data={{
                                label: 'Ідентифікатори організації',
                                target: identifierSchemeCurrentTenants,
                                
                                required: true,
                            }}
                            onChange={(e) => { setIdentifierSchemeCurrentTenants(e) }}
                            options={[
                                {name: 'Обрати', value: null},
                                {name: 'ЄДРПОУ', value: 'UA-EDR'},
                                {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                                {name: 'Паспорт', value: 'UA-PASSPORT'},
                                {name: 'ID-карта', value: 'UA-ID-CARD'},
                                {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                            ]}
                        />
                        <Input //Код ЄДРПОУ або ІПН або паспорт
                            label='Код ЄДРПОУ або ІПН або паспорт'
                            value={identifierIdCurrentTenants}
                            onChange={(e)=>{ setIdentifierIdCurrentTenants(e) }}
                            required
                        />
                    </div>
                    <div className={styles.costBlock}>
                        <div className={styles.costBlockTitle}>Місцезнаходження учасника</div>
                        <Input
                            label='Країна'
                            value={addressCurrentTenants?.countryName?.uk_UA}
                            onChange={()=>{
                                setAdressCurrentTenants({
                                    addressID: {
                                        scheme: 'koatuu',
                                        id: setKoatuu(addressCurrentTenants?.region?.uk_UA)
                                    },
                                    streetAddress: addressCurrentTenants?.streetAddress,
                                    locality: addressCurrentTenants?.locality,
                                    region: addressCurrentTenants?.region,
                                    postalCode: addressCurrentTenants?.postalCode,
                                    countryName: {uk_UA: "Україна"}
                                })
                            }}
                            required
                            disabled
                        />
                        <Select
                            data={{
                                label: "Область",
                                target: addressCurrentTenants?.region?.uk_UA,
                                
                                required: true,
                            }}
                            onChange={(e) => {
                                setAdressCurrentTenants({
                                    addressID: {
                                        scheme: 'koatuu',
                                        id: setKoatuu(addressCurrentTenants?.region?.uk_UA)
                                    },
                                    streetAddress: addressCurrentTenants?.streetAddress,
                                    locality: addressCurrentTenants?.locality,
                                    region: e !== null && e !== '' ? {uk_UA: e} : null,
                                    postalCode: addressCurrentTenants?.postalCode,
                                    countryName: {uk_UA: "Україна"}
                                })
                            }}
                            options={regionsList}
                        />
                        <Input
                            label='Населенний пункт'
                            value={addressCurrentTenants?.locality?.uk_UA}
                            onChange={(e)=>{
                                setAdressCurrentTenants({
                                    addressID: {
                                        scheme: 'koatuu',
                                        id: setKoatuu(addressCurrentTenants?.region?.uk_UA)
                                    },
                                    streetAddress: addressCurrentTenants?.streetAddress,
                                    locality: e !== null && e !== '' ? {uk_UA: e} : null,
                                    region: addressCurrentTenants?.region,
                                    postalCode: addressCurrentTenants?.postalCode,
                                    countryName: {uk_UA: "Україна"}
                                })
                            }}
                            required
                        />
                        <Input
                            label='Адреса'
                            value={addressCurrentTenants?.streetAddress?.uk_UA}
                            onChange={(e)=>{
                                setAdressCurrentTenants({
                                    addressID: {
                                        scheme: 'koatuu',
                                        id: setKoatuu(addressCurrentTenants?.region?.uk_UA)
                                    },
                                    streetAddress: e !== null && e !== '' ? {uk_UA: e} : null,
                                    locality: addressCurrentTenants?.locality,
                                    region: addressCurrentTenants?.region,
                                    postalCode: addressCurrentTenants?.postalCode,
                                    countryName: {uk_UA: "Україна"}
                                })
                            }}
                            required
                        />
                        <Input
                            label='Поштовий індекс'
                            value={addressCurrentTenants?.postalCode}
                            onChange={(e)=>{
                                setAdressCurrentTenants({
                                    addressID: {
                                        scheme: 'koatuu',
                                        id: setKoatuu(addressCurrentTenants?.region?.uk_UA)
                                    },
                                    streetAddress: addressCurrentTenants?.streetAddress,
                                    locality: addressCurrentTenants?.locality,
                                    region: addressCurrentTenants?.region,
                                    postalCode: e !== null && e !== '' ? e : null,
                                    countryName: {uk_UA: "Україна"}
                                })
                            }}
                            maxlength={'5'}
                        />
                    </div>
                    {/* <LocationOrg
                        address={addressCurrentTenants}
                        setAdress={setAdressCurrentTenants}
                        dataText='Місцезнаходження учасника'
                    /> */}
                    <Input //№ свідоцтва про право на провадження діяльності арбітражного керуючого
                        label='Інформація щодо підтвердження повноважень'
                        value={contactRepresentativeInfoCurrentTenants}
                        onChange={(e)=>{ setContactRepresentativeInfoCurrentTenants(e) }}
                    />
                    <Select 
                        data={{
                            label: 'Учасник',
                            target: priority,
                            
                            required: true,
                        }}
                        onChange={(e) => { 
                            setContactPriority(e)
                            if(e === 0){
                                setContactPriority2(1)
                            }else{
                                setContactPriority2(0)
                            }
                        }}
                        options={[
                            {name: 'Обрати', value: null},
                            {name: 'З переважним правом', value: 0},
                            {name: 'З переважним правом другої черги', value: 1},
                        ]}
                    />
                    <div className={styles.costBlock}>
                        <InputAmount //"Гарантійний внесок"
                            title="Орендна ставка (сума на рік)"
                            lable="Орендна ставка (сума на рік)"
                            data={{
                                amount: checkProp(contactValueCurrentTenants,'amount') ? contactValueCurrentTenants.amount : '',
                                currency: checkProp(contactValueCurrentTenants,'currency') ? contactValueCurrentTenants.currency : null,
                                auctionType: 'brd'
                            }}
                            onChange={(e) => { setContactValueCurrentTenants(e) }}
                        />
                    </div>
                    <div className={styles.startDate}>
                        <div className={styles.costBlockTitle}>Дата початку та закінчення договору оренди</div>
                        <div>Період з</div>
                        <InputDate
                            data={{
                                target: contactTimeDateFromCurrentTenants,
                                time: true
                            }}
                            onChange={ e => setContactTimeDateFromCurrentTenants(e) }
                        />
                        <div>Період по</div>
                        <InputDate
                            data={{
                                target: contactTimeDateTillCurrentTenants,
                                time: true
                            }}
                            onChange={ e => setContactTimeDateTillCurrentTenants(e) }
                        />
                    </div>
                </div>
                {numberOfCurrentTenants === 2
                    ? <div className={styles.costBlock}>
                        <div className={styles.costBlockTitle}>Інформація про учасника з переважним правом</div>
                        <Input //Повна юридична назва організації або ПІБ
                            label='Повна юридична назва організації або ПІБ'
                            value={identifierLegalNameCurrentTenants2}
                            // onChange={(e)=>{ setNameCurrentTenants2(e) }}
                            disabled='true'
                        />
                        <div className={styles.orgBlock}>
                            <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                            <Input //Ідентифікатори організації
                                label='Повна юридична назва організації'
                                value={identifierLegalNameCurrentTenants2}
                                onChange={(e)=>{ 
                                    setIdentifierLegalNameCurrentTenants2(e)
                                }}
                                required
                            />
                            <Select 
                                data={{
                                    label: 'Ідентифікатори організації',
                                    target: identifierSchemeCurrentTenants2,
                                    
                                    required: true,
                                }}
                                onChange={(e) => { setIdentifierSchemeCurrentTenants2(e) }}
                                options={[
                                    {name: 'Обрати', value: null},
                                    {name: 'ЄДРПОУ', value: 'UA-EDR'},
                                    {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                                    {name: 'Паспорт', value: 'UA-PASSPORT'},
                                    {name: 'ID-карта', value: 'UA-ID-CARD'},
                                    {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                                ]}
                            />
                            <Input //Код ЄДРПОУ або ІПН або паспорт
                                label='Код ЄДРПОУ або ІПН або паспорт'
                                value={identifierIdCurrentTenants2}
                                onChange={(e)=>{ setIdentifierIdCurrentTenants2(e) }}
                                required
                            />
                        </div>

                        <div className={styles.costBlock}>
                            <div className={styles.costBlockTitle}>Місцезнаходження учасника</div>
                            <Input
                                label='Країна'
                                value={addressCurrentTenants2?.countryName?.uk_UA}
                                onChange={()=>{
                                    setAdressCurrentTenants2({
                                        addressID: {
                                            scheme: 'koatuu',
                                            id: setKoatuu(addressCurrentTenants2?.region?.uk_UA)
                                        },
                                        streetAddress: addressCurrentTenants2?.streetAddress,
                                        locality: addressCurrentTenants2?.locality,
                                        region: addressCurrentTenants2?.region,
                                        postalCode: addressCurrentTenants2?.postalCode,
                                        countryName: {uk_UA: "Україна"}
                                    })
                                }}
                                required
                                disabled
                            />
                            <Select
                                data={{
                                    label: "Область",
                                    target: addressCurrentTenants2?.region?.uk_UA,
                                    
                                    required: true,
                                }}
                                onChange={(e) => {
                                    setAdressCurrentTenants2({
                                        addressID: {
                                            scheme: 'koatuu',
                                            id: setKoatuu(addressCurrentTenants2?.region?.uk_UA)
                                        },
                                        streetAddress: addressCurrentTenants2?.streetAddress,
                                        locality: addressCurrentTenants2?.locality,
                                        region: e !== null && e !== '' ? {uk_UA: e} : null,
                                        postalCode: addressCurrentTenants2?.postalCode,
                                        countryName: {uk_UA: "Україна"}
                                    })
                                }}
                                options={regionsList}
                            />
                            <Input
                                label='Населенний пункт'
                                value={addressCurrentTenants2?.locality?.uk_UA}
                                onChange={(e)=>{
                                    setAdressCurrentTenants2({
                                        addressID: {
                                            scheme: 'koatuu',
                                            id: setKoatuu(addressCurrentTenants2?.region?.uk_UA)
                                        },
                                        streetAddress: addressCurrentTenants2?.streetAddress,
                                        locality: e !== null && e !== '' ? {uk_UA: e} : null,
                                        region: addressCurrentTenants2?.region,
                                        postalCode: addressCurrentTenants2?.postalCode,
                                        countryName: {uk_UA: "Україна"}
                                    })
                                }}
                                required
                            />
                            <Input
                                label='Адреса'
                                value={addressCurrentTenants2?.streetAddress?.uk_UA}
                                onChange={(e)=>{
                                    setAdressCurrentTenants2({
                                        addressID: {
                                            scheme: 'koatuu',
                                            id: setKoatuu(addressCurrentTenants2?.region?.uk_UA)
                                        },
                                        streetAddress: e !== null && e !== '' ? {uk_UA: e} : null,
                                        locality: addressCurrentTenants2?.locality,
                                        region: addressCurrentTenants2?.region,
                                        postalCode: addressCurrentTenants2?.postalCode,
                                        countryName: {uk_UA: "Україна"}
                                    })
                                }}
                                required
                            />
                            <Input
                                label='Поштовий індекс'
                                value={addressCurrentTenants2?.postalCode}
                                onChange={(e)=>{
                                    setAdressCurrentTenants2({
                                        addressID: {
                                            scheme: 'koatuu',
                                            id: setKoatuu(addressCurrentTenants2?.region?.uk_UA)
                                        },
                                        streetAddress: addressCurrentTenants2?.streetAddress,
                                        locality: addressCurrentTenants2?.locality,
                                        region: addressCurrentTenants2?.region,
                                        postalCode: e !== null && e !== '' ? e : null,
                                        countryName: {uk_UA: "Україна"}
                                    })
                                }}
                                maxlength={'5'}
                            />
                        </div>
                        {/* <LocationOrg
                            address={addressCurrentTenants2}
                            setAdress={setAdressCurrentTenants2}
                            dataText='Місцезнаходження учасника'
                        /> */}
                        <Input //№ свідоцтва про право на провадження діяльності арбітражного керуючого
                            label='Інформація щодо підтвердження повноважень'
                            value={contactRepresentativeInfoCurrentTenants2}
                            onChange={(e)=>{ setContactRepresentativeInfoCurrentTenants2(e) }}
                        />
                        <Select 
                            data={{
                                label: 'Учасник',
                                target: priority2,
                                required: true,
                            }}
                            onChange={(e) => { 
                                setContactPriority2(e)
                                if(e === 0){
                                    setContactPriority(1)
                                }else{
                                    setContactPriority(0)
                                }
                            }}
                            options={[
                                {name: 'Обрати', value: null},
                                {name: 'З переважним правом', value: 0},
                                {name: 'З переважним правом другої черги', value: 1},
                            ]}
                        />
                        <div className={styles.costBlock}>
                            <InputAmount //"Гарантійний внесок"
                                title="Орендна ставка (сума на рік)"
                                lable="Орендна ставка (сума на рік)"
                                data={{
                                    amount: checkProp(contactValueCurrentTenants2,'amount') ? contactValueCurrentTenants2.amount : '',
                                    currency: checkProp(contactValueCurrentTenants2,'currency') ? contactValueCurrentTenants2.currency : null,
                                    auctionType: 'brd'
                                }}
                                onChange={(e) => { setContactValueCurrentTenants2(e) }}
                            />
                        </div>
                        <div className={styles.startDate}>
                            <div className={styles.costBlockTitle}>Дата початку та закінчення договору оренди</div>
                            <div>Період з</div>
                            <InputDate
                                data={{
                                    target: contactTimeDateFromCurrentTenants2,
                                    time: true
                                }}
                                onChange={ e => setContactTimeDateFromCurrentTenants2(e) }
                            />
                            <div>Період по</div>
                            <InputDate
                                data={{
                                    target: contactTimeDateTillCurrentTenants2,
                                    time: true
                                }}
                                onChange={ e => setContactTimeDateTillCurrentTenants2(e) }
                            />
                        </div>
                    </div>
                : null
                }
            </div>

            <BanksAccount bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} auction={auction} bankAccount={bankAccount}/>

            <div className={styles.startDate}>
                <div className={styles.costBlockTitle}>{`${TranslationsUk['basicSell-english'].basicSellEnglishProcedure.auctionPeriod.startDate.legalName} аукціону`}</div>
                <InputDate
                    data={{
                        target: startDate,
                        time: true
                    }}
                    onChange={ e => setStartDate(e) }
                    disabled={isDisabled()}
                />
            </div>

            <div className={styles.bottomButtonBlock}>
                <Button
                    height={'50px'}
                    font={'16px'}
                    color='blue'
                    data={'Зберегти зміни'}
                    onClick={
                        () =>{
                            let tmp
                            let bodyObj = {
                                base: requestBody,
                            }
                            tmp = createBody(bodyObj)
                            if(auction.id !== null && auction.id !== ''){
                                if(auction.status === "active_tendering"){
                                    let tmp = {
                                        currentTenants: numberOfCurrentTenants === 1 ? ct1 : [...ct1, ...ct2]
                                    }
                                    editAucCurrentTenant(props.updateAuc, tmp)
                                }else{
                                    editAuc(props.updateAuc, tmp)
                                }
                            }else{
                                createAuc(props.updateAuc, tmp)
                            }
                        }
                    }
                />
            </div>
        </>
    )
}

export default AuctionLSP