import React from 'react';
import styles from './documents.module.css';

import {connect} from 'react-redux';

import DocLotItem from './docItem';

import SlimmerBlock from '../elements/slimmerBlock/slimmerBlock';

import { isLAE, isLAW, isLAP, isAPE, isAPD } from '../../redux/procedure';



const AucDocuments = (props) => {
    const docScopeData = {}

    docScopeData.aucId = props.auction.id
    docScopeData.aucStatus = props.auction.status
    docScopeData.aucType = props.auction.sellingMethod
    docScopeData.historyUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auction.id}/documents`
    docScopeData.typeOf = 'auction'
    docScopeData.aucOwner = props.auction.createdByCurrentUser
    docScopeData.aucItems = props.auction.items
    docScopeData.docList = props.data.documents

    let tmp = null;
    if(props.data !== null){
        if(props.data.documents.length === 0){
            tmp = (
                <div className={styles.infoBlock}>
                    {/* <h3 className={styles.infoBlockTitle}>Документація по лоту</h3> */}
                    <div>
                        {(isLAE(props.data.sellingMethod) || isLAW(props.data.sellingMethod) || isLAP(props.data.sellingMethod))
                            ? "Всі документи аукціону та пов'язані додатки відсутні"
                            : "Документація відсутня"
                        }
                    </div>
                </div>
            )
        }else{
            let tmpSortImg = imageSort(props)
            tmp = (
                <>
                    {viewImageDocs(tmpSortImg, docScopeData)}
                    {viewNotsImageDocs(props, docScopeData)}
                </>
            )
        }
    }
    
    return (
        <SlimmerBlock 
            title= {(isLAE(props.data.sellingMethod) || isLAW(props.data.sellingMethod) || isLAP(props.data.sellingMethod)
                || isAPE(props.data.sellingMethod) || isAPD(props.data.sellingMethod)
                )
                    ? "Всі документи аукціону та пов'язані додатки"
                    : "Документація по лоту"
            }
            content={tmp}
            slim={false}
        />
    )
}

function imageSort(props){
    if(props.auction !== null){
        if(props.auction.documents !== null){
            if(props.auction.documents.length > 0){
                let tmp = props.auction.documents
                return tmp.sort(
                    function(a, b){
                        return a.index - b.index
                    }
                )
            }
        }
    }
    return null
}

function viewImageDocs(props, data, docScopeData){
    if(data !== null){
        if(data.length > 0){
            return (
                data
                .filter(
                    (i) => {
                        return i.index !== null
                    }
                )
                .map(
                    (i) => {
                        return <DocLotItem
                            auctionFull={props.auction}
                            data={i}
                            scopeData={docScopeData}
                        />
                    }
                )
            )
        }
    }
    return null
}

function viewNotsImageDocs(props, docScopeData) {
    function dataECPMapper(data, t) {
        let tmp = data
            .filter(
            (item) => {
                return item.documentType === 'digitalSignature' && item.relatedDocument === t.id
            })
        if (tmp.length === 0) return null
        return tmp
    }
    
    if(props.auction !== null){
        if(props.auction.documents !== null){
            if(props.auction.documents.length > 0){
                return (
                    props.auction.documents
                    // .filter(
                    //     (i) => {
                    //         return i.index === null
                    //     }
                    // )
                    .filter(
                        (i) => {
                            return i.documentType !== 'digitalSignature'
                        }
                    )
                    .map(
                        (i) => {
                            return <DocLotItem
                                auctionFull={props.auction}
                                data={i}
                                scopeData={docScopeData}
                                key={i.id}
                                dataECP={ dataECPMapper(props.auction.documents, i) }
                            />
                        }
                    )
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auction: state.start.auctionsGetId,
        auction: state.start.auctionFull,
        langFlag: state.start.langFlag,
        // history: state.start.history,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(AucDocuments)