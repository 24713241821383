import React,{useState} from 'react';
import styles from './newAward.module.css';

import {connect} from 'react-redux';

import {changeAwardRejectPopUpStatus} from '../../../redux/actions/awards/awards.js';

import { setAwardAllUserPopUp, setAwardAllUserPopUpUser } from '../../../redux/actions.js';
import { isSUE, isSUD, isGFE, isGFD, isLLE, isLLD,
    isLRE, isLSE, isLSP, isBSE, isBSD, isALE, isCSE, isCSD,
    isBRE, isBRD, isBRW } from '../../../redux/procedure';

import {backendAPI} from '../../lib/backendAPI'
import {errorAPI} from '../../lib/errorAPI'

import PaymentHOC from './buttons/partialPayment/paymentHOC'
import PartialPaymentHOC from './buttons/partialPayment/partialPaymentHOC'


import {
    changeContractApprowePopUpStatus,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
} from '../../../redux/actions/contracts/contracts.js';

import { getUpdateDataAuction, getUpdateDataBid } from '../updateData';
import { saveArrBids } from '../../../redux/allAuctionBids';
import { setDataAddress } from '../../../redux/createAddress';

import TextHalf from '../../elements/viewData/textHalf'
import PopDown from '../../elements/viewData/popdownBlock/popDownBlock'
// import ContractDocItem from './awardComponents/docItems/awardDocItem';
import AddDocPopup from '../../documents/addDocPopup/addDocPopup';
import DocItem from '../../documents/docItem';

import {
    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,
} from '../../../redux/history.js';
import {setUniversalError, changeLoader} from '../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';

import {modifiDateString} from '../../../redux/modifiDate'
import { checkProp } from '../../../redux/check';
import {viewStringValue} from '../../../api/valueToString.js'

const Contract = (props) => {
    const [slim, setSlim] = useState({ state: false, text: 'Розгорнути' })
    const [popAddDoc, setPopAddDoc] = useState(false)

    function Slimmer() {
        slim.state ? setSlim({ state: false, text: 'Розгорнути'}) : setSlim({ state: true, text: 'Згорнути'})
    }

    if(props.auctionId !== null){
        if(props.auctionId === undefined || props.auctionId === 'undefined'){

        }else{
            if(
                props.auctionId.status === 'active_auction' ||
                props.auctionId.status === 'active_awarded' ||
                props.auctionId.status === 'active_qualification' ||
                props.auctionId.status === 'pending_payment' ||
                props.auctionId.status === 'pending_admission' ||
                props.auctionId.status === 'cancelled' ||
                props.auctionId.status === 'complete' ||
                props.auctionId.status === 'unsuccessful'
            ){
                if(props.auctionId.awards !== null){
                    return (<>
                                
                                <div className={styles.awardBlock}>
                                    <div className={styles.awardBlock_title}>Кваліфікація учасників</div>
                                    <div className={styles.slimmer} onClick={() => Slimmer()}>{slim.text}</div>
                                    {slim.state ?
                                        <div className={styles.awardBlockContent}>
                                            {viewAwardArr(props, popAddDoc, setPopAddDoc)}
                                        </div> : null
                                    }
                                </div>
                            </>
                    )
                }
            }
        }
    }
    return null
}



function viewAwardArr(props, popAddDoc, setPopAddDoc) {
    let tmp = null;
    if(props.auctionId !== null){
        if(props.auctionId.awards.length > 0){
            tmp = props.auctionId.awards.map(
                (i, count) => {
                    return (<>
                                <div className={styles.awardBlock}>
                                    {viewTitle(i)}
                                    {viewBiddersName(i)}
                                    {viewBidValue(props, i)}
                                    {initialValueAmount(props, i)}
                                    {viewTerminationReason(props, i)}
                                    {viewStatus(props, i)}
                                    {viewAddress(i)}
                                    {dataViewVerificationPeriod(i)}
                                    {dataViewSigningPeriod(i)}
                                    {i.terminationReason === null ? <div className={styles.btnBlock}>
                                        {openButton(props, i, count, setPopAddDoc, popAddDoc, setAwardDocTypes)}
                                    </div> : null}
                                    {viewContractDocuments(props, i, count)}
                                </div>
                        </>
                    )
                }
            )
        }
    }
    return tmp
}

function openButton(props, i, count, setPopAddDoc, popAddDoc){
    if(
        checkProp(props.userProfiles, 'isAdmin')
        && props.userProfiles.isAdmin === true
        && props.auctionId.status !== "pending_payment"
    ){
        let flag = false
        for(let j = 0; j < props.auctionId.bids.length; j++){
            if(props.auctionId.bids[j].id === i.bidId){
                if(props.auctionId.bids[j].owner === "dto.com.ua" || props.auctionId.owner === "dto.com.ua"){
                    flag = true
                }
            }
        }
        if(flag){
            return viewApproveContractDocAdmin(props, i, count, setPopAddDoc, popAddDoc, setAwardDocTypes)
        }
    }
    return buttonContractPopUp(props, i, count, setPopAddDoc, popAddDoc, setAwardDocTypes)
}


function buttonContractPopUp(props, i, count, setPopAddDoc, popAddDoc){
    if(props.auctionId !== null){
        return (
            <>
                {viewApproveContractDoc(props, i, count, setPopAddDoc, popAddDoc)}
            </>
        )
    }
    return null
}


function viewApproveContractDocAdmin(props, i, count, setPopAddDoc, popAddDoc) { // Кнопка "Документи кваліфікації"  
    
    function getBidOwner( props, i) {
        if (props.auctionId.privateData.hasBids) {
            for (let key in props.auctionId.privateData.bids) {
                if (i.bidId === props.auctionId.privateData.bids[key].bidId) return true
            }
        }
        return false
    }

    function getBidDocuments(props, i) {
        if (getBidOwner(props, i)) {
            for (let key in props.auctionId.bids) {
                if (i.bidId === props.auctionId.bids[key].id) {
                    return props.auctionId.bids[key].documents.filter((i) => { return i.documentType === 'auctionProtocol' })
                }
            }
        }

    }

    let tmp = null
    const docScopeData = {}
    docScopeData.aucId = props.auctionId.id
    docScopeData.aucStatus = props.auctionId.status
    docScopeData.aucType = props.auctionId.sellingMethod
    docScopeData.awardId = i.id
    docScopeData.awardStatus = i.status
    // docScopeData.aucReload = props.reload
    docScopeData.aucOwner = props.userProfiles.isAdmin
    docScopeData.bidOwner = getBidOwner( props, i)
    docScopeData.bidId = i.bidId
    docScopeData.docTypesList = setAwardDocTypes(props, i)
    docScopeData.reload = () => {
        getUpdateDataAuction(props, props.auctionId.id)
        if (!props.auctionId.privateData.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
    }
    docScopeData.docList = i.documents
    docScopeData.bidDocList = getBidDocuments(props, i)

    if(props.userProfiles.isAdmin){
        if(props.auctionId.owner !== "dto.com.ua"){
            docScopeData.typeOf = 'bidAward'
            docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/bid/${i.bidId}/documents`
        }else{
            docScopeData.typeOf = 'award'
            docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${i.id}/documents`
        }
    } else if (props.auctionId.privateData.isOwner){
        docScopeData.typeOf = 'award'
        docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${i.id}/documents`
    } else if (props.auctionId.privateData.hasBids) {
        docScopeData.typeOf = 'bidAward'
        //docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents`
        docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/bid/${i.bidId}/documents`
    }

    if(
        props.auctionId.status !== 'cancelled' &&
        props.auctionId.status !== 'unsuccessful' &&
        props.auctionId.status !== 'complete' &&
        (i.status === 'pending' || i.status === 'pending_payment')
    ){
        tmp = (
            <>
                {
                    popAddDoc ?
                    <AddDocPopup
                        scopeData={docScopeData}
                        actionClose={() => {
                            setPopAddDoc(false)
                        }}
                        actionNo={() => {
                            setPopAddDoc(false)
                        }}
                    />
                    : null
                }
                <div
                    className={styles.btnAccept}
                    onClick={
                        () => {
                            setPopAddDoc(true)
                        }
                    }
                >
                    Документи кваліфікації
                </div>
                {props.userProfiles.isAdmin === true && props.auctionId.owner === "dto.com.ua" ? viewApprove(props, i, count, 4) : null}
            </>
        )
    }
    return tmp
}

function viewApproveContractDoc(props, i, count, setPopAddDoc, popAddDoc) { // Кнопка "Документи кваліфікації"  
    
    function getBidOwner( props, i) {
        if (props.auctionId.privateData.hasBids) {
            for (let key in props.auctionId.privateData.bids) {
                if (i.bidId === props.auctionId.privateData.bids[key].bidId) return true
            }
        }
        return false
    }

    function getBidDocuments(props, i) {
        if (getBidOwner(props, i)) {
            for (let key in props.auctionId.bids) {
                if (i.bidId === props.auctionId.bids[key].id) {
                    return props.auctionId.bids[key].documents.filter((i) => { return i.documentType === 'auctionProtocol' })
                }
            }
        }

    }

    let tmp = null
    const docScopeData = {}
    docScopeData.aucId = props.auctionId.id
    docScopeData.aucStatus = props.auctionId.status
    docScopeData.aucType = props.auctionId.sellingMethod
    docScopeData.awardId = i.id
    docScopeData.awardStatus = i.status
    // docScopeData.aucReload = props.reload
    docScopeData.aucOwner = props.auctionId.privateData.isOwner
    docScopeData.bidOwner = getBidOwner( props, i)
    docScopeData.bidId = i.bidId
    docScopeData.docTypesList = setAwardDocTypes(props, i)
    docScopeData.reload = () => {
        getUpdateDataAuction(props, props.auctionId.id)
        if (!props.auctionId.privateData.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
    }
    docScopeData.docList = i.documents
    docScopeData.bidDocList = getBidDocuments(props, i)

    if(props.auctionId.privateData.isOwner){
        docScopeData.typeOf = 'award'
        docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${i.id}/documents`
    } else if (props.auctionId.privateData.hasBids) {
        docScopeData.typeOf = 'bidAward'
        //docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents`
        docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/bid/${i.bidId}/documents`
    }
        if(props.auctionId.privateData.isOwner === true){
            if(
                props.auctionId.status !== 'cancelled' &&
                props.auctionId.status !== 'unsuccessful' &&
                props.auctionId.status !== 'complete' &&
                i.status !== 'cancelled' &&
                i.status !== 'unsuccessful'
            ){
                tmp = (
                    <>
                    {
                                popAddDoc ?
                                <AddDocPopup
                                    scopeData={docScopeData}
                                    actionClose={() => {
                                        setPopAddDoc(false)
                                    }}
                                    actionNo={() => {
                                        setPopAddDoc(false)
                                    }}
                                />
                                : null
                            }
                        <div
                            className={styles.btnAccept}
                            onClick={
                                () => {
                                    setPopAddDoc(true)
                                }
                            }
                        >
                            Документи кваліфікації
                        </div>
                        {checkProp(props.userProfiles, 'isAdmin') ? props.userProfiles.isAdmin ? viewApprove(props, i, count, 4) : null : null}
                        {props.auctionId.privateData.isOwner ? viewApprove(props, i, count, 4) : null}
                        {/*isPartiallyPaid(props, i)*/}
                        <PaymentHOC i={i} />
                        <PartialPaymentHOC i={i} />
                    </>
                )
            }
            if(i.status === 'pending_waiting') tmp = null
        }else if(props.auctionId.privateData !== null){
            if(i.status === 'pending_waiting'){
                if(
                    props.auctionId.status !== 'cancelled' &&
                    props.auctionId.status !== 'unsuccessful' &&
                    props.auctionId.status !== 'complete' &&
                    i.status !== 'cancelled' &&
                    i.status !== 'unsuccessful'
                ){
                    if(props.auctionId.privateData.bids !== null && props.auctionId.privateData.bids.length > 0){
                        if(i.bidId === props.auctionId.privateData.bids[0].bidId){
                            tmp = (
                                <>
                                    {
                                        popAddDoc ?
                                        <AddDocPopup
                                            scopeData={docScopeData}
                                            actionClose={() => {
                                                setPopAddDoc(false)
                                            }}
                                            actionNo={() => {
                                                setPopAddDoc(false)
                                            }}
                                        />
                                        : null
                                    }
                                    {checkProp(props.userProfiles, 'isAdmin') ? props.userProfiles.isAdmin ? viewApprove(props, i, count) : null : null}
                                    {props.auctionId.privateData.isOwner ? viewApprove(props, i, count) : null}
                                    {props.auctionId.privateData.hasBids ? viewApprove(props, i, count) : null}
                                    {/*isPartiallyPaid(props, i)*/}
                                    {/*<PaymentHOC i={i} />*/}
                                    {/*<PartialPaymentHOC i={i} />*/}
                                </>
                            )
                        }
                    }
                }
            }else{
                if(
                    props.auctionId.status !== 'cancelled' &&
                    props.auctionId.status !== 'unsuccessful' &&
                    props.auctionId.status !== 'complete' &&
                    i.status !== 'cancelled' &&
                    i.status !== 'unsuccessful' &&
                    i.status !== 'active'
                ){
                    if(props.auctionId.privateData.bids !== null && props.auctionId.privateData.bids.length > 0){
                        if(i.bidId === props.auctionId.privateData.bids[0].bidId){
                            tmp = (
                                <>
                                    {
                                        popAddDoc ?
                                            <AddDocPopup
                                                scopeData={docScopeData}
                                                actionClose={() => {
                                                    setPopAddDoc(false)
                                                }}
                                                actionNo={() => {
                                                    setPopAddDoc(false)
                                                }}
                                            />
                                        : null
                                    }
                                    {checkProp(props.userProfiles, 'isAdmin') || props.auctionId.privateData.isOwner || (props.auctionId.privateData.bids.length > 0 && props.auctionId.privateData.bids[0].bidId === i.bidId)? <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                setPopAddDoc(true)
                                            }
                                        }
                                    >
                                        Документи кваліфікації
                                    </div> : null}
                                    {checkProp(props.userProfiles, 'isAdmin') ? props.userProfiles.isAdmin ? viewApprove(props, i, count) : null : null}
                                    {props.auctionId.privateData.isOwner ? viewApprove(props, i, count) : null}
                                    {/*isPartiallyPaid(props, i)*/}
                                    {/*<PaymentHOC i={i} />
                                    <PartialPaymentHOC i={i} />*/}
                                </>
                            )
                        }
                    }
                }
            }
        }
    return tmp
}


function viewTitle(i){
    if(i.title !== null && i.title !== ''){
        return (
            <TextHalf
                title='Назва'
                data={i.title}
            />
        )
    }
    return null
}

function viewBiddersName(i){
    if(i.buyers !== null){
        if(i.buyers.length > 0){
            if(i.buyers[0].name !== null && i.buyers[0].name !== ''){
                return (
                    <TextHalf
                        title='Назва учасника'
                        data={i.buyers[0].name.uk_UA}
                    />
                )
            }
        }
    }
    return null
}

function setAwardDocTypes(props, i) {
    if(props.auctionId.privateData.isOwner && i.status === 'pending') {
        return [
            { value: 'rejectionProtocol', name: 'Документ, що підтверджує дискваліфікацію', main: null },
            { value: 'act', name: 'Документ, що підтверджує відмову', main: null },
            ]
    }
    if(checkProp(props.userProfiles, 'isAdmin') && props.userProfiles.isAdmin === true && i.status === 'pending') {
        return [
            { value: 'auctionProtocol', name: 'Протокол аукціону', main: null },
            ]
    }
    if(props.auctionId.privateData.isOwner && i.status === 'pending_payment') {
        return [
            { value: 'paymentInformation', name: 'Інформація про повний розрахунок', main: true },
            { value: 'partialPayment', name: 'Інформація про часткову оплату', main: null },
            { value: 'rejectionProtocol', name: 'Документ, що підтверджує дискваліфікацію', main: null },
            { value: 'act', name: 'Документ, що підтверджує відмову', main: null },
        ]
    }
    if (props.auctionId.privateData.isOwner && i.status === 'active') {
        return [
            { value: 'rejectionProtocol', name: 'Документ, що підтверджує дискваліфікацію', main: null },
            { value: 'act', name: 'Документ, що підтверджує відмову', main: null },
        ]
    }
    if (props.auctionId.privateData.bids !== null && props.auctionId.privateData.bids.length > 0) {
        if(i.bidId === props.auctionId.privateData.bids[0].bidId  && i.status === 'pending'){
        //if(i.status === 'pending'){
            return [
                { value: 'auctionProtocol', name: 'Протокол аукціону', main: null }
            ]
        }else if(i.bidId === props.auctionId.privateData.bids[0].bidId && i.status === 'active'){
        //}else if(i.status === 'active'){
            let tmpIsCurrentTenant = null
            for(let g = 0; g < props.auctionId.bids.length; g++){
                if(props.auctionId.bids[g].hasOwnProperty('isCurrentTenant') === true){
                    if(props.auctionId.bids[g].id === props.auctionId.privateData.bids[0].bidId) tmpIsCurrentTenant = props.auctionId.bids[g].isCurrentTenant
                }
            }
            if(tmpIsCurrentTenant !== null){
                if(tmpIsCurrentTenant === false){
                    return [
                        { value: 'auctionProtocol', name: 'Протокол аукціону', main: null },
                        { value: 'transferAct', name: 'Акт приймання-передачі', main: true },
                    ]
                }else{
                    return [
                        { value: 'auctionProtocol', name: 'Протокол аукціону', main: null },
                        { value: 'transferAct', name: 'Акт приймання-передачі', main: null },
                    ]
                }
            }else{
                return [
                    { value: 'auctionProtocol', name: 'Протокол аукціону', main: null }
                ]
            }
        }else if(i.bidId === props.auctionId.privateData.bids[0].bidId && i.status === 'pending_waiting'){
        //}else if(i.status === 'pending_waiting'){
            return [
                { value: 'act', name: 'Документ, що підтверджує відмову', main: null }
            ]
        }
    }
    return []
}

function viewBidValue(props, i){
    if(props.auctionId !== null){
        if(props.auctionId.bids !== null){
            if(props.auctionId.bids.length > 0){
                for(let tmp = 0; tmp < props.auctionId.bids.length; tmp++){
                    if(props.auctionId.bids[tmp].value !== null){
                        if(props.auctionId.bids[tmp].id === i.bidId){
                            let tmpValue = viewStringValue(props.auctionId.bids[tmp].value)
                            return (
                                <TextHalf
                                    title='Розмір цінової пропозиції'
                                    data={tmpValue}
                                />
                            )
                        }
                    }
                }
            }
        }
    }
    return null
}

function initialValueAmount(props, i){
    if(props.auctionId !== null){
        if(props.auctionId.bids !== null){
            if(props.auctionId.bids.length > 0){
                for(let tmp = 0; tmp < props.auctionId.bids.length; tmp++){
                    if(props.auctionId.bids[tmp].value !== null){
                        if(props.auctionId.bids[tmp].id === i.bidId){
                            let tmpCost = {
                                amount: props.auctionId.bids[tmp].initialValueAmount,
                                currency: "UAH"
                            }
                            let tmpValue = viewStringValue(tmpCost)
                            return props.auctionId.bids[tmp].initialValueAmount ? <TextHalf
                                    title='Розмір закритої цінової пропозиції'
                                    data={tmpValue}
                                /> : null
                        }
                    }
                }
            }
        }
    }
    return null
}

function viewTerminationReason(props, data){
    if(data.terminationReason !== null && data.terminationReason !== '' && data.terminationReason !== 'null'){
        return (
            <TextHalf
                title='Причина відмови'
                data={viewTypeTerminationReason(props, data.terminationReason)}
            />
        )
    }
    return null
}

function viewTypeTerminationReason(props, data){
    if(props.auctionId !== null){
        switch(true){
            case isSUE(props.auctionId.sellingMethod):
            case isSUD(props.auctionId.sellingMethod):
                switch(data){
                    case '1': return "Непідписання протоколу"
                    case '2': return "Непідписання договору"
                    case '3': return "Поширюються обмежувальні заходи (санкції)"
                    case '4': return "Непроведення реєстрації представництва"
                    case '5': return "Невнесення сплати в установлений строк"
                    case '6': return "Неотримання висновку з оцінки впливу на довкілля"
                    case '7': return "Отримання висновку з оцінки впливу на довкілля з обґрунтуванням недопустимості провадження планованої діяльності"
                    default: return data
                }
            case isLLE(props.auctionId.sellingMethod):
            case isLLD(props.auctionId.sellingMethod):
                switch(data){
                    case '1': return 'Непідписання протоколу'
                    case '2': return `Непідписання договору`
                    case '3': return 'Невідповідність вимогам статті 4 ЗУ'
                    case '4': return `Неподання обов'язкових документів або відомостей`
                    case '5': return 'Подання неправдивих відомостей'
                    case '6': return 'Судові рішення та інші підстави'
                    default: return data
                }
            case isGFE(props.auctionId.sellingMethod):
            case isGFD(props.auctionId.sellingMethod):
                switch(data){
                    case '1': return 'Відмовився від підписання договору'
                    case '2': return `Переможець аукціону є боржником та/або поручителем за кредитними договорами та договорами забезпечення виконання зобов'язань`
                    case '3': return 'Повна оплата коштів за лот не здійснена в строк'
                    default: return data
                }
            case isLRE(props.auctionId.sellingMethod):
            case isLSE(props.auctionId.sellingMethod):
            case isLSP(props.auctionId.sellingMethod):
                switch(data){
                    case "1": return "Відмовився від підписання протоколу"
                    case "2": return "Відмовився від укладання договору"
                    case "3": return "Не надав обов’язкові документи або відомості /надав неправдиві відомості"
                    case "4": return "Не сплатив належну суму за придбаний лот та/або суму витрат на підготовку лота до продажу"
                    case "5": return "Не відповідає встановленим Кодексом вимогам"
                    case "6": return "Не отримав необхідне погодження ВРУ або КМУ"
                    default: return data
                }
            case isBSE(props.auctionId.sellingMethod):
            case isBSD(props.auctionId.sellingMethod):
            case isALE(props.auctionId.sellingMethod):
            case isCSE(props.auctionId.sellingMethod):
            case isCSD(props.auctionId.sellingMethod):
                switch(data){
                    case "1": return "Відмова або непідписання протоколу"
                    case "2": return "Відмова або непідписання договору"
                    case "3": return "Відсутні обов’язкові документи"
                    case "4": return "Невнесення передоплати або оплати за договором"
                    case "5": return "Участь в аукціоні з більше, ніж 1 майданчика"
                    default: return data
                }
            case isBRE(props.auctionId.sellingMethod):
            case isBRD(props.auctionId.sellingMethod):
            case isBRW(props.auctionId.sellingMethod):
                switch(data){
                    case "1": return "Відмова або непідписання протоколу"
                    case "2": return "Відмова або непідписання акту"
                    case "3": return "Невнесення оплати за лот"
                    default: return data
                }
            default:
                switch(data){
                    case '1': return 'Відмовився від підписання протоколу'
                    case '2': return 'Невідповідність встановленим вимогам'
                    case '3': return 'Відмовився від підписання договору купівлі-продажу лота'
                    case '4': return 'Не підписав договір купівлі-продажу лота в установлений строк'
                    case '5': return 'Вчасно не здійснив передоплату за договором купівлі-продажу лота'
                    default: return data
                }
        }
    }
}

function viewStatus(props, data){
    if(data.status !== null && data.status !== '' && data.status !== 'null'){
        return (
            <TextHalf
                title='Статус'
                data={viewStatusAwards(props, data.status)}
            />
        )
    }
    return null
}

function viewStatusAwards(props, status){
    switch(status){
        case 'pending':
            return 'Очікується протокол'
        case 'pending_waiting':
            return 'Очікується рішення'
        case 'pending_payment':
            return 'Очікується оплата'
        case 'active':
            if(props.auctionId.status === "complete"){
                return 'Переможець. Аукціон завершено. Договір підписано'
            }else{
                return 'Переможець. Очікується договір'
            }
        case 'unsuccessful':
            return 'Дискваліфіковано'
        case 'cancelled':
            return 'Учасник не став переможцем'
    }
    return null
}

function viewAddress(data){
    if(createAddress(data) !== null){
        return (
            <TextHalf
                title='Юридична адреса'
                data={createAddress(data)}
            />
        )
    }
    return null
}

function createAddress(i){
    let tmp = null
        if(i.buyers !== null){
            if(i.buyers.length > 0){
                tmp = i.buyers[0].address.countryName.uk_UA + ', ' + i.buyers[0].address.postalCode + ', ' + i.buyers[0].address.locality.uk_UA + ', ' + i.buyers[0].address.region.uk_UA + ', ' + i.buyers[0].address.streetAddress.uk_UA
            }
        }
    return tmp
}

function dataViewVerificationPeriod(data){
    if(data.hasOwnProperty('verificationPeriod')){
        if(data.verificationPeriod !== null){
            let tmp = `${modifiDateString(data.verificationPeriod.startDate, 3)} - ${modifiDateString(data.verificationPeriod.endDate, 3)}`
            return (
                <TextHalf
                    title='Термін на завантаження та підтвердження протоколу'
                    data={tmp}
                />
            )
        }
    }
    return null
}

function dataViewSigningPeriod(data){
    if(data.hasOwnProperty('signingPeriod')){
        if(data.signingPeriod !== null){
            let tmp = `${modifiDateString(data.signingPeriod.startDate, 3)} - ${modifiDateString(data.signingPeriod.endDate, 3)}`
            return (
                <TextHalf
                    title='Термін на завантаження та підтвердження договору'
                    data={tmp}
                />
            )
        }
    }
    return null
}

function viewContractDocuments(props, data, count, setPopAddDoc){
    if(data !== null){
        if (data.documents !== null) {
            let TmpBidDocs = viewDocAwardBid(props, data)
            let tmpArr =  TmpBidDocs !== null ? TmpBidDocs.length : null
            if((data.documents !== null && data.documents.length > 0) || (TmpBidDocs !== null && TmpBidDocs.length > 0)){// || viewDocAwardBidCount(props, data) !== null){
                let docOrg = null
                let docUch = null
                if(data.documents.length !== 0) docOrg = <div className={styles.blockHeader}>Документи організатора:</div>
                if(viewDocAwardBid(props, data) !== null && viewDocAwardBid(props, data).length !== 0) docUch = <div className={styles.blockHeader}>Документи учасника:</div>
                return <PopDown
                    title={`Додані документи (${data.documents.length + tmpArr})`}
                    content={
                        <>
                            {docOrg}
                            {viewDocContract(data, count, props)}
                            {docUch}
                            {viewDocAwardBid(props, data)}
                        </>
                    }
                />
            }
        }
    }
    return null
}

function viewDocContract(data, countContract, props) { // документы организатора
    
    function dataECPMapper(data, t) {
        let tmp = data
            .filter(
            (item) => {
                return item.documentType === 'digitalSignature' && item.relatedDocument === t.id
            })
        if (tmp.length === 0) return null
        return tmp
    }
    
    if(data !== null){
        if(data === undefined || data === 'undefined'){
        }else{
            let tmp = data.documents
            // .filter(
            //     (t) => {
            //         return t.documentType !== 'digitalSignature'
            //     }
            // )
            .map(
                (t) => {

                        const getHistoryURL = (props, data) => {
                            if (t.documentOf === 'award') {
                                    return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/awards/${data.id}/documents`
                                }
                                if (t.documentOf === 'bid') {
                                    return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/bids/${data.bidId}/documents`
                                }
                        }
                        
                        const getBidOwner = (props, data) => {
                            if (props.auctionId.privateData.hasBids) {
                                for (let key in props.auctionId.privateData.bids) {
                                    if (data.bidId === props.auctionId.privateData.bids[key].bidId) return true
                                }
                            }
                            return false
                        }
                     

                        const docScopeData = {}
                        docScopeData.aucId = props.auctionId.id
                        docScopeData.aucStatus = props.auctionId.status
                        docScopeData.aucType = props.auctionId.sellingMethod
                        docScopeData.reload = () => {
                            if (!props.auctionId.privateData.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
                            getUpdateDataAuction(props, props.auctionId.id)
                        }
                        docScopeData.aucOwner = props.auctionId.privateData.isOwner
                        docScopeData.historyUrl = getHistoryURL(props, data)
                        
                           
                        docScopeData.bidOwner = getBidOwner( props, data)
                        docScopeData.docTypesList = setAwardDocTypes(props, data)
                        docScopeData.awardStatus = data.status
                        docScopeData.docList = data.documents

                        if(props.auctionId.privateData.isOwner){
                            docScopeData.typeOf = 'award'
                            docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${data.id}/documents`
                            docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${data.id}/documents`
                            docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${data.id}/documents`
                        }
                    
                        return (
                            <DocItem
                                data={t}
                                scopeData={docScopeData}
                                dataECP={ dataECPMapper(data.documents, t) }
                                key={t.id}
                            />
                        )
                }
            )
            return tmp
        }
    }
}

function viewDocAwardBid(props, data) { //документы участника
    const getBidOwner = (props, data) => {
        if (props.auctionId.privateData.hasBids) {
            for (let key in props.auctionId.privateData.bids) {
                if (data.bidId === props.auctionId.privateData.bids[key].bidId) return true
            }
        }
        return false
    }

    function dataECPMapper(data, t) {
        let tmp = data
            .filter(
            (item) => {
                return item.documentType === 'digitalSignature' &&  item.relatedDocument === t.documentId
            })
        if (tmp.length === 0) return null
        return tmp
    }

    //TODO: Пофиксить вывод документов бида
    let tmpArr = null

    if (checkProp(props.auctionId, 'bids') && props.auctionId.bids.length > 0) {
        for (let key in props.auctionId.bids) {
            if (props.auctionId.bids[key].id === data.bidId) {
                if (checkProp(props.auctionId.bids[key], 'documents') && props.auctionId.bids[key].documents.length > 0) {
                    // ecpArr = props.auctionId.bids[key].documents
                    tmpArr = props.auctionId.bids[key].documents.filter(
                        (t) => {
                            return t.documentType === 'auctionProtocol'
                                // || t.documentType === "digitalSignature"
                                            }
                    )
                    break
                }
            }
        }
    }
    
    if (tmpArr !== null) {
        let tmp = tmpArr.map(
            (t) => {
                const docScopeData = {}
                docScopeData.aucId = props.auctionId.id
                docScopeData.aucStatus = props.auctionId.status
                docScopeData.aucType = props.auctionId.sellingMethod
                docScopeData.reload = () => {
                    // await getUpdateDataBid(props, props.auctionId.privateData.bids[0].id)
                    if (!props.auctionId.privateData.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
                    getUpdateDataAuction(props, props.auctionId.id)
                }
                docScopeData.aucOwner = props.auctionId.privateData.isOwner
                
                docScopeData.bidOwner = getBidOwner( props, data)
                docScopeData.docTypesList = setAwardDocTypes(props, data)
                docScopeData.awardStatus = data.status
                docScopeData.typeOf = 'bidAward'
                docScopeData.docList = data.documents

                docScopeData.historyUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/bids/${data.bidId}/documents`
                if (checkProp(props.auctionId.privateData, 'bids') && props.auctionId.privateData.bids.length > 0 ){
                    ///docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents/${getDocId(t.id)}`
                    docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/bid/${data.bidId}/documents/${t.id}`
                    //docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents/${getDocId(t.id)}`
                    docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/bid/${data.bidId}/documents/${t.id}`
                }
                return (
                    <>
                        <DocItem
                            data={t}
                            scopeData={docScopeData}
                            key={t.id}
                            dataECP={dataECPMapper(tmpArr, t)}
                        />
                    </>
                )
            }
        )
        return tmp
    }
    return null
}

//////////////////////////////////////////

function viewApprove(props, i, count){
    if(i.status !== 'active'){
        if(
            !isLRE(props.auctionId.sellingMethod) &&
            !isLSE(props.auctionId.sellingMethod) &&
            !isLSP( props.auctionId.sellingMethod)
        ){
            if(
                props.auctionId.status !== 'cancelled' &&
                props.auctionId.status !== 'unsuccessful' &&
                props.auctionId.status !== 'complete' &&
                i.status !== 'cancelled' &&
                i.status !== 'unsuccessful' //&&
                //i.status !== 'active'
            ){
                if(props.auctionId.privateData.bids !== null && props.auctionId.privateData.bids.length > 0){
                    if(i.bidId === props.auctionId.privateData.bids[0].bidId){
                        if(i.status === 'pending_waiting'){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                        }
                                    }
                                >
                                    Відмовитись від очікування
                                </div>
                            )
                        }
                    }
                }else if(checkProp(props.userProfiles, 'isAdmin') && props.userProfiles.isAdmin === true || i.status === 'pending_payment'){
                    if(i.documents !== null){
                        if(i.documents.length > 0){
                            for(let tm = 0; tm < i.documents.length; tm++){
                                if(
                                    i.documents[tm].documentType === 'auctionProtocol'
                                ){
                                    if(!props.auctionId.privateData.isOwner){
                                        return (
                                            <div
                                                className={styles.btnAccept}
                                                onClick={
                                                    () => {
                                                        props.changeAwardRejectPopUpStatus({
                                                            aucId: props.auctionId.id,
                                                            awardId: i.id,
                                                            status: i.status,
                                                            awards: count,
                                                            sellingMethod: props.auctionId.sellingMethod
                                                        })
                                                    }
                                                }
                                            >
                                                Дії з учасником
                                            </div>
                                        )
                                    }
                                }
                            }
                            for(let tm = 0; tm < i.documents.length; tm++){
                                //let tmpDoc1 = new Date(i.documents[tm].datePublished)
                                if(
                                    i.documents[tm].documentType === 'rejectionProtocol' || 
                                    i.documents[tm].documentType === 'act'
                                ){
                                    return (
                                        <div
                                            className={styles.btnAccept}
                                            onClick={
                                                () => {
                                                    props.changeAwardRejectPopUpStatus({
                                                        aucId: props.auctionId.id,
                                                        awardId: i.id,
                                                        status: i.status,
                                                        awards: count,
                                                        sellingMethod: props.auctionId.sellingMethod
                                                    })
                                                }
                                            }
                                        >
                                            Дії з учасником
                                        </div>
                                    )
                                }
                            }
                            return null
                        }
                    }
                }else{
                    if(i.documents !== null){
                        if(i.documents.length > 0){
                            for(let tm = 0; tm < i.documents.length; tm++){
                                if(
                                    i.documents[tm].documentType === 'rejectionProtocol' || 
                                    i.documents[tm].documentType === 'act'
                                ){
                                    return (
                                        <div
                                            className={styles.btnAccept}
                                            onClick={
                                                () => {
                                                    props.changeAwardRejectPopUpStatus({
                                                        aucId: props.auctionId.id,
                                                        awardId: i.id,
                                                        status: i.status,
                                                        awards: count,
                                                        sellingMethod: props.auctionId.sellingMethod
                                                    })
                                                }
                                            }
                                        >
                                            Дії з учасником
                                        </div>
                                    )
                                }
                            }
                        }
                    }
                }
            }
            return (
                <div className={styles.btnDecline}>
                    Дії з учасником
                </div>
            )
        }else{
            if(
                props.auctionId.status !== 'cancelled' &&
                props.auctionId.status !== 'unsuccessful' &&
                props.auctionId.status !== 'complete' &&
                i.status !== 'cancelled' &&
                i.status !== 'unsuccessful' //&&
                //i.status !== 'active'
            ){
                if(i.documents !== null){
                    if(i.documents.length > 0){
                        if(i.status !== 'active'){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                        }
                                    }
                                >
                                    Дії з учасником
                                </div>
                            )
                        }else{
                            if(props.auctionId.privateData.isOwner === true){
                                //Тут если статус актив надо взять время аварда и проверить время создания документов если есть док с временем больше аварда открыть кнопку
                                let tmpDataAward = ''
                                if(i.dateModified !== null){
                                    let tmpNow = new Date(i.datePublished)
                                    tmpDataAward = tmpNow.getTime()
                                }
                                for(let tm = 0; tm < i.documents.length; tm++){
                                    let tmpDoc = new Date(i.documents[tm].datePublished)
                                    if(
                                        i.documents[tm].documentType === 'rejectionProtocol' || 
                                        i.documents[tm].documentType === 'act'
                                    ){
                                        if(tmpDataAward < tmpDoc.getTime()){
                                            return (
                                                <div
                                                    className={styles.btnAccept}
                                                    onClick={
                                                        () => {
                                                            /*props.changeAwardRejectPopUpStatus({
                                                                aucId: props.auctionId.id,
                                                                awardId: i.id,
                                                                status: i.status,
                                                                awards: count,
                                                                sellingMethod: props.auctionId.sellingMethod
                                                            })*/
                                                            props.changeAwardRejectPopUpStatus({
                                                                aucId: props.auctionId.id,
                                                                awardId: i.id,
                                                                status: i.status,
                                                                awards: count,
                                                                sellingMethod: props.auctionId.sellingMethod
                                                            })
                                                        }
                                                    }
                                                >
                                                    Дії з учасником2
                                                </div>
                                            )
                                        }
                                    }
                                }
                            }
                            return (
                                <div className={styles.btnDecline}>
                                    Дії з учасником3
                                </div>
                            )
                            
                        }
                    //}else if(i.bid.createdByCurrentUser === true){
                    }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                        return (
                            <div
                                className={styles.btnAccept}
                                onClick={
                                    () => {
                                        /*props.changeAwardRejectPopUpStatus({
                                            aucId: props.auctionId.id,
                                            awardId: i.id,
                                            status: i.status,
                                            awards: count,
                                            sellingMethod: props.auctionId.sellingMethod
                                        })*/
                                        props.changeAwardRejectPopUpStatus({
                                            aucId: props.auctionId.id,
                                            awardId: i.id,
                                            status: i.status,
                                            awards: count,
                                            sellingMethod: props.auctionId.sellingMethod
                                        })
                                    }
                                }
                            >
                                Відмовитись від очікування
                            </div>
                        )
                    }
                //}else if(i.bid.createdByCurrentUser === true){
                }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                    return (
                        <div
                            className={styles.btnAccept}
                            onClick={
                                () => {
                                    props.changeAwardRejectPopUpStatus({
                                        aucId: props.auctionId.id,
                                        awardId: i.id,
                                        status: i.status,
                                        awards: count,
                                        sellingMethod: props.auctionId.sellingMethod
                                    })
                                }
                            }
                        >
                            Відмовитись від очікування
                        </div>
                    )
                }
            }else{
                if(openButtonWithContract(props, i) === true){
                    if(i.documents !== null){
                        if(i.documents.length > 0){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                        }
                                    }
                                >
                                    Дії з учасником4
                                </div>
                            )
                        //}else if(i.bid.createdByCurrentUser === true){
                        }else if(props.auctionId.privateData.bids[0].bidId === i.bidId){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                        }
                                    }
                                >
                                    Відмовитись від очікування
                                </div>
                            )
                        }
                    //}else if(i.bid.createdByCurrentUser === true){
                    }else if(props.auctionId.privateData.bids[0].bidId === i.bidId){
                        return (
                            <div
                                className={styles.btnAccept}
                                onClick={
                                    () => {
                                        props.changeAwardRejectPopUpStatus({
                                            aucId: props.auctionId.id,
                                            awardId: i.id,
                                            status: i.status,
                                            awards: count,
                                            sellingMethod: props.auctionId.sellingMethod
                                        })
                                        /*props.changeContractApprowePopUpStatus({
                                            aucId: props.auctionsGetId.id,
                                            contractId: i.id,
                                            sellingMethod: props.auctionsGetId.sellingMethod,
                                            count: count,
                                            fullAuction: props.auctionsGetId,
                                            typeView: 4,
                                            bid: i
                                        })*/
                                    }
                                }
                            >
                                Відмовитись від очікування
                            </div>
                        )
                    }
                }
            }
            return (
                <div className={styles.btnDecline}>
                    Дії з учасником5
                </div>
            )
        }
    }else{
        let tmp = false
        for(let tt = 0; tt < i.documents.length; tt++){
            if(i.documents[tt].documentType === 'rejectionProtocol' || i.documents[tt].documentType === 'act'){
                tmp = true
            }
        }
        if(tmp){
            if(
                !isLRE(props.auctionId.sellingMethod) &&
                !isLSE(props.auctionId.sellingMethod) &&
                !isLSP( props.auctionId.sellingMethod)
            ){
                if(
                    props.auctionId.status !== 'cancelled' &&
                    props.auctionId.status !== 'unsuccessful' &&
                    props.auctionId.status !== 'complete' &&
                    i.status !== 'cancelled' &&
                    i.status !== 'unsuccessful' //&&
                    //i.status !== 'active'
                ){
                    if(props.auctionId.privateData.bids !== null && props.auctionId.privateData.bids.length > 0){
                        if(i.bidId === props.auctionId.privateData.bids[0].bidId){
                            if(i.status === 'pending_waiting'){
                                return (
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                props.changeAwardRejectPopUpStatus({
                                                    aucId: props.auctionId.id,
                                                    awardId: i.id,
                                                    status: i.status,
                                                    awards: count,
                                                    sellingMethod: props.auctionId.sellingMethod
                                                })
                                                /*props.changeContractApprowePopUpStatus({
                                                    aucId: props.auctionsGetId.id,
                                                    contractId: i.id,
                                                    sellingMethod: props.auctionsGetId.sellingMethod,
                                                    count: count,
                                                    fullAuction: props.auctionsGetId,
                                                    typeView: 4,
                                                    bid: i
                                                })*/
                                            }
                                        }
                                    >
                                        Відмовитись від очікування
                                    </div>
                                )
                            }
                        }
                    }else{
                        if(i.documents !== null){
                            if(i.documents.length > 0){
                                return (
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                //props.changeAwardRejectPopUpStatus({aucId: props.auctionId.id, awardId: i.id, status: i.status, awards: count, sellingMethod: props.auctionId.sellingMethod})
                                                props.changeAwardRejectPopUpStatus({
                                                    aucId: props.auctionId.id,
                                                    awardId: i.id,
                                                    status: i.status,
                                                    awards: count,
                                                    sellingMethod: props.auctionId.sellingMethod
                                                })
                                                /*props.changeContractApprowePopUpStatus({
                                                    aucId: props.auctionsGetId.id,
                                                    contractId: i.id,
                                                    sellingMethod: props.auctionsGetId.sellingMethod,
                                                    count: count,
                                                    fullAuction: props.auctionsGetId,
                                                    typeView: 4,
                                                    bid: i
                                                })*/
                                            }
                                        }
                                    >
                                        Дії з учасником
                                    </div>
                                )
                            }
                        }
                    }
                }
                return (
                    <div className={styles.btnDecline}>
                        Дії з учасником6
                    </div>
                )
            }else{
                if(
                    props.auctionId.status !== 'cancelled' &&
                    props.auctionId.status !== 'unsuccessful' &&
                    props.auctionId.status !== 'complete' &&
                    i.status !== 'cancelled' &&
                    i.status !== 'unsuccessful' //&&
                    //i.status !== 'active'
                ){
                    if(i.documents !== null){
                        if(i.documents.length > 0){
                            if(i.status !== 'active'){
                                return (
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                props.changeAwardRejectPopUpStatus({
                                                    aucId: props.auctionId.id,
                                                    awardId: i.id,
                                                    status: i.status,
                                                    awards: count,
                                                    sellingMethod: props.auctionId.sellingMethod
                                                })
                                            }
                                        }
                                    >
                                        Дії з учасником7
                                    </div>
                                )
                            }else{
                                if(props.auctionId.privateData.isOwner === true){
                                    //Тут если статус актив надо взять время аварда и проверить время создания документов если есть док с временем больше аварда открыть кнопку
                                    let tmpDataAward = ''
                                    if(i.dateModified !== null){
                                        let tmpNow = new Date(i.datePublished)
                                        tmpDataAward = tmpNow.getTime()
                                    }
                                    for(let tm = 0; tm < i.documents.length; tm++){
                                        let tmpDoc = new Date(i.documents[tm].datePublished)
                                        if(
                                            i.documents[tm].documentType === 'rejectionProtocol' || 
                                            i.documents[tm].documentType === 'act'
                                        ){
                                            if(tmpDataAward < tmpDoc.getTime()){
                                                return (
                                                    <div
                                                        className={styles.btnAccept}
                                                        onClick={
                                                            () => {
                                                                /*props.changeAwardRejectPopUpStatus({
                                                                    aucId: props.auctionId.id,
                                                                    awardId: i.id,
                                                                    status: i.status,
                                                                    awards: count,
                                                                    sellingMethod: props.auctionId.sellingMethod
                                                                })*/
                                                                props.changeAwardRejectPopUpStatus({
                                                                    aucId: props.auctionId.id,
                                                                    awardId: i.id,
                                                                    status: i.status,
                                                                    awards: count,
                                                                    sellingMethod: props.auctionId.sellingMethod
                                                                })
                                                            }
                                                        }
                                                    >
                                                        Дії з учасником
                                                    </div>
                                                )
                                            }
                                        }
                                    }
                                }
                                return (
                                    <div className={styles.btnDecline}>
                                        Дії з учасником
                                    </div>
                                )
                                
                            }
                        //}else if(i.bid.createdByCurrentUser === true){
                        }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            /*props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })*/
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                        }
                                    }
                                >
                                    Дії з учасником
                                </div>
                            )
                        }
                    //}else if(i.bid.createdByCurrentUser === true){
                    }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                        return (
                            <div
                                className={styles.btnAccept}
                                onClick={
                                    () => {
                                        props.changeAwardRejectPopUpStatus({
                                            aucId: props.auctionId.id,
                                            awardId: i.id,
                                            status: i.status,
                                            awards: count,
                                            sellingMethod: props.auctionId.sellingMethod
                                        })
                                    }
                                }
                            >
                                Дії з учасником
                            </div>
                        )
                    }
                }else{
                    if(openButtonWithContract(props, i) === true){
                        if(i.documents !== null){
                            if(i.documents.length > 0){
                                return (
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                props.changeAwardRejectPopUpStatus({
                                                    aucId: props.auctionId.id,
                                                    awardId: i.id,
                                                    status: i.status,
                                                    awards: count,
                                                    sellingMethod: props.auctionId.sellingMethod
                                                })
                                                /*props.changeContractApprowePopUpStatus({
                                                    aucId: props.auctionsGetId.id,
                                                    contractId: i.id,
                                                    sellingMethod: props.auctionsGetId.sellingMethod,
                                                    count: count,
                                                    fullAuction: props.auctionsGetId,
                                                    typeView: 4,
                                                    bid: i
                                                })*/
                                            }
                                        }
                                    >
                                        Дії з учасником
                                    </div>
                                )
                            //}else if(i.bid.createdByCurrentUser === true){
                            }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                                return (
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                props.changeAwardRejectPopUpStatus({
                                                    aucId: props.auctionId.id,
                                                    awardId: i.id,
                                                    status: i.status,
                                                    awards: count,
                                                    sellingMethod: props.auctionId.sellingMethod
                                                })
                                                /*props.changeContractApprowePopUpStatus({
                                                    aucId: props.auctionsGetId.id,
                                                    contractId: i.id,
                                                    sellingMethod: props.auctionsGetId.sellingMethod,
                                                    count: count,
                                                    fullAuction: props.auctionsGetId,
                                                    typeView: 4,
                                                    bid: i
                                                })*/
                                            }
                                        }
                                    >
                                        Дії з учасником
                                    </div>
                                )
                            }
                        //}else if(i.bid.createdByCurrentUser === true){
                        }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                        }
                                    }
                                >
                                    Дії з учасником
                                </div>
                            )
                        }
                    }
                }
                return (
                    <div className={styles.btnDecline}>
                        Дії з учасником
                    </div>
                )
            }
        }
    }
    return null
}

function openButtonWithContract(props, data){
    if(props.contractAuctionIdGet !== null){
        if(props.contractAuctionIdGet.length > 0){
            for(let i = 0; i < props.contractAuctionIdGet.length; i++){
                if(props.contractAuctionIdGet[i].award !== null){
                    if(props.contractAuctionIdGet[i].award.id === data.id){
                        if(
                            props.contractAuctionIdGet[i].status === 'signed' &&
                            props.contractAuctionIdGet[i].lotPaymentConfirmation === false
                        ) return true
                    }
                }
            }
        }   
    }
    return false
}

async function combineSetData(props, aucId){
    let tmpData = {}
    //tmpData.endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/by_public_id/${aucId}`
    tmpData.endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${aucId}/sync`
    tmpData.body = {}
    tmpData.body.method = "GET"
    if(props.token !== null){
        tmpData.body.headers = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        tmpData.body.headers = {
            'Content-Type': 'application/json'
        }
    }
    let tmpResponse = await backendAPI(tmpData)
    let tmpResponse2 = await errorAPI(tmpResponse)
    if(tmpResponse2.hasOwnProperty('message') === true){
        let tmp = JSON.stringify({title: 'Помилка', message: JSON.stringify(tmpResponse2)})
        props.setUniversalError(tmp)
    }else{
        await props.setDataAddress(tmpResponse2, 'SET_FULL_AUCTION')
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionAwards: state.start.auctionAwards,
        allUserPopUp: state.start.allUserPopUp,
        allUserPopUpUser: state.start.allUserPopUpUser,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        timberContractPut: state.start.timberContractPut,
        pageNumber: state.start.pageNumber,
        auctionsGetId: state.start.auctionsGetId,
        auctionsAllBids: state.start.auctionsAllBids,
        // history: state.start.history,
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
        bidEdit: state.start.bidEdit,
        userProfiles: state.start.userProfiles
        // historyDocsContracts: state.start.historyDocsContracts,
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,
    setAwardAllUserPopUp,
    setAwardAllUserPopUpUser,

    changeContractApprowePopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,

    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,
    setContractDatePaid,

    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
    setDataAddress,
    saveArrBids
}

export default connect(mapStateToProps, mapDispatchToProps)(Contract)