import React,{useState, useEffect} from 'react';
import styles from './item.module.css';

import {connect} from 'react-redux';
import { createItemCS, saveItemCS } from '../utils';
import { isMongo, checkProp } from '../../../../../redux/check';

import TextArea from '../../../../elements/inputs/textArea'
import Input from '../../../../elements/inputs/input';
import Select from '../../../../elements/inputs/inputSelect'
import Localization from '../../../elems/localization/localizationLAW';
import Button from '../../../../elements/buttons/button';
import MultiSelect from '../../../../elements/inputs/multiSelect/inputMultiSelectCVZULAE';
import MultiSelectKVTSPZLAE from '../../../../elements/inputs/multiSelect/inputMultiSelectKVTSPZLAE'
import Checkbox from '../../../../elements/inputs/checkBox';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import {
    getMyAuction,
    changeLoader,
    setUniversalError,
} from '../../../../../redux/actions.js';

import {popUpAddress} from '../../../../../redux/actions/address/address.js';

import {clearDataLot} from '../clearDataItem'

const LandItem = ( {data, classification, ...props} ) => {
    //const [intendedUse, setIntendeduse] = useState(createArrData(data));
    const [intendedUse, setIntendeduse] = useState(null);
    const [intendedUseList, setIntendedUseList] = useState([]);

    const [intendedUseSecond, setIntendeduseSecond] = useState(null);
    const [intendedUseListSecond, setIntendedUseListSecond] = useState([]);

    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)

    // const [, setQuantity] = useState(checkProp(data, 'quantity') ? data.quantity : null)
    const [location, setLocation] = useState(checkProp(data, 'location') ? data.location : null)
    const [address, setAdress] = useState(checkProp(data, 'address') ? data.address : null)

    const [bankRuptcyItemType] = useState(checkProp(data, 'bankRuptcyItemType') ? data.bankRuptcyItemType : null)

    const [cadastral, setCadastral] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'cadastralNumber') ? data.itemProps.cadastralNumber : null)
    const [landArea, setLandArea] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'landArea') ? data.itemProps.landArea : null)
    const [ownershipType, setOwnershipType] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'ownershipType') ? data.itemProps.ownershipType : null)
    const [encumbrances, setEncumbrances] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'encumbrances') ? data.itemProps.encumbrances : null)
    const [jointOwnership, setJointOwnership] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'jointOwnership') ? data.itemProps.jointOwnership : null)
    const [utilitiesAvailability, setUtilitiesAvailability] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'utilitiesAvailability') ? data.itemProps.utilitiesAvailability : null)


    const [hasEncumbrances, setHasEncumbrances] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'hasEncumbrances') ? data.itemProps.hasEncumbrances : false)
    const [hasJointOwnership, setHasJointOwnership] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'hasJointOwnership') ? data.itemProps.hasJointOwnership : false)
    const [hasUtilitiesAvailability, setHasUtilitiesAvailability] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'hasUtilitiesAvailability') ? data.itemProps.hasUtilitiesAvailability : false)
    const [hasEmphyteusisLease, setHasEmphyteusisLease] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'hasEmphyteusisLease') ? data.itemProps.hasEmphyteusisLease : false)


    const [additionalClassifications, setAdditionalClassifications] = useState(checkProp(data, 'additionalClassifications') ? data.additionalClassifications : [])
    // const [, setAdditionalClassificationsSecond] = useState(checkProp(data, 'additionalClassifications') ? data.additionalClassifications : [])





    let requestBody = {
            description: {
                uk_UA: description,
            },
            // classification: {
            //     id: classification,
            //     scheme: getValues(props.classificationsBseBsd, classification, 'id', 'scheme'),
            //     description: {
            //         uk_UA: getValuesUA(props.classificationsBseBsd, classification, 'id', 'description'),
            //     }
            // },
            additionalClassifications: additionalClassifications,
            // unit: {
            //     code: unit,
            //     name: {
            //         uk_UA: getValues(props.classificationsZU.unitCode, unit, 'code', 'name'),
            //     }
            // },
            //quantity: +quantity,
            address: address,
            location: location,
            itemProps: {
                landArea: +landArea,
                cadastralNumber: cadastral,
                ownershipType: ownershipType,
                hasEncumbrances: hasEncumbrances === true ? true : false,
                hasJointOwnership: hasJointOwnership === true ? true : false,
                hasUtilitiesAvailability: hasUtilitiesAvailability === true ? true : false,
                hasEmphyteusisLease: hasEmphyteusisLease === true ? true : false,
            },
            // commercialSellItemType: null
            // additionalClassifications: null,
            // registrationDetails: null,
            bankRuptcyItemType: bankRuptcyItemType,
    }

    function fHasEncumbrances(requestBody){
        if(!hasEncumbrances) return requestBody
        requestBody.itemProps.encumbrances = encumbrances
        return requestBody
    }
    function fHasJointOwnership(requestBody){
        if(!hasJointOwnership) return requestBody
        requestBody.itemProps.jointOwnership = jointOwnership
        return requestBody
    }
    function fHasUtilitiesAvailability(requestBody){
        if(!hasUtilitiesAvailability) return requestBody
        requestBody.itemProps.utilitiesAvailability = utilitiesAvailability
        return requestBody
    }

    function fHasEmphyteusisLease(requestBody) {
        if (
            (ownershipType === 'private' || ownershipType === 'compatible') &&
            (/*classification === '06121000-6' || */ checkEmphyteusisLeaseAdditionalClassifications())
        ) {
            return requestBody
        }
        requestBody.itemProps.hasEmphyteusisLease = null
        return requestBody
    }

    useEffect( () => {
        async function getintendedUseList() {
            //await fetch(`${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/byScheme/cvzu`)
            await fetch(`${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/CVZU`)
            .then( (response) => {if (response.status == 200) return response.json()})
            .then( (data1) => {
                let tmp = []
                let tmpCVZU = createArrDataCVZU(data)
                for(let i = 0; i < tmpCVZU.length; i++){
                    for(let j = 0; j < data1.length; j++){
                        if(data1[j].id.indexOf(tmpCVZU[i]) !== -1) tmp.push(data1[j].id)
                    }
                }
                setIntendeduse(tmp)
                setIntendedUseList(data1)
            })
        }
        if (intendedUseList.length === 0) getintendedUseList()
    }, []);

    useEffect( () => {
        async function getintendedUseListSecond() {
            //await fetch(`${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/byScheme/kvtspz`)
            await fetch(`${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/kvtspz`)
            .then( (response) => {if (response.status == 200) return response.json()})
            .then( (data1) => {
                let tmp = []
                let tmpKvtspz = createArrDataKvtspz(data)
                for(let i = 0; i < tmpKvtspz.length; i++){
                    for(let j = 0; j < data1.length; j++){
                        //if(data1[j].classificationId.indexOf(tmpKvtspz[i]) !== -1) tmp.push(data1[j].id)
                        if(data1[j].id.indexOf(tmpKvtspz[i]) !== -1) tmp.push(data1[j].id)
                    }
                }
                setIntendeduseSecond(tmp)
                 let uniqArr = []
                for(let i=0; i < data1.length; i++){
                    uniqArr.push({
                        classificationId: data1[i].id,
                        description: data1[i].description.uk_UA,
                        id: data1[i].id,
                        parent: null,
                        scheme: "kvtspz",
                    })
// description: {uk_UA: "Водні обʼєкти загального користування", en_US: null}
// en_US: null
// uk_UA: "Водні обʼєкти загального користування"
// id: "10.14"
// scheme: "kvtspz"
                }
                setIntendedUseListSecond(uniqArr)

                    // classificationId: "02.03"
                    // description: "Для будівництва і обслуговування багатоквартирного житлового будинку"
                    // id: 56769
                    // parent: null
                    // scheme: "kvtspz"
                
            })
        }
        if (intendedUseListSecond.length === 0) getintendedUseListSecond()
    }, []);


    function createArrDataCVZU(data){
        if(checkProp(data, 'additionalClassifications')){
            let tmp = data.additionalClassifications.filter(
                (e) => {
                    return e.scheme === "CVZU"
                }
            )
            .map(
                (e) => {
                    return e.id
                }
            )
            return tmp
        }
        return []
    }
    function createArrDataKvtspz(data){
        if(checkProp(data, 'additionalClassifications')){
            let tmp = data.additionalClassifications.filter(
                (e) => {
                    return e.scheme === "kvtspz"
                }
            )
            .map(
                (e) => {
                    return e.id
                }
            )
            return tmp
        }
        return []
    }

    function viewButtons(props){
        if(
            props.auctionsMy.items === null ||
            props.auctionsMy.items.length === 0
        ){
            if(
                description !== null && description !== '' && landArea !== null && cadastral !== null
            ){
                    return (
                        <div className={styles.lineButtons}>
                            <Button
                                data={'Зберегти зміни'}
                                color={'blue'}
                                onClick={
                                    () => {
                                        let tmp = requestBody
                                        tmp = fHasJointOwnership(tmp)
                                        tmp = fHasEncumbrances(tmp)
                                        tmp = fHasUtilitiesAvailability(tmp)
                                        tmp = fHasEmphyteusisLease(tmp)
                                        createItemZU(props, tmp, intendedUseList, intendedUse, intendedUseListSecond, intendedUseSecond)
                                        props.edit(false)
                                    }
                                }
                            />
                            <Button
                                data={'Закрити'}
                                color={'gray'}
                                onClick={
                                    () => {
                                        clearDataLot(props)
                                        props.edit(false)
                                    }
                                }
                            />
                        </div>
                    )
            }
        }else{
                if(
                    description !== null && description !== '' && landArea !== null && cadastral !== null
                ){
                    return (
                        <div className={styles.lineButtons}>
                            <Button
                                data={'Зберегти зміни'}
                                color={'blue'}
                                onClick={
                                    () => {
                                        let tmp = requestBody
                                        tmp = fHasJointOwnership(tmp)
                                        tmp = fHasEncumbrances(tmp)
                                        tmp = fHasUtilitiesAvailability(tmp)
                                        tmp = fHasEmphyteusisLease(tmp)
                                        if(
                                            !checkProp(data, 'id')
                                        ){
                                            createItemZU(props, tmp, intendedUseList, intendedUse, intendedUseListSecond, intendedUseSecond)
                                        }else{
                                            saveItemZU(props, tmp, data.id, intendedUseList, intendedUse, intendedUseListSecond, intendedUseSecond)
                                        }
                                        props.edit(false)
                                    }
                                }
                            />
                            <Button
                                data={'Закрити'}
                                color={'gray'}
                                onClick={
                                    () => {
                                        clearDataLot(props)
                                        props.edit(false)
                                    }
                                }
                            />
                        </div>
                    )
                }
        }
    }

    function checkEmphyteusisLeaseAdditionalClassifications() {
        for (let i in intendedUseSecond) {
            if (intendedUseSecond[i].startsWith("01")) {
                return true
            }
        }
        return false;
    }

    return (
        <>
            <div style={{marginBottom: "10px"}}>
                <MultiSelect
                    label="Додатковий класифікатор CVZU"
                    value={intendedUse}
                    options={intendedUseList}
                    dictionary={true}
                    setUpLevel={setAdditionalClassifications}
                    intendedUse={setIntendeduse}
                />
                <div style={{fontSize: "12px", color: "#8b0000", marginTop: "-13px"}}>* Оберіть мінімум один додатковий класифікатор CVZU</div>
            </div>
            <div style={{marginBottom: "15px"}}>
                <MultiSelectKVTSPZLAE
                    label="Додатковий класифікатор kvtspz"
                    value={intendedUseSecond}
                    options={intendedUseListSecond}
                    dictionary={true}
                    // setUpLevel={setAdditionalClassificationsSecond}
                    intendedUse={setIntendeduseSecond}
                />
                <div style={{fontSize: "12px", color: "#8b0000", marginTop: "-13px"}}>* Оберіть ОДИН додатковий класифікатор kvtspz</div>
            </div>


            <TextArea
                value={description}
                disabled={blokedInput(props)}
                label="Опис об'єкта"
                required
                onChange={(event)=>{
                    setDescription(event)
                }}
            />

            <div className={styles.semiTitle}>Характеристики земельної ділянки</div>
            <Input
                label="Площа ділянки, га *"
                value={landArea}
                onChange={(event)=>{
                    if(Number(event) <= 60362800){
                        let tmp = event.replace(/,/gi, '.')
                        setLandArea(tmp)
                        // setQuantity(tmp)
                    }
                }}
                disabled={blokedInput(props)}
            />
            <Input
                label="Кадастровий номер"
                value={cadastral}
                onChange={(event)=>{
                    setCadastral(event)
                }}
                required
                error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                descr={'1234567980:12:123:1234'}
                disabled={blokedInput(props)}
            />
            <Select
                data={{
                    label: "Оберіть тип власності",
                    target: ownershipType,
                }}
                onChange={(event) => {
                    setOwnershipType(event)
                }}
                options={[
                    {value: "state", name: 'Державна'},
                    {value: "private", name: 'Приватна '},
                    {value: "municipal", name: 'Комунальна'},
                    {value: "compatible", name: 'Сумісна'},
                ]}
            />
            
            <div className={styles.blokInputs}>
                <div className={styles.semiTitle}>Увага! При встановленні відмітки у полі щодо наявності обтяжень/співласників/інженерних мереж обов'язково заповніть відповідні поля з пов'язаною інформацією.</div>
                <Checkbox 
                    value={hasEncumbrances}
                    onChange={setHasEncumbrances}
                >
                    Присутні обтяження
                </Checkbox>
                {/* <Select
                    data={{
                        label: "Інформація про наявні обтяження",
                        target: encumbrances,
                    }}
                    onChange={(event) => {
                        setEncumbrances(event)
                        // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
                    }}
                    options={[
                        //{value: "noEncumbrances", name: 'Обтяження відсутні'},
                        {value: "arrest", name: 'Арешт нерухомого майна '},
                        {value: "collateral", name: 'Податкова застава'},
                        {value: "restraintsOnAlienation", name: 'Заборона відчуження'},
                        {value: "otherEncumbrances", name: 'Інші обтяження'},
                    ]}
                    disabled={hasEncumbrances ? false : true}
                /> */}
                <Input
                    label="Інформація про наявні обтяження"
                    value={encumbrances}
                    onChange={(event)=>{
                        setEncumbrances(event)
                        // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
                    }}
                    //error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                    disabled={hasEncumbrances ? false : true}
                />
                {/*<Input
                    label="Інформація про наявні обтяження"
                    value={encumbrances}
                    onChange={(event)=>{
                        setEncumbrances(event)
                        // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
                    }}
                    //error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                    disabled={hasEncumbrances ? false : true}
                />*/}
                <Checkbox 
                    value={hasJointOwnership}
                    onChange={setHasJointOwnership}
                >
                    Наявні співвласники
                </Checkbox>
                <Input
                    label="Інформація про співвласників"
                    value={jointOwnership}
                    onChange={(event)=>{
                        setJointOwnership(event)
                        // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
                    }}
                    error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                    disabled={hasJointOwnership ? false : true}
                />
                <Checkbox 
                    value={hasUtilitiesAvailability}
                    onChange={setHasUtilitiesAvailability}
                >
                    Інженерні мережі наявні
                </Checkbox>
                <Input
                    label="Інформація про інженерні мережі"
                    value={utilitiesAvailability}
                    onChange={(event)=>{
                        setUtilitiesAvailability(event)
                        // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY')
                    }}
                    error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                    disabled={hasUtilitiesAvailability ? false : true}
                />
                { ((ownershipType === 'private' || ownershipType === 'compatible') && (/*classification === '06121000-6' || */ checkEmphyteusisLeaseAdditionalClassifications())) &&
                    <Checkbox
                        value={hasEmphyteusisLease}
                        onChange={setHasEmphyteusisLease}
                    >
                        Право користування чужою земельною ділянкою
                    </Checkbox>
                }
            </div>

            {/*<Input
                label="Поточне використання земельної ділянки"
                value={landIntendedUse}
                onChange={(event)=>{
                    setLandIntendedUse(event)
                    // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LAND_INTENDED_USE')
                }}
                // error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                disabled={blokedInput(props, '11')}
            />*/}
            {/*<Input
                label="Цільове призначення земельної ділянки"
                value={specialLandPurpose}
                onChange={(event)=>{
                    setSpecialLandPurpose(event)
                    // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LAND_INTENDED_USE')
                }}
                required
                // error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                disabled={blokedInput(props, '11')}
            />*/}
            <Localization address={address} location={location} setLocation={setLocation} setAdress={setAdress} />
            {viewButtons(props)}
        </>
    )
}

function blokedInput(){
    return null
}

function redBlock(amount){
    if(amount === null || amount === '' || amount === 'null'){
        return 'error'
    }
    return null
}

async function createItemZU(props, requestBody, intendedUseList, intendedUse, intendedUseListSecond, intendedUseSecond){
    props.changeLoader(true)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            await createItemCS(props, requestBody, intendedUseList, intendedUse, intendedUseListSecond, intendedUseSecond)
        }
    }
}

async function saveItemZU(props, requestBody, itemId, intendedUseList, intendedUse, intendedUseListSecond, intendedUseSecond){
    props.changeLoader(true)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            saveItemCS(props, requestBody, itemId, intendedUseList, intendedUse, intendedUseListSecond, intendedUseSecond)
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
        addressGetId: state.start.addressGetId,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        userProfiles: state.start.userProfiles,
        classificationsDgf: state.start.classificationsDgf,
        classificationsZU: state.start.classificationsZU,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        classificationsBseBsd: state.start.classificationsBseBsd,
        classificationCVZU: state.start.classificationCVZU,
    }
}

const mapDispatchToProps = {
    setDataAddress,
    changeLoader,
    getMyAuction,
    popUpAddress,
    setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(LandItem)