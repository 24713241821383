import React from 'react';
import styles from './partialPayment.module.css';

import {connect} from 'react-redux';

import {backendAPI} from '../../../../lib/backendAPI'
import {errorAPI} from '../../../../lib/errorAPI'
import { setDataAddress } from '../../../../../redux/createAddress';
import {setUniversalError, changeLoader} from '../../../../../redux/actions.js';

function PaymentHOC(props){
    if(
        props.auctionId.status === 'pending_payment'
        && props.i.status === 'pending_payment'
        //&& props.i.isPartiallyPaid === null
        && isDoc(props.i) === true
    ){
        return <div className={styles.wrapper}>
            <div className={styles.wrapper__title}>Повна оплата</div>
            <div className={styles.buttons}>
                <div className={styles.btn + ' ' + styles.accept}
                    onClick={() => {approveWinerAward(props)}}
                >
                    Так
                </div>
            </div>
        </div>
    }
    return null
}

function isDoc(data){
    let tmp = false
    if(data !== null && data.documents !== null && data.documents.length > 0){
        for(let i = 0; i < data.documents.length; i++){
            if(data.documents[i].documentType === 'paymentInformation') tmp = true
        }
    }
    return tmp
}

/////////////////////////////////////

async function approveWinerAward(props){
    props.changeLoader(true)
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${props.i.id}/set_status/active`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при затвердженні аварду', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            updateData(props, props.auctionId.id)
        }
    }
}

/////////////////////////////////////

async function updateData(props, aucId){
    let tmpData = {}
    //tmpData.endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/by_public_id/${aucId}`
    tmpData.endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${aucId}/sync`
    tmpData.body = {}
    tmpData.body.method = "GET"
    if(props.token !== null){
        tmpData.body.headers = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        tmpData.body.headers = {
            'Content-Type': 'application/json'
        }
    }
    let tmpResponse = await backendAPI(tmpData)
    let tmpResponse2 = await errorAPI(tmpResponse)
    if(tmpResponse2.hasOwnProperty('message') === true){
        let tmp = JSON.stringify({title: 'Помилка', message: JSON.stringify(tmpResponse2)})
        props.setUniversalError(tmp)
    }else{
        await props.setDataAddress(tmpResponse2, 'SET_FULL_AUCTION')
    }
    props.changeLoader(false)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionId: state.start.auctionFull,
    }
}

const mapDispatchToProps = {
    setUniversalError,
    changeLoader,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHOC)